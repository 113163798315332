import { Injectable } from "@angular/core";
import { AOverviewStateService } from "@zaeper/angular-dashboard-view-lib";
import { IGroup } from "../../models/i-group";
import { IGroupQuickAccessMenuStateService } from "./i-group-quick-access-menu-state.service";

@Injectable()
export class GroupQuickAccessMenuStateService extends AOverviewStateService<IGroup> implements IGroupQuickAccessMenuStateService {
  constructor() {
    super();
  }
}

import { IGroupRestClient } from "./i-group-rest.client";
import { Injectable } from "@angular/core";
import { ICreateGroupForm } from "./forms/i-create-group-form";
import { IDeleteGroupForm } from "./forms/i-delete-group-form";
import { IGetGroupForm } from "./forms/i-get-group-form";
import { IGroup } from "../../models/i-group";
import { IGetGroupPageForm } from "./forms/i-get-group-page-form";
import { IUpdateGroupForm } from "./forms/i-update-group-form";
import { ARestClient, ICreateRecordResponse, IOkResponse, IPage, ISort } from "@zaeper/communication-lib";
import { HttpClient } from "@angular/common/http";
import { HostUtil } from "../../utils/host-util";
import { firstValueFrom, Observable } from "rxjs";
import { ICloud } from "../../models/i-cloud";

@Injectable()
export class GroupRestClient extends ARestClient implements IGroupRestClient {
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }

  createGroup(createGroupForm: ICreateGroupForm): Promise<ICreateRecordResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${createGroupForm.cloudId}/group`);

    return firstValueFrom(this._httpClient.post(endpointUrl, createGroupForm, { withCredentials: true }) as Observable<ICreateRecordResponse>);
  }

  deleteGroup(deleteGroupForm: IDeleteGroupForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${deleteGroupForm.cloudId}/group/${deleteGroupForm.groupId}`);

    return firstValueFrom(this._httpClient.delete(endpointUrl, { withCredentials: true }) as Observable<IOkResponse>);
  }

  getGroup(getGroupForm: IGetGroupForm): Promise<IGroup> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${getGroupForm.cloudId}/group/${getGroupForm.groupId}`);

    return firstValueFrom(this._httpClient.get(endpointUrl, { withCredentials: true }) as Observable<ICloud>);
  }

  getGroupPage(getGroupPageForm: IGetGroupPageForm): Promise<IPage<IGroup>> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${getGroupPageForm.cloudId}/group/page`);

    const defaultSort: ISort = {
      field: "name",
      ascending: true
    };

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
      params: {
        pageNumber: getGroupPageForm.pageNumber,
        pageSize: getGroupPageForm.pageSize,
        sort: JSON.stringify(defaultSort)
      }
    }) as Observable<IPage<IGroup>>);
  }

  updateGroup(updateGroupForm: IUpdateGroupForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${updateGroupForm.cloudId}/group/${updateGroupForm.groupId}`);

    return firstValueFrom(this._httpClient.put(endpointUrl, updateGroupForm, { withCredentials: true }) as Observable<IOkResponse>);
  }

}

import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { ConnectReceiverModalComponent } from './connect-receiver-modal.component';
import { ConnectionCodeDisplayModule } from "../../components/connection-code-display/connection-code-display.module";
import { ZDictionaryModule } from '@zaeper/angular-localization-lib';
import { ConnectionCodeService } from "../../components/connection-code-display/connection-code.service";
import {
  connectionCodeServiceInjectionToken
} from "../../components/connection-code-display/connection-code-service-injection-token";
import { ActionCardModule, ContentSliderModule } from '@zaeper/angular-dashboard-view-lib';
import { connectReceiverModalServiceInjectionToken } from "./connect-receiver-modal-service-injection-token";
import { ConnectReceiverModalService } from "./connect-receiver-modal.service";
import {
  connectionCodeStateServiceInjectionToken
} from "../../components/connection-code-display/connection-code-state-service-injection-token";
import { ConnectionCodeStateService } from "../../components/connection-code-display/connection-code-state.service";
import {LottieComponent} from "ngx-lottie";

@NgModule({
  imports: [
    ContentSliderModule,
    ButtonModule,
    InputTextModule,
    ZDictionaryModule,
    ActionCardModule,
    ConnectionCodeDisplayModule,
    LottieComponent
  ],
  exports: [ConnectReceiverModalComponent],
  declarations: [ConnectReceiverModalComponent],
  providers: [
    {
      provide: connectionCodeStateServiceInjectionToken,
      useClass: ConnectionCodeStateService
    },
    {
      provide: connectionCodeServiceInjectionToken,
      useClass: ConnectionCodeService,
    },
    {
      provide: connectReceiverModalServiceInjectionToken,
      useClass: ConnectReceiverModalService
    }
  ],
})
export class ConnectReceiverModalModule {
}

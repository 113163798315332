import { Inject, Injectable } from '@angular/core';
import { IConnectionCodeService } from './i-connection-code.service';
import { receiverRestClientInjectionToken } from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import { IReceiverRestClient } from "../../rest-clients/receiver/i-receiver-rest.client";
import {
  IGenerateConnectionCodeResponse
} from "../../rest-clients/receiver/responses/i-generate-connection-code-response";
import { connectionCodeStateServiceInjectionToken } from "./connection-code-state-service-injection-token";
import { IConnectionCodeStateService } from "./i-connection-code-state.service";

@Injectable()
export class ConnectionCodeService implements IConnectionCodeService {
  private static readonly _CONNECTION_CODE_DIVIDER_POSITIONS: Set<number> = new Set([3]);
  private static readonly _CONNECTION_CODE_DIVIDER_CHARACTER: string = "-";

  constructor(
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
    @Inject(connectionCodeStateServiceInjectionToken) private readonly _connectionCodeStateService: IConnectionCodeStateService
  ) {
  }

  public async requestConnectionCode(cloudId: string, groupId: string): Promise<void> {
    const response: IGenerateConnectionCodeResponse = await this._receiverRestClient.generateConnectionCode({
      cloudId,
      groupId
    });

    const formattedConnectionCode: string = this._formatConnectionCode(response.connectionCode);

    this._connectionCodeStateService.nextConnectionCode(formattedConnectionCode);
  }

  private _formatConnectionCode(connectionCode: string): string {
    return connectionCode.toUpperCase().split("").map((char, index) => {
      if (ConnectionCodeService._CONNECTION_CODE_DIVIDER_POSITIONS.has(index)) {
        return char + ConnectionCodeService._CONNECTION_CODE_DIVIDER_CHARACTER;
      }
      return char;
    }).join("")
  }
}

<p-table (onLazyLoad)="lazyLoadTable($event)" [lazy]="true" [loading]="(isLoading$ | async) ?? true"
         [paginator]="false"
         [rowsPerPageOptions]="rowsPerPageOptions"
         [rows]="10" [scrollable]="true"
         [showLoader]="false" [totalRecords]="(totalRecords$ | async) || 0"
         [value]="(records$ | async) || []">
  <ng-template let-columns="columns" pTemplate="loadingbody">
    <tr *ngFor="let i of (skeletonRows$ | async)">
      <td>
        <p-skeleton height="2rem"></p-skeleton>
      </td>
      <td>
        <p-skeleton height="2rem"></p-skeleton>
      </td>
      <td>
        <p-skeleton height="2rem"></p-skeleton>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="8" class="text-center">
        Keine Einträge gefunden
      </td>
    </tr>
  </ng-template>
  <ng-template let-appRecord let-i="rowIndex" pTemplate="body">
    <tr>
      <td>
        <img [src]="appRecord.lightIconUrl" class="rounded" height="48" width="48"/>
      </td>
      <td><strong>{{ appRecord.name }}</strong></td>
      <td class="flex gap-2 justify-end">
        <p-button (onClick)="install(appRecord.id)" [outlined]="appRecord.id | checkAppAlreadyInstalled" [disabled]="appRecord.id | checkAppAlreadyInstalled"
                  [rounded]="true">
          @if (appRecord.id | checkAppAlreadyInstalled) {
            <div class="flex flex-row gap-2">
              <zaeper-icon fontSet="material-icons-outlined">check_mark</zaeper-icon>
              <span>Installiert</span>
            </div>
          } @else {
            Installieren
          }
        </p-button>
      </td>
    </tr>
  </ng-template>
</p-table>

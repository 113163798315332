import {ModuleWithProviders, NgModule} from '@angular/core';
import {
  userAuthenticationRestClientInjectionToken
} from "./user/authentication/user-authentication-rest-client-injection-token";
import {UserAuthenticationRestClient} from "./user/authentication/user-authentication-rest.client";
import {cloudRestClientInjectionToken} from "./cloud/cloud-rest-client-injection-token";
import {CloudRestClient} from "./cloud/cloud-rest.client";
import {groupRestClientInjectionToken} from "./group/group-rest-client-injection-token";
import {GroupRestClient} from "./group/group-rest.client";
import {
  appRestClientInjectionToken,
  appSettingsRestClientInjectionToken,
  launcherRestClientInjectionToken,
  launcherSettingsRestClientInjectionToken,
  widgetRestClientInjectionToken,
  widgetSettingsRestClientInjectionToken
} from '@zaeper/angular-application-store-lib';
import {AppRestClient} from "./application/app/app-rest.client";
import {LauncherRestClient} from "./application/launcher/launcher-rest.client";
import {WidgetRestClient} from "./application/widget/widget-rest.client";
import {AppSettingsRestClient} from "./application/app/app-settings-rest.client";
import {LauncherSettingsRestClient} from "./application/launcher/launcher-settings-rest.client";
import {WidgetSettingsRestClient} from "./application/widget/widget-settings-rest.client";

@NgModule()
export class RestClientsModule {
  public static forRoot(): ModuleWithProviders<RestClientsModule> {
    return {
      ngModule: RestClientsModule,
      providers: [
        {
          provide: appRestClientInjectionToken,
          useClass: AppRestClient
        },
        {
          provide: appSettingsRestClientInjectionToken,
          useClass: AppSettingsRestClient
        },
        {
          provide: launcherRestClientInjectionToken,
          useClass: LauncherRestClient
        },
        {
          provide: launcherSettingsRestClientInjectionToken,
          useClass: LauncherSettingsRestClient
        },
        {
          provide: widgetRestClientInjectionToken,
          useClass: WidgetRestClient
        },
        {
          provide: widgetSettingsRestClientInjectionToken,
          useClass: WidgetSettingsRestClient
        },
        {
          provide: userAuthenticationRestClientInjectionToken,
          useClass: UserAuthenticationRestClient
        },
        {
          provide: cloudRestClientInjectionToken,
          useClass: CloudRestClient
        },
        {
          provide: groupRestClientInjectionToken,
          useClass: GroupRestClient
        }
      ]
    }
  }
}

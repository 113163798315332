import { Inject, Injectable } from "@angular/core";
import {
  IApplicationsOverviewModalForm,
  IApplicationsOverviewModalService
} from "./i-applications-overview-modal.service";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IModalService, modalServiceInjectionToken } from "@zaeper/angular-dashboard-view-lib";
import { ApplicationsOverviewModalComponent } from "./applications-overview-modal.component";

@Injectable()
export class ApplicationsOverviewModalService implements IApplicationsOverviewModalService {
  constructor(@Inject(modalServiceInjectionToken) private readonly _modalService: IModalService) {
  }

  openApplicationsOverviewModal(applicationsOverviewModalForm: IApplicationsOverviewModalForm): void {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(ApplicationsOverviewModalComponent, {
      data: {
        receiver: applicationsOverviewModalForm.receiver,
        cloudId: applicationsOverviewModalForm.cloudId,
        onSaveCallback: () => {
          applicationsOverviewModalForm.onSaveCallback?.();
          dynamicDialogRef.close();
        }
      },
      styleClass: "bg-white rounded-lg",
      header: "Applikationen verwalten",
      showHeader: true,
      closable: true,
      width: "900px",
      closeOnEscape: true,
      dismissableMask: true
    });
  }
}


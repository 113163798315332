import { ModuleWithProviders, NgModule } from '@angular/core';
import { authenticationGuardInjectionToken } from "./session/authentication-guard-injection-token";
import { AuthenticationGuard } from "./session/authentication-guard.service";

@NgModule()
export class GuardsModule {
    public static forRoot(): ModuleWithProviders<GuardsModule> {
        return {
            ngModule: GuardsModule,
            providers: [
                {
                    provide: authenticationGuardInjectionToken,
                    useClass: AuthenticationGuard
                }
            ]
        }
    }
}

<div class="flex flex-col gap-6 pt-6">
  <zaeper-tab-navigation [tabs]="tabs">
    <ng-template zTemplate="tab">
      <app-installed-apps-tab />
    </ng-template>
    <ng-template zTemplate="tab">
      <app-store-apps-tab />
    </ng-template>
  </zaeper-tab-navigation>
</div>

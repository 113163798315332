import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SplashLoaderComponent } from "./splash-loader.component";
import { LottieComponent } from "ngx-lottie";
import { splashLoaderStateServiceInjectionToken } from "./splash-loader-state-service-injection-token";
import { SplashLoaderStateService } from "./splash-loader-state.service";
import { splashLoaderServiceInjectionToken } from "./splash-loader-service-injection-token";
import { SplashLoaderService } from "./splash-loader-service";


@NgModule({
  declarations: [],
  exports: [
    SplashLoaderComponent
  ],
  imports: [
    CommonModule, LottieComponent, SplashLoaderComponent
  ],
  providers: [
    {
      provide: splashLoaderStateServiceInjectionToken,
      useClass: SplashLoaderStateService
    }
  ]
})
export class SplashLoaderModule {
  public static forRoot(): ModuleWithProviders<SplashLoaderModule> {
    return {
      ngModule: SplashLoaderModule,
      providers: [
        {
          provide: splashLoaderServiceInjectionToken,
          useClass: SplashLoaderService
        }
      ]
    };
  }
}

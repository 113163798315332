import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewChild} from "@angular/core";
import {AsyncPipe, NgClass} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {DropdownMenuComponent, IconComponent} from "@zaeper/angular-dashboard-view-lib";
import {InputTextModule} from "primeng/inputtext";
import {ScrollerLazyLoadEvent, ScrollerModule} from "primeng/scroller";
import {MenuItem, SharedModule} from "primeng/api";
import {ZTemplateModule} from "@zaeper/angular-core-lib";
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription} from "rxjs";
import {ICloud} from "../../models/i-cloud";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {languageStateServiceInjectionToken} from "../../services/language/languageStateServiceInjectionToken";
import {ILanguageStateService} from "@zaeper/localization-lib";
import {CloudQuickAccessMenuStateService} from "./cloud-quick-access-menu-state.service";
import {cloudQuickAccessMenuStateServiceInjectionToken} from "./cloud-quick-access-menu-state-service-injection-token";
import {cloudRestClientInjectionToken} from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import {ICloudRestClient} from "../../rest-clients/cloud/i-cloud-rest.client";
import {ICloudQuickAccessMenuStateService} from "./i-cloud-quick-access-menu-state.service";
import {IGetCloudPageForm} from "../../rest-clients/cloud/forms/i-get-cloud-page-form";
import {IPage} from "@zaeper/communication-lib";
import {
  ICloudCreateModalForm,
  ICloudCreateUpdateModalService
} from "../../modals/cloud-create-update-modal/i-cloud-create-update-modal.service";
import {Router} from "@angular/router";
import {
  cloudCreateUpdateModalServiceInjectionToken
} from "../../modals/cloud-create-update-modal/cloud-create-update-modal-service-injection-token";
import {IReceiver} from "../../models/i-receiver";
import {IGetGroupPageForm} from "../../rest-clients/group/forms/i-get-group-page-form";
import {groupRestClientInjectionToken} from "../../rest-clients/group/group-rest-client-injection-token";
import {IGroupRestClient} from "../../rest-clients/group/i-group-rest.client";
import {IGroup} from "../../models/i-group";
import {IGetReceiverPageForm} from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import {IReceiverRestClient} from "../../rest-clients/receiver/i-receiver-rest.client";
import {receiverRestClientInjectionToken} from "../../rest-clients/receiver/receiver-rest-client-injection-token";

@Component({
  selector: "app-cloud-quick-access-menu",
  standalone: true,
  providers: [{
    provide: cloudQuickAccessMenuStateServiceInjectionToken,
    useClass: CloudQuickAccessMenuStateService
  }],
  imports: [AsyncPipe, ButtonModule, DropdownMenuComponent, IconComponent, InputTextModule, ScrollerModule, SharedModule, ZTemplateModule, NgClass],
  templateUrl: "./cloud-quick-access-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudQuickAccessMenuComponent implements OnDestroy {
  private static readonly _MAX_SCROLL_HEIGHT: number = 160;
  public readonly itemSize: number = 40;
  public readonly pageSize: number = 0;
  public readonly scrollHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(this.itemSize);
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly cloudMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public readonly activeCloud$: Observable<ICloud | null>;
  public readonly language$: Observable<string>;
  @ViewChild("searchInput", {read: ElementRef}) searchInputEl!: ElementRef;
  public readonly records$: Observable<ICloud[]> = new Observable<ICloud[]>();
  private _subscriptions: Subscription = new Subscription();

  constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
              @Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService,
              @Inject(cloudQuickAccessMenuStateServiceInjectionToken) private readonly _cloudQuickAccessMenuStateService: ICloudQuickAccessMenuStateService,
              @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
              @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
              @Inject(cloudCreateUpdateModalServiceInjectionToken) private readonly _cloudCreateUpdateModalService: ICloudCreateUpdateModalService,
              private readonly _router: Router) {
    this.activeCloud$ = this._sessionStateService.activeCloud$;
    this.language$ = this._languageStateService.language$;
    this.records$ = this._cloudQuickAccessMenuStateService.records$;

    this._setMenuItems();
    this._cloudQuickAccessMenuStateService.setPageSize(this.pageSize);
    this._handleActiveCloudChange();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public lazyLoadItems(scrollerLazyLoadEvent: ScrollerLazyLoadEvent): Promise<void> {
    const last: number = scrollerLazyLoadEvent.last;

    if (last === this._cloudQuickAccessMenuStateService.getRecords().length && last < this._cloudQuickAccessMenuStateService.getTotalRecords()) {
      const nextPage: number = Math.ceil(last / this.pageSize);
      if (this._cloudQuickAccessMenuStateService.getPageNumber() < nextPage) {
        return this._loadCloudRecords(nextPage);
      }
    }
    return Promise.resolve();
  }

  public switchToCloud(cloud: ICloud): void {
    const getGroupPageForm: IGetGroupPageForm = {
      cloudId: cloud.id,
      pageNumber: 0,
      pageSize: 1
    };

    const groupPage: Promise<IPage<IGroup>> = this._groupRestClient.getGroupPage(getGroupPageForm);
    groupPage.then((groupPage: IPage<IGroup>): void => {
      const firstGroup: IGroup = groupPage.records[0] ?? null;

      this._sessionStateService.setActiveGroup(firstGroup);

      const getReceiverPageForm: IGetReceiverPageForm = {
        cloudId: cloud.id,
        groupId: firstGroup.id,
        pageNumber: 0,
        pageSize: 1
      };

      const receiverPage: Promise<IPage<IReceiver>> = this._receiverRestClient.getReceiverPage(getReceiverPageForm);
      const language$: Promise<string> = firstValueFrom(this._languageStateService.language$);
      Promise.all([receiverPage, language$]).then(([receiverPage, language]: [IPage<IReceiver>, string]): void => {
        const firstReceiver: IReceiver | null = receiverPage.records[0] ?? null;

        this._sessionStateService.setActiveCloud(cloud);
        this._sessionStateService.setActiveGroup(firstGroup);
        this._sessionStateService.setActiveReceiver(firstReceiver);

        this._router.navigate([`/${language}/`]);
      });
    });
  }

  private _handleActiveCloudChange(): void {
    this._subscriptions.add(this._sessionStateService.activeCloud$.subscribe((cloud: ICloud | null) => {
      this._cloudQuickAccessMenuStateService.setRecords([]);
      this._loadCloudRecords(0);
    }));
  }

  private _loadCloudRecords(pageNumber: number): Promise<void> {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;

    if (cloudId) {
      this.isLoading$.next(true);
      const getCloudPageForm: IGetCloudPageForm = {
        pageNumber: pageNumber,
        pageSize: this._cloudQuickAccessMenuStateService.getPageSize()
      };

      return this._cloudRestClient.getCloudPage(getCloudPageForm).then((page: IPage<ICloud>) => {
        this._cloudQuickAccessMenuStateService.setRecords([...this._cloudQuickAccessMenuStateService.getRecords(), ...page.records]);
        this._cloudQuickAccessMenuStateService.setTotalRecords(page.totalRecords);
        this._cloudQuickAccessMenuStateService.setTotalPages(page.totalPages);
        this._cloudQuickAccessMenuStateService.setPageNumber(pageNumber);
        this.scrollHeight$.next(Math.min(CloudQuickAccessMenuComponent._MAX_SCROLL_HEIGHT, page.totalRecords * this.itemSize));
        this.isLoading$.next(false);
      });
    }

    return Promise.resolve();
  }

  private _setMenuItems() {
    this._subscriptions.add(combineLatest([this.activeCloud$, this.language$]).subscribe(([activeCloud, language]: [ICloud | null, string]) => {
      if (activeCloud) {
        this.cloudMenuItems$.next([{
          label: "Cloud hinzufügen",
          icon: "pi pi-plus",
          command: () => {
            const cloudCreateModalForm: ICloudCreateModalForm = {
              onSaveCallback: () => this._router.navigate([`/${language}/clouds`])
            };
            this._cloudCreateUpdateModalService.openCreateModal(cloudCreateModalForm);
          }
        }, {
          label: "Clouds verwalten",
          icon: "pi pi-list",
          routerLink: `/${language}/clouds`
        }]);
      }
    }));
  }
}

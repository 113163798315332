@if (isVisible) {
  <div class="absolute opacity-50 -left-1/2 -z-1 w-full h-full rotate-[-75deg] bg-gradient-to-t from-neutral-900 to-slate-500"></div>
  <div class="relative h-full w-full">
    <div class="absolute z-0 h-full w-full top-0 left-0"></div>

    <div class="absolute z-30 grid place-content-center h-full w-full">
      <div class="flex flex-col items-center gap-4">
        <ng-lottie class="w-[325px]"
          [options]="loadingDotsLgOptions"></ng-lottie>
      </div>
    </div>
  </div>
}

import { NgModule } from "@angular/core";

import {
  availableAppsOverviewModalServiceInjectionToken
} from "./available-apps-overview-modal-service-injection-token";
import { AvailableAppsOverviewModalService } from "./available-apps-overview-modal.service";
import { AsyncPipe, CommonModule, NgForOf, NgIf } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { SharedModule } from "primeng/api";
import { SkeletonModule } from "primeng/skeleton";
import { TableModule } from "primeng/table";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { RouterLink } from "@angular/router";
import { IconComponent, TabNavigationModule } from "@zaeper/angular-dashboard-view-lib";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import {
  LauncherSettingsTabComponent
} from "../applications-overview-modal/tabs/launchers-overview-tab/launcher-settings-tab.component";
import { AvailableAppsOverviewModalComponent } from "./available-apps-overview-modal.component";
import {
  AppsOverviewTabComponent
} from "../applications-overview-modal/tabs/apps-overview-tab/apps-overview-tab.component";
import { InstalledAppsTabComponent } from "./tabs/installed-apps-tab/installed-apps-tab.component";
import { StoreAppsTabComponent } from "./tabs/store-apps-tab/store-apps-tab.component";
import {
  installedAppsOverviewStateServiceInjectionToken
} from "./tabs/installed-apps-tab/installed-apps-overview-state-service-injection-token";
import { InstalledAppsOverviewStateService } from "./tabs/installed-apps-tab/installed-apps-overview-state.service";
import { CheckAppAlreadyInstalledPipe } from "./tabs/installed-apps-tab/check-app-already-installed.pipe";

@NgModule({
  imports: [
    AsyncPipe,
    ButtonModule,
    InputTextModule,
    NgForOf,
    NgIf,
    SharedModule,
    SkeletonModule,
    TableModule,
    ZDictionaryModule,
    RouterLink,
    CommonModule,
    TabNavigationModule,
    ZTemplateModule,
    LauncherSettingsTabComponent,
    AppsOverviewTabComponent,
    StoreAppsTabComponent,
    IconComponent
  ],
  exports: [ AvailableAppsOverviewModalComponent ],
  declarations: [ AvailableAppsOverviewModalComponent, InstalledAppsTabComponent, CheckAppAlreadyInstalledPipe ],
  providers: [
    {
      provide: availableAppsOverviewModalServiceInjectionToken,
      useClass: AvailableAppsOverviewModalService
    }, {
      provide: installedAppsOverviewStateServiceInjectionToken,
      useClass: InstalledAppsOverviewStateService
    }
  ]
})
export class AvailableAppsOverviewModalModule {
}

import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReceiverWsClient } from "./receiver/receiver-ws.client";
import { receiverWsClientInjectionToken } from "./receiver/receiver-ws-client-injection-token";
import {
  receiverAuthenticationWsClientInjectionToken
} from "./receiver-authentication/receiver-authentication-ws-client-injection-token";
import { ReceiverAuthenticationWsClient } from "./receiver-authentication/receiver-authentication-ws.client";

@NgModule()
export class WsClientsModule {
  public static forRoot(): ModuleWithProviders<WsClientsModule> {
    return {
      ngModule: WsClientsModule,
      providers: [
        {
          provide: receiverWsClientInjectionToken,
          useClass: ReceiverWsClient
        }, {
          provide: receiverAuthenticationWsClientInjectionToken,
          useClass: ReceiverAuthenticationWsClient
        }
      ]
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudCreateUpdateModalComponent } from './cloud-create-update-modal.component';
import { InputTextareaModule, InputTextModule } from '@zaeper/angular-dashboard-view-lib';
import { ButtonModule } from "primeng/button";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
    declarations: [
        CloudCreateUpdateModalComponent
    ], imports: [
        ReactiveFormsModule,
        CommonModule,
        InputTextModule,
        InputTextareaModule,
        ButtonModule
    ]
})
export class CloudCreateUpdateModalModule {
}

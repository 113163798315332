import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserSettingsPageComponent } from "./user-settings-page.component";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import {
  ConfirmationModalModule,
  IconComponent,
  InputGroupModule,
  InputTextModule,
  PageLayoutModule,
  TabNavigationModule,
  TabNavigationStateService,
  tabNavigationStateServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { UserPropertiesTabComponent } from "./tabs/user-properties-tab/user-properties-tab.component";
import { ButtonModule } from "primeng/button";


@NgModule({
  providers: [
    {
      provide: tabNavigationStateServiceInjectionToken,
      useClass: TabNavigationStateService
    }
  ],
  declarations: [
    UserSettingsPageComponent, UserPropertiesTabComponent
  ],
  imports: [
    CommonModule,
    ZDictionaryModule,
    ZTemplateModule,
    PageLayoutModule,
    TabNavigationModule,
    InputTextModule,
    InputGroupModule,
    ButtonModule,
    ConfirmationModalModule,
    IconComponent
  ],
  exports: [
    UserSettingsPageComponent
  ]
})
export class UserSettingsPageModule {
}

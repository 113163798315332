import { Injectable } from "@angular/core";
import { ILivekitClient } from "./i-livekit.client";
import {
  LocalParticipant,
  LocalTrackPublication,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent, Track
} from "livekit-client";

@Injectable()
export class LivekitClient implements ILivekitClient {
    private static readonly _ENDPOINT_URL: string = "wss://zaeper-beobskxw.livekit.cloud";
    private _activeRoom: Room | null = null;

    public async connect( accessToken: string, parentElement: HTMLElement ): Promise<void> {
        if ( this._activeRoom !== null ) {
            await this.disconnect();
        }

        // creates a new room with options
        const room: Room = new Room( {
            // automatically manage subscribed video quality
            adaptiveStream: true,

            // optimize publishing bandwidth and CPU for published tracks
            dynacast: true,
        } );

        // pre-warm connection, this can be called as early as your page is loaded
        await room.prepareConnection( LivekitClient._ENDPOINT_URL, accessToken );

        // set up event listeners
        room
            .on( RoomEvent.TrackSubscribed, ( remoteTrack: RemoteTrack, remoteTrackPublication: RemoteTrackPublication,
                                              remoteParticipant: RemoteParticipant
            ) => this._handleTrackSubscribed( remoteTrack, remoteTrackPublication, remoteParticipant, parentElement ) )
            .on( RoomEvent.TrackUnsubscribed, ( remoteTrack: RemoteTrack,
                                                remoteTrackPublication: RemoteTrackPublication,
                                                remoteParticipant: RemoteParticipant
            ) => this._handleTrackUnsubscribed( remoteTrack, remoteTrackPublication, remoteParticipant ) )
            .on( RoomEvent.ActiveSpeakersChanged, ( participants: Participant[] ) => this._handleActiveSpeakerChange( participants ) )
            .on( RoomEvent.Disconnected, () => this._handleDisconnect() )
            .on( RoomEvent.LocalTrackUnpublished, ( localTrackPublication: LocalTrackPublication,
                                                    localParticipant: LocalParticipant
            ) => this._handleLocalTrackUnpublished( localTrackPublication, localParticipant ) );

        // connect to room
        await room.connect( LivekitClient._ENDPOINT_URL, accessToken );

        this._activeRoom = room;

        console.log( 'connected to room', room.name );

        console.log( room.numPublishers )
    }

    public async disconnect(): Promise<void> {
        /*if(this._activeRoom !== null) {
            await this._activeRoom.disconnect(true);
            this._activeRoom = null;
        }*/
        return Promise.resolve();
    };

    private _handleTrackSubscribed( remoteTrack: RemoteTrack, remoteTrackPublication: RemoteTrackPublication,
                                    remoteParticipant: RemoteParticipant, parentElement: HTMLElement
    ) {
        if ( ( remoteTrack.kind === Track.Kind.Video ) ) {
            const element: HTMLElement = remoteTrack.attach();
            element.classList.add( "max-h-full" )
            element.classList.add( "h-full" )
            parentElement.innerHTML = "";
            parentElement.appendChild( element );
        }
    }

    private _handleTrackUnsubscribed( remoteTrack: RemoteTrack, remoteTrackPublication: RemoteTrackPublication,
                                      remoteParticipant: RemoteParticipant
    ) {
        // remove tracks from all attached elements
        remoteTrack.detach();
    }

    private _handleLocalTrackUnpublished(
        localTrackPublication: LocalTrackPublication, localParticipant: LocalParticipant ) {
        // when local tracks are ended, update UI to remove them from rendering
        localTrackPublication.track?.detach();
    }

    private _handleActiveSpeakerChange( participants: Participant[] ) {
        // show UI indicators when participant is speaking
    }

    private _handleDisconnect() {
        console.log( 'disconnected from room' );
    }
}

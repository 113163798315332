import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { IConnectionCodeStateService } from "./i-connection-code-state.service";

@Injectable()
export class ConnectionCodeStateService implements IConnectionCodeStateService {
  private readonly _connectionCode$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly connectionCode$: Observable<string | null> = this._connectionCode$.asObservable();

  public getConnectionCode(): string | null {
    return this._connectionCode$.getValue();
  }

  public nextConnectionCode(connectionCode: string): void {
    this._connectionCode$.next(connectionCode);
  }
}

<form (submit)="submit($event)" [formGroup]="formGroup">
  <div class="py-6 flex flex-col gap-6">
    <zaeper-input-text [inputOptions]="nameInputTextOptions"></zaeper-input-text>
    <zaeper-input-textarea [inputOptions]="descriptionInputTextareaOptions"></zaeper-input-textarea>
  </div>
  <div class="w-full flex gap-2 justify-end">
    <p-button (onClick)="close()" [rounded]="true" [outlined]="true">Abrechen</p-button>
    <p-button [rounded]="true" type="submit">{{submitText}}</p-button>
  </div>
</form>

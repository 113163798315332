import { inject, NgModule } from "@angular/core";
import { RouteReuseStrategy, RouterModule, Routes } from "@angular/router";
import { ReceiverPreviewerPageComponent } from "../pages/receiver-previewer-page/receiver-previewer-page.component";
import { languageResolverServiceInjectionToken } from "../services/language/languageResolverServiceInjectionToken";
import {
  dictionaryResolverServiceInjectionToken
} from "../services/dictionary/dictionary-resolver-service-injection-token";
import { CloudsOverviewPageComponent } from "../pages/clouds-overview-page/clouds-overview-page.component";
import { GroupsOverviewPageComponent } from "../pages/groups-overview-page/groups-overview-page.component";
import { ReceiversOverviewPageComponent } from "../pages/receivers-overview-page/receivers-overview-page.component";
import { FeaturesOverviewPageComponent } from "../pages/features-overview-page/features-overview-page.component";
import { UserSettingsPageComponent } from "../pages/user-settings-page/user-settings-page.component";
import { PaymentOverviewPageComponent } from "../pages/payment-overview-page/payment-overview-page.component";
import { SaveLocalstoragePageComponent } from "../pages/save-localstorage-page/save-localstorage-page.component";
import { Matcher } from "@zaeper/angular-core-lib";
import { AppRouteReuseStrategy } from "./app-route-reuse-strategy";
import { authenticationGuardInjectionToken } from "./guards/session/authentication-guard-injection-token";
import { GuardsModule } from "./guards/guards.module";
import { ResolversModule } from "./resolvers/resolvers.module";
import { currentUserResolverInjectionToken } from "./resolvers/session/current-user-resolver-injection-token";
import {
  quickAccessResourcesResolverInjectionToken
} from "./resolvers/session/quick-access-resources-resolver-injection-token";
import { StoreRoutingModule } from "@zaeper/angular-application-store-lib";
import { AppOutletComponent } from "../outlets/app-outlet/app-outlet.component";

const availableLanguages: [ string, string ] = [
  "de", "en"
];

const routes: Routes = [
  {
    path: "",
    redirectTo: `${availableLanguages[0]}`,
    pathMatch: "full"
  }, {
    path: "save-localstorage",
    pathMatch: "full",
    component: SaveLocalstoragePageComponent
  }, {
    path: "",
    component: AppOutletComponent,
    pathMatch: "prefix",
    canActivateChild: [ () => inject(authenticationGuardInjectionToken).isLoggedIn() ],
    children: [
      {
        matcher: Matcher.matchLanguage(availableLanguages),
        pathMatch: "prefix",
        resolve: {
          language: languageResolverServiceInjectionToken,
          dictionaries: dictionaryResolverServiceInjectionToken,
          currentUser: () => inject(currentUserResolverInjectionToken).getCurrentUser(),
          quickAccessResources: () => inject(quickAccessResourcesResolverInjectionToken).getQuickAccessResources()
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ReceiverPreviewerPageComponent

          }, {
            path: "",
            pathMatch: "prefix",
            children: [
              {
                path: "clouds",
                pathMatch: "full",
                component: CloudsOverviewPageComponent
              }, {
                path: "clouds",
                pathMatch: "prefix",
                children: [
                  {
                    pathMatch: "prefix",
                    matcher: Matcher.matchId("cloudId"),
                    children: [
                      {
                        path: "groups",
                        pathMatch: "full",
                        component: GroupsOverviewPageComponent
                      }, {
                        path: "groups",
                        pathMatch: "prefix",
                        children: [
                          {
                            pathMatch: "prefix",
                            matcher: Matcher.matchId("groupId"),
                            children: [
                              {
                                path: "receivers",
                                pathMatch: "full",
                                component: ReceiversOverviewPageComponent
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }, {
                path: "store",
                loadChildren: () => StoreRoutingModule
              }, {
                path: "features",
                pathMatch: "full",
                component: FeaturesOverviewPageComponent
              }, {
                path: "user-settings",
                pathMatch: "full",
                component: UserSettingsPageComponent
              }, {
                path: "payment-overview",
                pathMatch: "full",
                component: PaymentOverviewPageComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    GuardsModule.forRoot(),
    ResolversModule.forRoot(),
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [ RouterModule ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy
    }
  ]
})
export class AppRoutingModule {
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeaturesOverviewPageComponent } from "./features-overview-page.component";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { SharedModule } from "primeng/api";
import { TableModule } from "primeng/table";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import {
  IconComponent,
  PageLayoutModule,
  TabNavigationModule,
  TabNavigationStateService,
  tabNavigationStateServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { RouterModule } from "@angular/router";
import { CheckboxModule } from "primeng/checkbox";
import { ToggleButtonModule } from "primeng/togglebutton";


@NgModule({
  providers: [
    {
      provide: tabNavigationStateServiceInjectionToken,
      useClass: TabNavigationStateService
    }
  ],
  declarations: [
    FeaturesOverviewPageComponent
  ],
  imports: [
    CommonModule,
    PageLayoutModule,
    ButtonModule,
    PaginatorModule,
    SharedModule,
    TableModule,
    ZDictionaryModule,
    ZTemplateModule,
    TabNavigationModule,
    RouterModule,
    CheckboxModule,
    ToggleButtonModule,
    IconComponent
  ],
  exports: [
    FeaturesOverviewPageComponent
  ]
})
export class FeaturesOverviewPageModule {
}

import {IMapper} from "../i-mapper";
import {IApplicationSettingsTextField} from "@zaeper/angular-application-store-lib";
import {InputText} from "@zaeper/angular-dashboard-view-lib";
import {FormControl, FormGroup} from "@angular/forms";

export class ApplicationSettingsTextFieldMapper implements Partial<IMapper<IApplicationSettingsTextField, InputText>> {
  constructor(private readonly _formGroup: FormGroup) {
  }

  from(applicationSettingsTextField: IApplicationSettingsTextField): InputText {
    const formControl: FormControl<string> = new FormControl();

    this._formGroup.setControl(applicationSettingsTextField.id, formControl)

    return new InputText({
      formControlEntry: {
        formControl: formControl, key: applicationSettingsTextField.id
      },
      title: applicationSettingsTextField.label.de,
      inputId: applicationSettingsTextField.id,
      required: applicationSettingsTextField.required ?? false,
      placeholder: applicationSettingsTextField.placeholder?.de
    });
  }
}

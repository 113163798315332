import { Inject, Injectable } from '@angular/core';
import { IAuthenticationGuard } from "./i-authentication.guard";
import { HostUtil } from "../../../utils/host-util";
import { ICurrentUser } from "../../../models/i-current-user";
import {
    userAuthenticationRestClientInjectionToken
} from "../../../rest-clients/user/authentication/user-authentication-rest-client-injection-token";
import {
    IUserAuthenticationRestClient
} from "../../../rest-clients/user/authentication/i-user-authentication-rest.client";
import {
    sessionStateServiceInjectionToken
} from "../../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../../global-states/session/i-session-state.service";

@Injectable()
export class AuthenticationGuard implements IAuthenticationGuard {
    constructor(
        @Inject(userAuthenticationRestClientInjectionToken) private readonly _userRestClient: IUserAuthenticationRestClient,
        @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService
    ) {
    }

    isLoggedIn(): Promise<boolean> {
        const currentUser: ICurrentUser | null = this._sessionStateService.getCurrentUser();

        if(currentUser === null) {
            return this._userRestClient.getCurrentUser().then((currentUser: ICurrentUser): boolean => {
                const isLoggedIn: boolean = currentUser.email !== '';

                if (isLoggedIn) {
                    return true;
                }

                location.href = HostUtil.getAuthenticationSiteUrl();
                return false;
            }).catch(() => {
              location.href = HostUtil.getAuthenticationSiteUrl();
              return false;
            })
        } else {
            return Promise.resolve(true);
        }
    }
}

import { Inject, Pipe, PipeTransform } from "@angular/core";
import {
  appsOverviewStateServiceInjectionToken
} from "../../../applications-overview-modal/apps-overview-state-service-injection-token";
import { IAppsOverviewStateService } from "../../../applications-overview-modal/i-apps-overview-state-service";
import { IApp } from "@zaeper/angular-application-store-lib";

@Pipe({
  name: "checkAppAlreadyInstalled"
})

export class CheckAppAlreadyInstalledPipe implements PipeTransform {
  private readonly _installedApps: IApp[];

  constructor(@Inject(appsOverviewStateServiceInjectionToken) readonly _appsOverviewStateService: IAppsOverviewStateService) {
    this._installedApps = _appsOverviewStateService.getRecords();
  }

  transform(appId: string): boolean {
    return !!this._installedApps.find((app: IApp) => app.id === appId);
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  IGroupCreateModalForm,
  IGroupCreateUpdateModalService,
  IGroupUpdateModalForm
} from "./i-group-create-update-modal.service";
import { IModalService, modalServiceInjectionToken } from '@zaeper/angular-dashboard-view-lib';
import { GroupCreateUpdateModalComponent } from "./group-create-update-modal.component";
import { EGroupCreateUpdateMode } from "./e-group-create-update-mode";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Injectable()
export class GroupCreateUpdateModalService implements IGroupCreateUpdateModalService {
  constructor(@Inject(modalServiceInjectionToken) private readonly _modalService: IModalService) {
  }

  openCreateModal(groupCreateModalForm: IGroupCreateModalForm): void {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(GroupCreateUpdateModalComponent, {
      data: {
        mode: EGroupCreateUpdateMode.CREATE,
        form: groupCreateModalForm,
        onSaveCallback: () => {
          groupCreateModalForm.onSaveCallback?.();
          dynamicDialogRef.close();
        }
      },
      styleClass: 'bg-white rounded-lg',
      header: "Gruppe hinzufügen",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true
    });
  }

  openUpdateModal(groupUpdateModalForm: IGroupUpdateModalForm): void {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(GroupCreateUpdateModalComponent, {
      data: {
        mode: EGroupCreateUpdateMode.UPDATE,
        form: groupUpdateModalForm,
        onSaveCallback: () => {
          groupUpdateModalForm.onSaveCallback?.();
          dynamicDialogRef.close();
        }
      },
      styleClass: 'bg-white rounded-lg',
      header: "Gruppe editieren",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true
    });
  }
}

import {Injectable} from "@angular/core";
import {AApplicationRestClient} from "../a-application-rest.client";
import {IWidget, IWidgetRestClient} from "@zaeper/angular-application-store-lib";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class WidgetRestClient extends AApplicationRestClient<IWidget> implements IWidgetRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/widget"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, WidgetRestClient._RESOURCE_PATH);
  }
}

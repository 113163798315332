<zaeper-dropdown-menu #receiverMenu [menuItems]="(receiverMenuItems$ | async) ?? []">
  <ng-template zTemplate="trigger">
    <p-button
      [disabled]="!(activeReceiver$ | async)"
      [label]="(activeReceiver$ | async)?.name ?? 'Kein receiver verbunden'"
      [rounded]="true"
      class="flex flex-row items-center gap-x-1"
      icon="pi pi-chevron-down"
      iconPos="right"
    >
      <zaeper-icon class="mr-2" fontSet="material-icons-outlined">tv</zaeper-icon>
    </p-button>
  </ng-template>

  <ng-template zTemplate="top">
    <div class="bg-white flex flex-col gap-1">
      <div class="p-2">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input #searchInput [autofocus]="true" pInputText placeholder="Receiver suchen" type="text"/>
                  </span>
      </div>
      <div class="relative">
        <p-scroller (onLazyLoad)="lazyLoadItems($event)" [appendOnly]="true" [autoSize]="true"
                    [itemSize]="itemSize"
                    [items]="(records$ | async) ?? []"
                    [lazy]="true" [loading]="(isLoading$ | async) ?? true"
                    [numToleratedItems]="0" [scrollHeight]="((scrollHeight$ | async) ?? 0) + 'px'" [showLoader]="true"
                    [step]="1"
                    contentStyleClass="w-full"
                    styleClass="border-1 surface-border">
          <ng-template let-item let-options="options" pTemplate="item">
            <div
              (click)="switchToReceiver(item)"
              [ngClass]="{'bg-gray-300 font-semibold': (activeReceiver$ | async)?.id === item.id, 'hover:bg-gray-100': (activeReceiver$ | async)?.id !== item.id}"
              class="flex flex-row gap-2 items-center px-4 py-2 cursor-pointer">
              @if (item.isOnline) {
                <div class="flex-shrink-0 inline-block h-[15px] w-[15px] rounded-full bg-zaeper-success-300"></div>
              } @else {
                <div class="flex-shrink-0 inline-block h-[15px] w-[15px] rounded-full bg-gray-400"></div>
              }
              <span class="truncate">{{ item.name }}</span>
            </div>
          </ng-template>
        </p-scroller>
      </div>
    </div>
  </ng-template>
</zaeper-dropdown-menu>

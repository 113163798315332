<zaeper-page-layout [title]="'user-settings' | zDictionary" [pageLayout]="ePageLayout.CARDS" [navigation]="navigation">
    <ng-template zTemplate="header">
        <zaeper-icon fontSet="material-icons-outlined">manage_accounts</zaeper-icon>
    </ng-template>

    <zaeper-tab-navigation [tabs]="tabs">
        <ng-template zTemplate="tab">
            <app-user-properties-tab></app-user-properties-tab>
        </ng-template>
        <ng-template zTemplate="tab">
        </ng-template>
    </zaeper-tab-navigation>
</zaeper-page-layout>

<zaeper-content-slider>
  <zaeper-content-slide [centered]="true">
    <div class="flex flex-col gap-4">
      <zaeper-action-card
        imageUrl="assets/images/tv_icon.svg"
        title="Receiver App verbinden"
      >
        Klicken Sie hier auf verbinden, falls Sie eine Zaeper Applikation auf
        Android, iOS, Windows oder OS X installiert haben.
        <ng-template zTemplate="footerRight">
          <p-button
            [rounded]="true"
            (click)="nextSlide()"
            label="Verbinden"
            icon="pi pi-arrow-right"
            iconPos="right"
          ></p-button>
        </ng-template>
      </zaeper-action-card>
      <zaeper-action-card
        imageUrl="assets/images/usb_stick_icon.svg"
        title="Zaeper TV-Stick verbinden"
        [disabled]="true"
      >
        Klicken Sie hier auf verbinden, falls Sie eine Zaeper Applikation auf
        Android, iOS, Windows oder OS X installiert haben.
        <ng-template zTemplate="footerRight">
          <p-button
            (click)="nextSlide()"
            [rounded]="true"
            label="Verbinden"
            icon="pi pi-arrow-right"
            iconPos="right"
            [disabled]="true"
          ></p-button>
        </ng-template>
      </zaeper-action-card>
    </div>
  </zaeper-content-slide>

  <zaeper-content-slide [showBackBtn]="true">
    <div class="grid place-items-center w-full h-full">
      <div class="flex flex-col gap-4 items-center">
        <ng-lottie
          width="210px"
          height="150px"
          [options]="lottieConnectToCloud"
          (animationCreated)="animationCreated($event, 'lottieDashboard')"
        ></ng-lottie>
        <div class="text-center">
          <p>
            Öffnen Sie die Zaeper Receiver App auf Ihrem Gerät.<br />
            Geben Sie den angezeigten Verbindungscode in der App ein.
          </p>
        </div>

        <app-connection-code-display [cloudId]="cloudId" [groupId]="groupId"></app-connection-code-display>

        <a href="#"
          >Benötigen Sie Hilfe beim installieren der Receiver Applikation?</a
        >
      </div>
    </div>
  </zaeper-content-slide>

  <zaeper-content-slide>
    <div
      class="flex flex-col gap-4 place-content-center place-items-center w-full h-full"
    >
      <ng-lottie
        width="100px"
        height="100px"
        [options]="lottieCheck"
        (animationCreated)="animationCreated($event, 'lottieCheck')"
      ></ng-lottie>
      <div class="text-2xl">Receiver wurde erfolgreich verbunden</div>
      <p>Konfiguriere als nächstes den Receiver</p>
      <div class="flex flex-col gap-2 items-center">
        <p-button [rounded]="true" label="Einrichtung beenden"></p-button>
      </div>
    </div>
  </zaeper-content-slide>
</zaeper-content-slider>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EPageLayout, ITab } from "@zaeper/angular-dashboard-view-lib";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'app-user-settings-page',
  templateUrl: './user-settings-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsPageComponent {
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;

  public readonly navigation: MenuItem[] = [
    {
      icon: 'pi pi-home',
      routerLink: '/de/',
    },
    {
      label: 'Einstellungen',
      routerLink: '/de/user-settings',
    }
  ];

  public tabs: ITab[] = [
    {
      title: "Konto",
      subject: "account"
    },
    {
      title: "Zahlungsoptionen",
      subject: "payment"
    }
  ]
}

import {ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {
  confirmationModalServiceInjectionToken,
  EPageLayout,
  ESeverity,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import {MenuItem} from "primeng/api";
import {
  groupCreateUpdateModalServiceInjectionToken
} from "../../modals/group-create-update-modal/group-create-update-modal-service-injection-token";
import {
  IGroupCreateModalForm,
  IGroupCreateUpdateModalService
} from "../../modals/group-create-update-modal/i-group-create-update-modal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {ICloud} from "../../models/i-cloud";
import {IGroup} from "../../models/i-group";
import {groupsOverviewStateServiceInjectionToken} from "./groups-overview-state-service-injection-token";
import {IGroupsOverviewStateService} from "./i-groups-overview-state.service";
import {TableLazyLoadEvent} from "primeng/table";
import {IGetGroupPageForm} from "../../rest-clients/group/forms/i-get-group-page-form";
import {groupRestClientInjectionToken} from "../../rest-clients/group/group-rest-client-injection-token";
import {IGroupRestClient} from "../../rest-clients/group/i-group-rest.client";
import {IPage} from "@zaeper/communication-lib";
import {IDeleteGroupForm} from "../../rest-clients/group/forms/i-delete-group-form";
import {GroupsOverviewStateService} from "./groups-overview-state.service";
import {ICloudRestClient} from "../../rest-clients/cloud/i-cloud-rest.client";
import {cloudRestClientInjectionToken} from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import {IGetCloudForm} from "../../rest-clients/cloud/forms/i-get-cloud-form";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {IGetReceiverPageForm} from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import {IReceiver} from "../../models/i-receiver";
import {receiverRestClientInjectionToken} from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import {IReceiverRestClient} from "../../rest-clients/receiver/i-receiver-rest.client";
import {languageStateServiceInjectionToken} from "../../services/language/languageStateServiceInjectionToken";
import {ILanguageStateService} from "@zaeper/localization-lib";

@Component({
  selector: "app-groups-overview-page",
  templateUrl: "./groups-overview-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: groupsOverviewStateServiceInjectionToken,
    useClass: GroupsOverviewStateService
  }]
})
export class GroupsOverviewPageComponent implements OnInit {
  public readonly skeletonRows$: BehaviorSubject<number[]>;
  public readonly rowsPerPageOptions: number[] = [10, 20, 50];
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly records$: Observable<IGroup[]>;
  public readonly totalPages$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly cloudId: string;
  public readonly cloud$: Observable<ICloud | null>;
  public readonly cloudName$: Observable<string>;
  public readonly navigation: MenuItem[];
  private _pageSize: number = this.rowsPerPageOptions[0];
  private _pageNumber: number = 0;

  constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
              @Inject(groupCreateUpdateModalServiceInjectionToken) private readonly _groupCreateUpdateModalService: IGroupCreateUpdateModalService,
              @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
              @Inject(groupsOverviewStateServiceInjectionToken) private readonly _groupsOverviewStateService: IGroupsOverviewStateService,
              @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
              @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
              @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
              @Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService,
              private readonly _activatedRoute: ActivatedRoute,
              private readonly _router: Router) {
    this.cloudId = this._activatedRoute.snapshot.params["cloudId"];
    this.records$ = _groupsOverviewStateService.records$;
    this.totalPages$ = _groupsOverviewStateService.totalPages$;
    this.totalRecords$ = _groupsOverviewStateService.totalRecords$;
    this.cloud$ = _groupsOverviewStateService.cloud$;
    this.cloudName$ = _groupsOverviewStateService.cloudName$;
    this.navigation = this._getNavigation();

    const amountSkeletonRows: number = Math.max(Math.min(this._sessionStateService.getQuickAccessGroups().length, this._pageSize), 1);
    this.skeletonRows$ = new BehaviorSubject<number[]>(new Array(amountSkeletonRows));
  }

  ngOnInit(): void {
    const getCloudForm: IGetCloudForm = {
      cloudId: this.cloudId
    };

    this._cloudRestClient.getCloud(getCloudForm).then((cloud: ICloud) => {
      this._groupsOverviewStateService.setCloud(cloud);
      this._groupsOverviewStateService.setCloudName(cloud.name);
    });
  }

  public openCreateModal() {
    const groupCreateModalForm: IGroupCreateModalForm = {
      cloudId: this.cloudId,
      onSaveCallback: () => {
        this._loadGroupRecords();
      }
    };

    this._groupCreateUpdateModalService.openCreateModal(groupCreateModalForm);
  }

  public update(group: IGroup) {
    this._groupCreateUpdateModalService.openUpdateModal({
      cloudId: this.cloudId,
      groupId: group.id,
      name: group.name,
      description: group.description,
      onSaveCallback: () => {
        this._loadGroupRecords();
      }
    });
  }

  public delete(groupId: string) {
    this._confirmationModalService.open({
      title: `Gruppe "Gruppe 1" löschen`,
      description: "Gruppe inklusive Receivers löschen?",
      confirmationText: "Löschen",
      referenceLists: [{
        description: "Receiver",
        items: ["Receiver 1"]
      }],
      onConfirm: () => this._sendDeleteGroupRequest(groupId)
    });
  }

  public lazyLoadTable(tableLazyLoadEvent: TableLazyLoadEvent): Promise<void> {
    const first: number = tableLazyLoadEvent.first ?? 0;
    this._pageSize = tableLazyLoadEvent.rows ?? this.rowsPerPageOptions[0];
    this._pageNumber = Math.floor(first / this._pageSize);

    return this._loadGroupRecords();
  }

  public switchToGroup(group: IGroup): void {
    const getReceiverPageForm: IGetReceiverPageForm = {
      cloudId: this.cloudId,
      groupId: group.id,
      pageNumber: 0,
      pageSize: 1
    };

    const receiverPage: Promise<IPage<IReceiver>> = this._receiverRestClient.getReceiverPage(getReceiverPageForm);
    const language$: Promise<string> = firstValueFrom(this._languageStateService.language$);

    Promise.all([receiverPage, language$]).then(([receiverPage, language]: [IPage<IReceiver>, string]): void => {
      const firstReceiver: IReceiver = receiverPage.records[0] ?? null;
      const cloud: ICloud | null = this._groupsOverviewStateService.getCloud();

      if (this._sessionStateService.getActiveCloud()?.id !== this.cloudId && cloud !== null) {
        this._sessionStateService.setActiveCloud(cloud);
      }

      this._sessionStateService.setActiveGroup(group);
      this._sessionStateService.setActiveReceiver(firstReceiver);

      this._router.navigate([`/${language}/`]);
    });
  }

  private _getNavigation(): MenuItem[] {
    return [{
      icon: "pi pi-home",
      routerLink: "/de/"
    }, {
      label: "Clouds",
      routerLink: "/de/clouds"
    }, {
      label: "Groups",
      routerLink: `/de/clouds/${this.cloudId}/groups`
    }];
  }

  private _loadGroupRecords() {
    this.isLoading$.next(true);
    const skeletonRows: number[] = Array(this._groupsOverviewStateService.getRecords().length);
    if (skeletonRows.length > 0) {
      this.skeletonRows$.next(skeletonRows);
    }
    this._groupsOverviewStateService.setRecords([]);
    const getGroupPageForm: IGetGroupPageForm = {
      cloudId: this.cloudId,
      pageNumber: this._pageNumber,
      pageSize: this._pageSize
    };

    return this._groupRestClient.getGroupPage(getGroupPageForm).then((page: IPage<ICloud>) => {
      this._groupsOverviewStateService.setRecords(page.records);
      this._groupsOverviewStateService.setTotalRecords(page.totalRecords);
      this._groupsOverviewStateService.setTotalPages(page.totalPages);
      this.isLoading$.next(false);
    });
  }

  private _sendDeleteGroupRequest(groupId: string): Promise<void> {
    const deleteGroupForm: IDeleteGroupForm = {
      cloudId: this.cloudId,
      groupId
    };

    return this._groupRestClient.deleteGroup(deleteGroupForm).then(() => {
      this._toastService.setMessage({
        title: "Erfolgreich",
        detail: "Gruppe wurde erfolgreich gelöscht",
        severity: ESeverity.success
      });
    }).catch(() => {
      this._toastService.setMessage({
        title: "Fehler",
        detail: "Gruppe konnte nicht gelöscht werden",
        severity: ESeverity.error
      });
    }).finally(() => {
      this._loadGroupRecords();
    });
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { ToastComponent } from "@zaeper/angular-dashboard-view-lib";
import { DashboardLayoutModule } from "../../layout/dashboard-layout/dashboard-layout.module";
import { SplashLoaderComponent } from "../../components/splash-loader/splash-loader.component";
import { filter, map, Observable, Subscription } from "rxjs";
import { IStoreStateService, storeStateServiceInjectionToken } from "@zaeper/angular-application-store-lib";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { IReceiver } from "../../models/i-receiver";
import { ICloud } from "../../models/i-cloud";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-app-outlet",
  standalone: true,
  imports: [
    RouterOutlet, DashboardLayoutModule, ToastComponent, SplashLoaderComponent, CommonModule
  ],
  templateUrl: "./app-outlet.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppOutletComponent implements OnInit, OnDestroy {
  public readonly routerPath$: Observable<string>;
  private readonly _subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(storeStateServiceInjectionToken) private readonly _storeStateService: IStoreStateService,
    private readonly _router: Router
  ) {
    this.routerPath$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(map((event) => <NavigationEnd>event)).pipe(map((event) => event.url));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._subscriptions.add(this._sessionStateService.activeReceiver$.subscribe((receiver: IReceiver | null) => {
      this._storeStateService.setActiveReceiverId(receiver?.id ?? null);
    }));

    this._subscriptions.add(this._sessionStateService.activeCloud$.subscribe((cloud: ICloud | null) => {
      this._storeStateService.setActiveCloudId(cloud?.id ?? null);
    }));
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  EFieldType,
  ESeverity,
  IInputGroupOptions,
  IToastService,
  toastServiceInjectionToken
} from '@zaeper/angular-dashboard-view-lib';
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-schedule-receiver-config-push-modal',
  templateUrl: './schedule-receiver-config-push-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleReceiverConfigPushModalComponent {
  constructor(
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
    private readonly _dynamicDialogRef: DynamicDialogRef
  ) {
  }

  public scheduleReceiverConfigPushInputGroup: IInputGroupOptions = {
    groupId: "push-schedule-settings",
    description: "Stelle das gewünschte Datum ein, an dem die zurzeit konfigurierten Einstellungen vom Receiver geladen werden sollen.",
    children: [
      {
        minDate: new Date(),
        fieldType: EFieldType.DATE,
        placeholder: moment().format("DD.MM.YYYY"),
        required: true,
        formControlEntry: {
          formControl: new FormControl(new Date()),
          key: "push-date"
        },
        title: 'Datum',
        inputId: 'push-date'
      },
      {
        fieldType: EFieldType.TIME,
        placeholder: moment().format("HH:mm"),
        required: true,
        formControlEntry: {
          formControl: new FormControl(moment().format("HH:mm")),
          key: "push-date"
        },
        title: 'Uhrzeit',
        inputId: 'push-time'
      },
    ]
  }

  public close() {
    this._dynamicDialogRef.close();
  }

  public submit() {
    this.close();
    this._toastService.setMessage({
      title: "Erfolgreich",
      detail: "Laden der Receiver Konfigurationen wurde erfolgreich geplant",
      severity: ESeverity.success
    });
    return Promise.resolve();
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReceiversOverviewPageComponent } from "./receivers-overview-page.component";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { CardModule } from "primeng/card";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { IconComponent, PageLayoutModule } from "@zaeper/angular-dashboard-view-lib";
import { RouterLink } from "@angular/router";
import { SkeletonModule } from "primeng/skeleton";
import { InputTextModule } from "primeng/inputtext";
import { BadgeModule } from "primeng/badge";


@NgModule({
  declarations: [ ReceiversOverviewPageComponent ],
    imports: [
        CommonModule,
        PageLayoutModule,
        ZTemplateModule,
        ZDictionaryModule,
        CardModule,
        TableModule,
        ButtonModule,
        PaginatorModule,
        RouterLink,
        SkeletonModule,
        InputTextModule,
        IconComponent,
        BadgeModule
    ],
  exports: [
    ReceiversOverviewPageComponent
  ]
})
export class ReceiversOverviewPageModule {
}

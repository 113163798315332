import {ReceiverPreviewerPageComponent} from "./receiver-previewer-page.component";
import {ZDictionaryModule} from "@zaeper/angular-localization-lib";
import {IconComponent, ModalModule, PageLayoutModule, ToggleButtonComponent} from "@zaeper/angular-dashboard-view-lib";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {NgModule} from "@angular/core";
import {SelectButtonModule} from "primeng/selectbutton";
import {TooltipModule} from "primeng/tooltip";
import {LiveViewContainerComponent} from "../../components/live-view-container/live-view-container.component";
import {PreviewContainerComponent} from "../../components/preview-container/preview-container.component";

@NgModule({
  imports: [
    ButtonModule,
    ZDictionaryModule,
    CommonModule,
    ModalModule,
    NgOptimizedImage,
    PageLayoutModule,
    SelectButtonModule,
    IconComponent,
    TooltipModule,
    ToggleButtonComponent,
    LiveViewContainerComponent,
    PreviewContainerComponent
  ],
  exports: [ReceiverPreviewerPageComponent],
  declarations: [ReceiverPreviewerPageComponent]
})
export class ReceiverPreviewerPageModule {
}

import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit,} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {connectionCodeStateServiceInjectionToken} from "./connection-code-state-service-injection-token";
import {IConnectionCodeStateService} from "./i-connection-code-state.service";
import {connectionCodeServiceInjectionToken} from "./connection-code-service-injection-token";
import {IConnectionCodeService} from "./i-connection-code.service";

@Component( {
  selector: 'app-connection-code-display',
  templateUrl: 'connection-code-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
} )
export class ConnectionCodeDisplayComponent implements OnInit, OnDestroy {
  private static readonly _DEFAULT_CONNECTION_CODE_TTL_IN_SECONDS: number = 60;

  public readonly connectionCode$: Observable<string | null>;
  public readonly connectionCodeTtl$: BehaviorSubject<number> = new BehaviorSubject( 0 );
  private _countdown: ReturnType<typeof setTimeout> | null = null;
  private readonly _subscriptions: Subscription = new Subscription();

  @Input( "cloudId" ) public _cloudId: string | undefined;
  @Input( "groupId" ) public _groupId: string | undefined;

  constructor(
    @Inject( connectionCodeStateServiceInjectionToken ) private readonly _connectionCodeStateService: IConnectionCodeStateService,
    @Inject( connectionCodeServiceInjectionToken ) private readonly _connectionCodeService: IConnectionCodeService
  ) {
    this.connectionCode$ = this._connectionCodeStateService.connectionCode$;
  }

  ngOnInit() {
    this._countdown = setInterval( () => {
      this.connectionCodeTtl$.next( Math.max( this.connectionCodeTtl$.getValue() - 1, 0 ) );
    }, 1000 )

    this._subscriptions.add( this._connectionCodeStateService.connectionCode$.subscribe( () => {
      this.connectionCodeTtl$.next( ConnectionCodeDisplayComponent._DEFAULT_CONNECTION_CODE_TTL_IN_SECONDS );
    } ) )
  }

  public requestNewConnectionCode(): void {
    if ( this._cloudId && this._groupId ) {
      this._connectionCodeService.requestConnectionCode( this._cloudId, this._groupId );
    }
  }

  ngOnDestroy(): void {
    if ( this._countdown !== null ) {
      clearInterval( this._countdown );
    }

    this._subscriptions.unsubscribe();
  }
}

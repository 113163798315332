import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
  ITab,
  TabNavigationStateService,
  tabNavigationStateServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";

@Component({
  selector: 'app-available-apps-modal',
  templateUrl: './available-apps-overview-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: tabNavigationStateServiceInjectionToken,
      useClass: TabNavigationStateService
    }
  ]
})
export class AvailableAppsOverviewModalComponent {
  public tabs: ITab[] = [
    {
      title: "Gekauft",
      subject: "purchased"
    },
    {
      title: "Store",
      subject: "store"
    }
  ]
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LocalstorageUtil } from '@zaeper/core-lib';
import { HostUtil } from "../../utils/host-util";

@Component({
  selector: 'app-save-localstorage-page',
  templateUrl: './save-localstorage-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveLocalstoragePageComponent implements OnInit {
  ngOnInit(): void {
    window.addEventListener("message", (messageEvent: MessageEvent) => {
      if (messageEvent.origin !== HostUtil.getAuthenticationSiteUrl()) {
        console.error("Can't accept message from origin", messageEvent.origin, "Allowed origin", HostUtil.getAuthenticationSiteUrl());
        return
      }

      const data = JSON.parse(messageEvent.data)

      if (!!data.csrfToken) {
        LocalstorageUtil.persistCSRFToken(data.csrfToken)
      }

      if (!!data.refreshToken) {
        LocalstorageUtil.persistRefreshToken(data.refreshToken)
      }
    })
  }
}

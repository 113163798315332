<div *ngIf="connectionCode$ | async; else noConnectionCode" class="flex flex-col gap-4 text-center">
  <span class="text-5xl">
      {{ connectionCode$ | async }}
  </span>
  <ng-container
    *ngIf="(connectionCodeTtl$ | async)! > 0 || (connectionCodeTtl$ | async) === null; else connectionCodeExpired">
    <p class="text-zaeper-gray-200">
      Verbindungscode ist noch {{ (connectionCodeTtl$ | async) || 0 }} Sekunden verfügbar.
    </p>
  </ng-container>
  <ng-template #connectionCodeExpired>
    <p class="text-zaeper-error-300">
      Verbindungscode ist abgelaufen.
    </p>
    <p-button
      (onClick)="requestNewConnectionCode()"
      [label]="'regenerate-connection-code' | zDictionary"
      [rounded]="true"
      icon="pi pi-refresh"
    ></p-button>
  </ng-template>
</div>

<ng-template #noConnectionCode><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></ng-template>

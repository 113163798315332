import { ICloudRestClient } from "./i-cloud-rest.client";
import { ICloud } from "../../models/i-cloud";
import { HostUtil } from "../../utils/host-util";
import { firstValueFrom, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ARestClient, ICreateRecordResponse, IOkResponse, IPage, ISort } from "@zaeper/communication-lib";
import { Injectable } from "@angular/core";
import { ICreateCloudForm } from "./forms/i-create-cloud-form";
import { IDeleteCloudForm } from "./forms/i-delete-cloud-form";
import { IGetCloudForm } from "./forms/i-get-cloud-form";
import { IGetCloudPageForm } from "./forms/i-get-cloud-page-form";
import { IUpdateCloudForm } from "./forms/i-update-cloud-form";

@Injectable()
export class CloudRestClient extends ARestClient implements ICloudRestClient {
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }

  createCloud(createCloudForm: ICreateCloudForm): Promise<ICreateRecordResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), "v1/cloud");

    return firstValueFrom(this._httpClient.post(endpointUrl, createCloudForm, { withCredentials: true }) as Observable<ICreateRecordResponse>);
  }

  deleteCloud(deleteCloudForm: IDeleteCloudForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${deleteCloudForm.cloudId}`);

    return firstValueFrom(this._httpClient.delete(endpointUrl, { withCredentials: true }) as Observable<IOkResponse>);
  }

  getCloud(getCloudForm: IGetCloudForm): Promise<ICloud> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${getCloudForm.cloudId}`);

    return firstValueFrom(this._httpClient.get(endpointUrl, { withCredentials: true }) as Observable<ICloud>);
  }

  getCloudPage(getCloudPageForm: IGetCloudPageForm): Promise<IPage<ICloud>> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), "v1/cloud/page");

    const defaultSort: ISort = {
      field: "name",
      ascending: true
    };

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
      params: {
        pageNumber: getCloudPageForm.pageNumber,
        pageSize: getCloudPageForm.pageSize,
        sort: JSON.stringify(defaultSort)
      }
    }) as Observable<IPage<ICloud>>);
  }

  updateCloud(updateCloudForm: IUpdateCloudForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getInfrastructureApiEndpointUrl(), `v1/cloud/${updateCloudForm.cloudId}`);

    return firstValueFrom(this._httpClient.put(endpointUrl, updateCloudForm, { withCredentials: true }) as Observable<IOkResponse>);
  }
}

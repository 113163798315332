import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {
  confirmationModalServiceInjectionToken,
  EPageLayout,
  ESeverity,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken
} from '@zaeper/angular-dashboard-view-lib';
import {MenuItem} from "primeng/api";
import {
  cloudCreateUpdateModalServiceInjectionToken
} from "../../modals/cloud-create-update-modal/cloud-create-update-modal-service-injection-token";
import {
  ICloudCreateModalForm,
  ICloudCreateUpdateModalService
} from "../../modals/cloud-create-update-modal/i-cloud-create-update-modal.service";
import {cloudRestClientInjectionToken} from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import {ICloudRestClient} from "../../rest-clients/cloud/i-cloud-rest.client";
import {IGetCloudPageForm} from "../../rest-clients/cloud/forms/i-get-cloud-page-form";
import {cloudsOverviewStateServiceInjectionToken} from "./clouds-overview-state-service-injection-token";
import {ICloudsOverviewStateService} from "./i-clouds-overview-state-service";
import {ICloud} from "../../models/i-cloud";
import {IPage} from "@zaeper/communication-lib";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {IDeleteCloudForm} from "../../rest-clients/cloud/forms/i-delete-cloud-form";
import {TableLazyLoadEvent} from "primeng/table";
import {CloudsOverviewStateService} from "./clouds-overview-state.service";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {IGetGroupPageForm} from "../../rest-clients/group/forms/i-get-group-page-form";
import {IGroup} from "../../models/i-group";
import {IGetReceiverPageForm} from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import {IReceiver} from "../../models/i-receiver";
import {groupRestClientInjectionToken} from "../../rest-clients/group/group-rest-client-injection-token";
import {IGroupRestClient} from "../../rest-clients/group/i-group-rest.client";
import {receiverRestClientInjectionToken} from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import {IReceiverRestClient} from "../../rest-clients/receiver/i-receiver-rest.client";
import {Router} from '@angular/router';
import {languageStateServiceInjectionToken} from "../../services/language/languageStateServiceInjectionToken";
import {ILanguageStateService} from "@zaeper/localization-lib";

@Component({
  selector: 'app-clouds-overview-page',
  templateUrl: './clouds-overview-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: cloudsOverviewStateServiceInjectionToken,
    useClass: CloudsOverviewStateService
  }]
})
export class CloudsOverviewPageComponent implements OnInit {
  public readonly skeletonRows$: BehaviorSubject<number[]>;
  public readonly rowsPerPageOptions: number[] = [10, 20, 50];
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly records$: Observable<ICloud[]>;
  public readonly totalPages$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly navigation: MenuItem[] = [{
    icon: 'pi pi-home',
    routerLink: '/de/',
  }, {
    label: 'Clouds',
    routerLink: '/de/clouds',
  },];
  private _pageSize: number = this.rowsPerPageOptions[0];
  private _pageNumber: number = 0;

  constructor(@Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService,
              @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
              @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
              @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
              @Inject(cloudsOverviewStateServiceInjectionToken) private readonly _cloudsOverviewStateService: ICloudsOverviewStateService,
              @Inject(cloudCreateUpdateModalServiceInjectionToken) private readonly _cloudCreateUpdateModalService: ICloudCreateUpdateModalService,
              @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
              @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
              private readonly _router: Router) {
    this.records$ = _cloudsOverviewStateService.records$;
    this.totalPages$ = _cloudsOverviewStateService.totalPages$;
    this.totalRecords$ = _cloudsOverviewStateService.totalRecords$;

    const amountSkeletonRows: number = Math.max(Math.min(this._sessionStateService.getQuickAccessClouds().length, this._pageSize), 1);
    this.skeletonRows$ = new BehaviorSubject<number[]>(new Array(amountSkeletonRows));
  }

  public openCreateModal() {
    const cloudCreateModalForm: ICloudCreateModalForm = {
      onSaveCallback: () => {
        this._loadCloudRecords()
      }
    }

    this._cloudCreateUpdateModalService.openCreateModal(cloudCreateModalForm);
  }

  public update(cloud: ICloud) {
    this._cloudCreateUpdateModalService.openUpdateModal({
      cloudId: cloud.id,
      name: cloud.name,
      description: cloud.description,
      onSaveCallback: () => {
        this._loadCloudRecords();
      }
    });
  }

  public delete(cloudId: string) {
    this._confirmationModalService.open({
      title: `Cloud "Test" löschen`,
      description: "Cloud inklusive Gruppen und Receivers löschen?",
      referenceLists: [{
        description: "Gruppen",
        items: ["Gruppe 1"]
      }, {
        description: "Receiver",
        items: ["Receiver 1"]
      }],
      confirmationText: "Löschen",
      onConfirm: () => this._sendDeleteCloudRequest(cloudId)
    })
  }

  ngOnInit(): void {
  }

  public lazyLoadTable(tableLazyLoadEvent: TableLazyLoadEvent): Promise<void> {
    const first: number = tableLazyLoadEvent.first ?? 0;
    this._pageSize = tableLazyLoadEvent.rows ?? this.rowsPerPageOptions[0]
    this._pageNumber = Math.floor(first / this._pageSize);

    return this._loadCloudRecords();
  }

  public switchToCloud(cloud: ICloud): void {
    const getGroupPageForm: IGetGroupPageForm = {
      cloudId: cloud.id,
      pageNumber: 0,
      pageSize: 1
    };

    const groupPage: Promise<IPage<IGroup>> = this._groupRestClient.getGroupPage(getGroupPageForm);
    groupPage.then((groupPage: IPage<IGroup>): void => {
      const firstGroup: IGroup = groupPage.records[0] ?? null;

      this._sessionStateService.setActiveGroup(firstGroup);

      const getReceiverPageForm: IGetReceiverPageForm = {
        cloudId: cloud.id,
        groupId: firstGroup.id,
        pageNumber: 0,
        pageSize: 1
      };

      const receiverPage: Promise<IPage<IReceiver>> = this._receiverRestClient.getReceiverPage(getReceiverPageForm);
      const language$: Promise<string> = firstValueFrom(this._languageStateService.language$);
      Promise.all([receiverPage, language$]).then(([receiverPage, language]: [IPage<IReceiver>, string]): void => {
        const firstReceiver: IReceiver | null = receiverPage.records[0] ?? null;

        this._sessionStateService.setActiveCloud(cloud);
        this._sessionStateService.setActiveGroup(firstGroup);
        this._sessionStateService.setActiveReceiver(firstReceiver);

        this._router.navigate([`/${language}/`]);
      });
    });
  }

  private _loadCloudRecords() {
    this.isLoading$.next(true);
    const skeletonRows: number[] = Array(this._cloudsOverviewStateService.getRecords().length);
    if (skeletonRows.length > 0) {
      this.skeletonRows$.next(skeletonRows);
    }
    this._cloudsOverviewStateService.setRecords([]);
    const getCloudPageRequest: IGetCloudPageForm = {
      pageNumber: this._pageNumber,
      pageSize: this._pageSize
    }

    return this._cloudRestClient.getCloudPage(getCloudPageRequest).then((page: IPage<ICloud>) => {
      this._cloudsOverviewStateService.setRecords(page.records);
      this._cloudsOverviewStateService.setTotalRecords(page.totalRecords);
      this._cloudsOverviewStateService.setTotalPages(page.totalPages);
      this.isLoading$.next(false);
    })
  }

  private _sendDeleteCloudRequest(cloudId: string): Promise<void> {
    const deleteCloudForm: IDeleteCloudForm = {
      cloudId
    }

    return this._cloudRestClient.deleteCloud(deleteCloudForm).then(() => {
      this._toastService.setMessage({
        title: "Erfolgreich",
        detail: "Cloud wurde erfolgreich gelöscht",
        severity: ESeverity.success
      });
    }).catch(() => {
      this._toastService.setMessage({
        title: "Fehler",
        detail: "Cloud konnte nicht gelöscht werden",
        severity: ESeverity.error
      });
    }).finally(() => {
      this._loadCloudRecords();
    })
  }
}

<zaeper-page-layout [title]="'manage-clouds' | zDictionary" [pageLayout]="ePageLayout.CARDS" [navigation]="navigation">
  <ng-template zTemplate="header">
    <zaeper-icon fontSet="material-icons-outlined">cloud_queue</zaeper-icon>
  </ng-template>

  <div class="flex flex-col gap-6">
    <div class="flex justify-between">
      <!-- TabNavigation -->
      <div class="flex flex-row gap-2">
        <p-button icon="pi pi-plus" (onClick)="openCreateModal()" [rounded]="true"
                  [label]="'add-cloud' | zDictionary"></p-button>
      </div>

      <div class="p-input-icon-right self-center">
        <input pInputText type="text" [placeholder]="'search' | zDictionary: 'main'" />
        <i class="pi pi-search"></i>
      </div>
    </div>

    <p-table [paginator]="true" [rows]="10" [rowsPerPageOptions]="rowsPerPageOptions"
             [totalRecords]="(totalRecords$ | async) || 0" [lazy]="true" (onLazyLoad)="lazyLoadTable($event)"
             [loading]="(isLoading$ | async) ?? true" [showLoader]="false" [value]="(records$ | async) || []"
             [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr *ngFor="let i of (skeletonRows$ | async)">
          <td>
            <p-skeleton height="2rem"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="2rem"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="2rem"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="2rem"></p-skeleton>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          <th>Name</th>
          <th class="w-1/2">Beschreibung</th>
          <th>Aktionen</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cloudRecord let-i="rowIndex">
        <tr>
          <td>{{ i + 1 }}</td>
          <td><strong>{{ cloudRecord.name }}</strong></td>
          <td [ngClass]="{'text-gray-400': !cloudRecord.description}"><i>{{ cloudRecord.description ?? "Keine Beschreibung vorhanden" }}</i></td>
          <td class="flex gap-2 justify-end">
            <p-button (onClick)="delete(cloudRecord.id)" *ngIf="((totalRecords$ | async) || 0) > 1" icon="pi pi-trash"
                      severity="danger" [outlined]="true"
                      [rounded]="true"/>
            <p-button (onClick)="update(cloudRecord)" icon="pi pi-pencil" [outlined]="true" [rounded]="true"/>
            <p-button icon="pi pi-users" [outlined]="true" [rounded]="true"/>
            <p-button (onClick)="switchToCloud(cloudRecord)"
                      [outlined]="true"
                      [rounded]="true" icon="pi pi-arrow-right-arrow-left"/>
            <p-button [routerLink]="['/de/clouds/' + cloudRecord.id + '/groups']" [rounded]="true">Gruppen verwalten</p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</zaeper-page-layout>

import { ModuleWithProviders, NgModule } from '@angular/core';
import { sessionStateServiceInjectionToken } from "./session/session-state-service-injection-token";
import { SessionStateService } from "./session/session-state.service";

@NgModule({})
export class GlobalStatesModule {
    public static forRoot(): ModuleWithProviders<GlobalStatesModule> {
        return {
            ngModule: GlobalStatesModule, providers: [
                {
                    provide: sessionStateServiceInjectionToken, useClass: SessionStateService
                }
            ],
        }
    }
}

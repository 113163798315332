import { IEnvironment } from "./i-environment";

export const environment: IEnvironment = {
  api: {
    authentication: {
      useSSL: true,
      url: "api.qas.zaeper.com/auth"
    },
    receiverAuthentication: {
      useSSL: true,
      url: "api.qas.zaeper.com/receiver-auth"
    },
    receiver: {
      useSSL: true,
      url: "api.qas.zaeper.com/receiver"
    },
    infrastructure: {
      useSSL: true,
      url: "api.qas.zaeper.com/infrastructure"
    },
    application: {
      useSSL: true,
      url: "api.qas.zaeper.com/application"
    }
  },
  sites: {
    authentication: {
      useSSL: true,
      url: "auth.qas.zaeper.com"
    }
  },
  websockets: {
    receiver: {
      useSSL: true,
      url: "ws.qas.zaeper.com/receiver"
    },
    receiverAuthentication: {
      useSSL: true,
      url: "ws.qas.zaeper.com/receiver-authentication"
    }
  },
  applications: {
    previewer: {
      useSSL: true,
      url: "apps.receiver-previewer-app.zaeper.io"
    }
  }
};

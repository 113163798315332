import {IMapper} from "../i-mapper";
import {IApplicationSettingsTextAreaField} from "@zaeper/angular-application-store-lib";
import {InputTextarea} from "@zaeper/angular-dashboard-view-lib";
import {FormControl, FormGroup} from "@angular/forms";

export class ApplicationSettingsTextAreaFieldMapper implements Partial<IMapper<IApplicationSettingsTextAreaField, InputTextarea>> {
  constructor(private readonly _formGroup: FormGroup) {
  }

  from(applicationSettingsTextAreaField: IApplicationSettingsTextAreaField): InputTextarea {
    const formControl: FormControl<string> = new FormControl();

    this._formGroup.setControl(applicationSettingsTextAreaField.id, formControl)

    return new InputTextarea({
      formControlEntry: {
        formControl, key: applicationSettingsTextAreaField.id
      },
      title: applicationSettingsTextAreaField.label.de,
      inputId: applicationSettingsTextAreaField.id,
      required: applicationSettingsTextAreaField.required ?? false,
      placeholder: applicationSettingsTextAreaField.placeholder?.de,
      wysiwygEditor: applicationSettingsTextAreaField.useWYSIWYG,
    });
  }
}

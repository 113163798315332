import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-store-apps-tab",
  standalone: true,
  imports: [],
  templateUrl: "./store-apps-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreAppsTabComponent {

}

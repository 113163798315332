import {HttpClient} from "@angular/common/http";
import {AApplicationSettingsRestClient} from "../a-application-settings-rest.client";
import {IWidgetSettingsRestClient} from "@zaeper/angular-application-store-lib";
import {Injectable} from "@angular/core";

@Injectable()
export class WidgetSettingsRestClient extends AApplicationSettingsRestClient<IWidgetSettingsRestClient> implements IWidgetSettingsRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/widget-settings"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, WidgetSettingsRestClient._RESOURCE_PATH);
  }
}

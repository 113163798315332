import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {
  appRestClientInjectionToken,
  IApp,
  IAppRestClient,
  IGetPurchasedApplicationPageForm,
  IInstallApplicationForm,
  IPurchaseApplicationForm
} from "@zaeper/angular-application-store-lib";
import {TableLazyLoadEvent} from "primeng/table";
import {BehaviorSubject, Observable} from "rxjs";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {IPage} from "@zaeper/communication-lib";
import {installedAppsOverviewStateServiceInjectionToken} from "./installed-apps-overview-state-service-injection-token";
import {IInstalledAppsOverviewStateService} from "./i-installed-apps-overview-state-service";
import {IAvailableAppsOverviewModalForm} from "../../i-available-apps-overview-modal.service";
import {
  confirmationModalServiceInjectionToken,
  ESeverity,
  IConfirmationModalOptions,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";

@Component({
  selector: "app-installed-apps-tab",
  templateUrl: "./installed-apps-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstalledAppsTabComponent {
  public readonly skeletonRows$: BehaviorSubject<number[]>;
  public readonly rowsPerPageOptions: number[] = [
    10, 20, 50
  ];
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly records$: Observable<IApp[]>;
  public readonly totalPages$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly cloudId: string | null;
  public readonly receiverId: string | null;
  private readonly _onSaveCallback?: () => void;
  private _pageSize: number = this.rowsPerPageOptions[0];
  private _pageNumber: number = 0;

  constructor(
    @Inject(appRestClientInjectionToken) private readonly _appRestClient: IAppRestClient,
    @Inject(installedAppsOverviewStateServiceInjectionToken) private readonly _installedAppsOverviewStateService: IInstalledAppsOverviewStateService,
    @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
    private readonly _dynamicDialogRef: DynamicDialogRef,
    private readonly _dynamicDialogConfig: DynamicDialogConfig<IAvailableAppsOverviewModalForm>,
    private readonly _changeDetectionRef: ChangeDetectorRef
  ) {
    this.receiverId = this._dynamicDialogConfig.data?.receiverId ?? null;
    this.cloudId = this._dynamicDialogConfig.data?.cloudId ?? null;
    this._onSaveCallback = this._dynamicDialogConfig.data?.onSaveCallback;
    this.records$ = _installedAppsOverviewStateService.records$;
    this.totalPages$ = _installedAppsOverviewStateService.totalPages$;
    this.totalRecords$ = _installedAppsOverviewStateService.totalRecords$;

    const amountSkeletonRows: number = 2;
    this.skeletonRows$ = new BehaviorSubject<number[]>(new Array(amountSkeletonRows));
  }

  public lazyLoadTable(tableLazyLoadEvent: TableLazyLoadEvent): Promise<void> {
    const first: number = tableLazyLoadEvent.first ?? 0;
    this._pageSize = 10;
    this._pageNumber = Math.floor(first / this._pageSize);

    return this._loadAppRecords();
  }

  public install(appId: string) {
    const confirmationModalOptions: IConfirmationModalOptions = {
      title: "App installieren",
      description: "Möchten Sie die App auf Receiver XYZ installieren?",
      confirmationText: "Installieren",
      onConfirm: () => this._sendInstallRequest(appId)
    };

    this._confirmationModalService.open(confirmationModalOptions);
  }

  private _sendInstallRequest(appId: string): Promise<void> {
    if (!!this.receiverId && !!this.cloudId) {
      const purchaseApplicationForm: IPurchaseApplicationForm = {
        applicationId: appId,
        cloudId: this.cloudId
      };

      const installAppForm: IInstallApplicationForm = {
        applicationId: appId,
        receiverId: this.receiverId
      };

      return Promise.all([
        this._appRestClient.purchaseApplication(purchaseApplicationForm), this._appRestClient.installApplication(installAppForm)
      ]).then(() => {
        this._toastService.setMessage({
          title: "Erfolgreich",
          detail: "App wurde erfolgreich installiert",
          severity: ESeverity.success
        });
        this._onSaveCallback?.();
        this._dynamicDialogRef.close();
      }).catch(() => {
        this._toastService.setMessage({
          title: "Fehlgeschlagen",
          detail: "App konnte nicht installiert werden",
          severity: ESeverity.error
        });
      });
    }

    return Promise.resolve();
  }

  private _loadAppRecords() {
    if (!!this.cloudId) {
      this.isLoading$.next(true);
      const skeletonRows: number[] = Array(this._installedAppsOverviewStateService.getRecords().length);
      if (skeletonRows.length > 0) {
        this.skeletonRows$.next(skeletonRows);
      }
      this._installedAppsOverviewStateService.setRecords([]);
      this._changeDetectionRef.markForCheck();
      const getPurchasedApplicationPageForm: IGetPurchasedApplicationPageForm = {
        cloudId: this.cloudId,
        pageNumber: this._pageNumber,
        pageSize: this._pageSize
      };

      return this._appRestClient.getPurchasedApplicationPage(getPurchasedApplicationPageForm).then((page: IPage<IApp>) => {
        this._installedAppsOverviewStateService.setRecords(page.records);
        this._installedAppsOverviewStateService.setTotalRecords(page.totalRecords);
        this._installedAppsOverviewStateService.setTotalPages(page.totalPages);
        this.isLoading$.next(false);
      });
    }
    return Promise.resolve();
  }
}

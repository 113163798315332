import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ESeverity, IInputTextareaOptions, IInputTextOptions, IToastService, toastServiceInjectionToken
} from '@zaeper/angular-dashboard-view-lib';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ECloudCreateUpdateMode } from "./e-cloud-create-update-mode";
import { ICloudCreateUpdateModalData } from "./i-cloud-create-update-modal-data";
import {
  cloudRestClientInjectionToken
} from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import { ICloudRestClient } from "../../rest-clients/cloud/i-cloud-rest.client";
import { ICreateCloudForm } from "../../rest-clients/cloud/forms/i-create-cloud-form";
import { IUpdateCloudForm } from "../../rest-clients/cloud/forms/i-update-cloud-form";
import {
  infrastructureServiceInjectionToken
} from "../../services/infrastructure/infrastructure-service-injection-token";
import { IInfrastructureService } from "../../services/infrastructure/i-infrastructure.service";
import { ICloudUpdateModalForm } from "./i-cloud-create-update-modal.service";

@Component({
  selector: 'app-cloud-create-update-modal',
  templateUrl: './cloud-create-update-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudCreateUpdateModalComponent {
  public nameInputTextOptions: IInputTextOptions;
  public descriptionInputTextareaOptions: IInputTextareaOptions;
  public submitText: string;
  public formGroup: FormGroup;
  private _dialogData: ICloudCreateUpdateModalData | undefined;
  private _mode: ECloudCreateUpdateMode;

  constructor(@Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
              @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(infrastructureServiceInjectionToken) private readonly _infrastructureService: IInfrastructureService,
              private _dynamicDialogRef: DynamicDialogRef,
              private _dynamicDialogConfig: DynamicDialogConfig<ICloudCreateUpdateModalData>) {
    this._dialogData = this._dynamicDialogConfig.data;

    this.formGroup = new FormGroup({
      name: new FormControl(this._dialogData?.form?.name ?? '', {
        validators: [Validators.required],
      }),
      description: new FormControl(this._dialogData?.form?.description ?? '', [])
    }, {
      updateOn: "submit"
    });

    this._mode = this._dialogData?.mode ?? ECloudCreateUpdateMode.CREATE;

    this.submitText = this._getSubmitText(this._mode);

    this.nameInputTextOptions = {
      title: 'Name',
      inputId: 'cloud-name',
      placeholder: 'Name',
      required: true,
      formControlEntry: {
        formControl: this.formGroup.controls["name"] as FormControl<string>,
        key: 'name'
      }
    }

    this.descriptionInputTextareaOptions = {
      title: 'Beschreibung',
      inputId: 'cloud-description',
      placeholder: 'Beschreibung',
      required: false,
      formControlEntry: {
        formControl: this.formGroup.controls["description"] as FormControl<string>,
        key: 'description'
      },
      wysiwygEditor: false
    }
  }

  public close() {
    this._dynamicDialogRef.close();
  }

  public submit(event: Event) {
    event.preventDefault();

    if (this.formGroup.valid) {
      if (this._mode === ECloudCreateUpdateMode.CREATE) {
        this._createCloud().then(() => {
          this._toastService.setMessage({
            title: "Erfolgreich",
            detail: "Cloud wurde erfolgreich erstellt",
            severity: ESeverity.success
          })
        }).catch(() => {
          this._toastService.setMessage({
            title: "Fehler beim erstellen der Cloud",
            detail: "Cloud konnte nicht erstellt werden",
            severity: ESeverity.error
          })
        });
      } else if (this._mode === ECloudCreateUpdateMode.UPDATE) {
        const cloudUpdateModalForm: ICloudUpdateModalForm = (<ICloudUpdateModalForm>this._dialogData?.form);
        this._updateCloud(cloudUpdateModalForm).then(() => {
          this._toastService.setMessage({
            title: "Erfolgreich",
            detail: "Cloud wurde erfolgreich aktualisiert",
            severity: ESeverity.success
          })
        }).catch(() => {
          this._toastService.setMessage({
            title: "Fehler beim aktualisieren der Cloud",
            detail: "Cloud konnte nicht aktualisiert werden",
            severity: ESeverity.error
          })
        });
      }
    }
  }

  private _updateCloud(cloudUpdateModalForm: ICloudUpdateModalForm): Promise<void> {
    const updateCloudForm: IUpdateCloudForm = {
      cloudId: cloudUpdateModalForm.cloudId,
      name: this.formGroup.get("name")?.value,
      description: this.formGroup.get("description")?.value
    }

    return this._cloudRestClient.updateCloud(updateCloudForm).then(() => {
      this._dialogData?.onSaveCallback?.();
    })
  }

  private _createCloud(): Promise<void> {
    const createCloudForm: ICreateCloudForm = {
      name: this.formGroup.get("name")?.value,
      description: this.formGroup.get("description")?.value
    }

    return this._infrastructureService.createCloud(createCloudForm).then(() => {
      this._dialogData?.onSaveCallback?.();
    })
  }

  private _getSubmitText(cloudCreateUpdateMode: ECloudCreateUpdateMode): string {
    if (cloudCreateUpdateMode === ECloudCreateUpdateMode.CREATE) {
      return "Erstellen";
    } else {
      return "Aktualisieren";
    }
  }
}

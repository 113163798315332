import {IUserAuthenticationRestClient} from "./i-user-authentication-rest.client";
import {Injectable} from "@angular/core";
import {IResetPasswordRequest} from "./requests/i-reset-password-request";
import {firstValueFrom, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IResetPasswordResponse} from "./responses/i-reset-password-response";
import {IOkResponse} from "@zaeper/communication-lib";
import {HostUtil} from "../../../utils/host-util";
import {ICurrentUser} from "../../../models/i-current-user";
import {ARestClient} from "@zaeper/communication-lib";
import {LocalstorageUtil} from "@zaeper/core-lib";

@Injectable()
export class UserAuthenticationRestClient extends ARestClient implements IUserAuthenticationRestClient {
  private static readonly _REFRESH_TOKEN_HEADER_NAME: string = "REFRESH-TOKEN";

  constructor(private _httpClient: HttpClient) {
    super();
  }

  public getCurrentUser(): Promise<ICurrentUser> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getAuthenticationApiEndpointUrl(), "v1/current-user");

    return firstValueFrom(this._httpClient.get(endpointUrl, {withCredentials: true}) as Observable<ICurrentUser>);
  }

  public sendResetPasswordRequest(resetPasswordRequest: IResetPasswordRequest): Promise<IResetPasswordResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getAuthenticationApiEndpointUrl(), "v1/forgot-password");

    return firstValueFrom(this._httpClient.post(endpointUrl, resetPasswordRequest) as Observable<IResetPasswordResponse>);
  }

  public logout(): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getAuthenticationApiEndpointUrl(), "v1/logout");
    const refreshToken: string | null = LocalstorageUtil.getRefreshToken();

    if (refreshToken) {
      return firstValueFrom(this._httpClient.post(endpointUrl, {}, {
        withCredentials: true,
        headers: {
          [UserAuthenticationRestClient._REFRESH_TOKEN_HEADER_NAME]: refreshToken
        }
      }) as Observable<IOkResponse>);
    }

    return Promise.reject();
  }
}

<div class="flex flex-col gap-7">
  <zaeper-input-group [inputOptions]="emailInputGroup">
    <ng-template zTemplate="after">
      <p-button [rounded]="true">E-Mail ändern</p-button>
    </ng-template>
  </zaeper-input-group>
  <zaeper-input-group [inputOptions]="passwordInputGroup">
    <ng-template zTemplate="after">
      <p-button [rounded]="true" (onClick)="sendEmailPasswordRequest()">Anfrage senden</p-button>
    </ng-template>
  </zaeper-input-group>
  <zaeper-input-group [inputOptions]="deleteAccountInputGroup">
    <ng-template zTemplate="after">
      <p-button [rounded]="true">Anfrage senden</p-button>
    </ng-template>
  </zaeper-input-group>
</div>

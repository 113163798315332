import { NgModule } from "@angular/core";

import { ApplicationsOverviewModalComponent } from "./applications-overview-modal.component";
import { applicationsOverviewModalServiceInjectionToken } from "./applications-overview-modal-service-injection-token";
import { ApplicationsOverviewModalService } from "./applications-overview-modal.service";
import { AsyncPipe, CommonModule, NgForOf, NgIf } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { SharedModule } from "primeng/api";
import { SkeletonModule } from "primeng/skeleton";
import { TableModule } from "primeng/table";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { RouterLink } from "@angular/router";
import { appsOverviewStateServiceInjectionToken } from "./apps-overview-state-service-injection-token";
import { AppsOverviewStateService } from "./apps-overview-state.service";
import { TabNavigationModule } from "@zaeper/angular-dashboard-view-lib";
import { AppsOverviewTabComponent } from "./tabs/apps-overview-tab/apps-overview-tab.component";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { LauncherSettingsTabComponent } from "./tabs/launchers-overview-tab/launcher-settings-tab.component";

@NgModule({
  imports: [
    AsyncPipe,
    ButtonModule,
    InputTextModule,
    NgForOf,
    NgIf,
    SharedModule,
    SkeletonModule,
    TableModule,
    ZDictionaryModule,
    RouterLink,
    CommonModule,
    TabNavigationModule,
    AppsOverviewTabComponent,
    ZTemplateModule,
    LauncherSettingsTabComponent
  ],
  exports: [ ApplicationsOverviewModalComponent ],
  declarations: [ ApplicationsOverviewModalComponent ],
  providers: [
    {
      provide: applicationsOverviewModalServiceInjectionToken,
      useClass: ApplicationsOverviewModalService
    },
    {
      provide: appsOverviewStateServiceInjectionToken,
      useClass: AppsOverviewStateService
    }
  ]
})
export class ApplicationsOverviewModalModule {
}

import { Injectable } from "@angular/core";
import { AOverviewStateService } from "@zaeper/angular-dashboard-view-lib";
import { IReceiver } from "../../models/i-receiver";
import { IReceiverQuickAccessMenuStateService } from "./i-receiver-quick-access-menu-state.service";

@Injectable()
export class ReceiverQuickAccessMenuStateService extends AOverviewStateService<IReceiver> implements IReceiverQuickAccessMenuStateService {
  constructor() {
    super();
  }
}

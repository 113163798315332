import { environment } from "../../environments/environment";

export class HostUtil {
  public static getApplicationsPreviewerEndpointUrl(): string {
    const useSSL: boolean = environment.applications.previewer.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.applications.previewer.url;
    const port: number | null = this.getPort(environment.applications.previewer.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getAuthenticationApiEndpointUrl(): string {
    const useSSL: boolean = environment.api.authentication.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.api.authentication.url;
    const port: number | null = this.getPort(environment.api.authentication.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getReceiverAuthenticationApiEndpointUrl(): string {
    const useSSL: boolean = environment.api.receiverAuthentication.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.api.receiverAuthentication.url;
    const port: number | null = this.getPort(environment.api.receiverAuthentication.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getApplicationApiEndpointUrl(): string {
    const useSSL: boolean = environment.api.application.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.api.application.url;
    const port: number | null = this.getPort(environment.api.application.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getReceiverApiEndpointUrl(): string {
    const useSSL: boolean = environment.api.receiver.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.api.receiver.url;
    const port: number | null = this.getPort(environment.api.receiver.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getInfrastructureApiEndpointUrl(): string {
    const useSSL: boolean = environment.api.infrastructure.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.api.infrastructure.url;
    const port: number | null = this.getPort(environment.api.infrastructure.port)
    return this.generateUrl(protocol, url, port)
  }

  public static getAuthenticationSiteUrl(): string {
    const useSSL: boolean = environment.sites.authentication.useSSL;
    const protocol: string = this.getHttpProtocol(useSSL);
    const url: string = environment.sites.authentication.url;
    const port: number | null = this.getPort(environment.sites.authentication.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getReceiverWebsocketEndpointUrl(): string {
    const useSSL: boolean = environment.websockets.receiver.useSSL;
    const protocol: string = this.getWebsocketProtocol(useSSL);
    const url: string = environment.websockets.receiver.url;
    const port: number | null = this.getPort(environment.websockets.receiver.port);
    return this.generateUrl(protocol, url, port);
  }

  public static getReceiverAuthenticationWebsocketEndpointUrl(): string {
    const useSSL: boolean = environment.websockets.receiverAuthentication.useSSL;
    const protocol: string = this.getWebsocketProtocol(useSSL);
    const url: string = environment.websockets.receiverAuthentication.url;
    const port: number | null = this.getPort(environment.websockets.receiverAuthentication.port);
    return this.generateUrl(protocol, url, port);
  }

  private static getPort(port?: number): number | null {
    if (port === undefined || port === 80 || port === 8080 || port === 443) {
      return null;
    }
    return port;
  }

  private static getHttpProtocol(useSSL: boolean): string {
    if (useSSL) {
      return "https"
    } else {
      return "http"
    }
  }

  private static getWebsocketProtocol(useSSL: boolean): string {
    if (useSSL) {
      return "wss"
    } else {
      return "ws"
    }
  }

  private static generateUrl(
    protocol: string,
    url: string,
    port: number | null,
    queries?: Record<string, string>
  ): string {
    let generatedUrl: string;

    if (port !== null) {
      generatedUrl = `${protocol}://${url}:${port}`
    } else {
      generatedUrl = `${protocol}://${url}`
    }

    if (Object.keys(queries || {}).length > 0) {
      generatedUrl += "?";

      Object.entries(queries!).forEach((
        [key, value]: [string, string],
        index: number
      ): void => {
        if (index > 0) {
          generatedUrl += `&`;
        }
        generatedUrl += `${key}=${value}`;
      })
    }

    return generatedUrl;
  }
}

import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ITab } from "@zaeper/angular-dashboard-view-lib";

@Component({
  selector: "app-applications-overview-modal",
  templateUrl: "./applications-overview-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationsOverviewModalComponent {
  public tabs: ITab[] = [
    {
      title: "Apps",
      subject: "apps"
    },
    {
      title: "Launchers",
      subject: "launchers"
    },
    {
      title: "Widgets",
      subject: "widgets"
    }
  ]
}

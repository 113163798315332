import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EPageLayout } from '@zaeper/angular-dashboard-view-lib';
import { MenuItem } from "primeng/api";

@Component({
  selector: 'app-payment-overview-page',
  templateUrl: './payment-overview-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentOverviewPageComponent {
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;

  public readonly navigation: MenuItem[] = [
    {
      icon: 'pi pi-home',
      routerLink: '/de/',
    },
    {
      label: 'Abrechnungs Übersicht',
      routerLink: '/de/payment-overview',
    }
  ];
}

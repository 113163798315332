import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./routing/app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardLayoutModule } from "./layout/dashboard-layout/dashboard-layout.module";

import { DictionaryService, ILanguageStateService, LanguageStateService } from "@zaeper/localization-lib";
import { dictionaryServiceInjectionToken, ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { languageStateServiceInjectionToken } from "./services/language/languageStateServiceInjectionToken";
import { languageResolverServiceInjectionToken } from "./services/language/languageResolverServiceInjectionToken";
import { LanguageResolverService } from "./services/language/languageResolver.service";
import { DictionaryResolverService } from "./services/dictionary/dictionaryResolver.service";
import { ReceiverPreviewerPageModule } from "./pages/receiver-previewer-page/receiver-previewer-page.module";
import {
  dictionaryResolverServiceInjectionToken
} from "./services/dictionary/dictionary-resolver-service-injection-token";
import { HttpClientModule } from "@angular/common/http";

import { provideLottieOptions } from "ngx-lottie";
import { ReceiverRestClient } from "./rest-clients/receiver/receiver-rest.client";
import { receiverRestClientInjectionToken } from "./rest-clients/receiver/receiver-rest-client-injection-token";
import { CloudsOverviewPageModule } from "./pages/clouds-overview-page/clouds-overview-page.module";
import { GroupsOverviewPageModule } from "./pages/groups-overview-page/groups-overview-page.module";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { ReceiversOverviewPageModule } from "./pages/receivers-overview-page/receivers-overview-page.module";
import { FeaturesOverviewPageModule } from "./pages/features-overview-page/features-overview-page.module";
import { UserSettingsPageModule } from "./pages/user-settings-page/user-settings-page.module";
import { CloudCreateUpdateModalModule } from "./modals/cloud-create-update-modal/cloud-create-update-modal.module";
import {
  cloudCreateUpdateModalServiceInjectionToken
} from "./modals/cloud-create-update-modal/cloud-create-update-modal-service-injection-token";
import { CloudCreateUpdateModalService } from "./modals/cloud-create-update-modal/cloud-create-update-modal.service";
import { PaymentOverviewPageModule } from "./pages/payment-overview-page/payment-overview-page.module";
import {
  groupCreateUpdateModalServiceInjectionToken
} from "./modals/group-create-update-modal/group-create-update-modal-service-injection-token";
import { GroupCreateUpdateModalService } from "./modals/group-create-update-modal/group-create-update-modal.service";
import { GroupCreateUpdateModalModule } from "./modals/group-create-update-modal/group-create-update-modal.module";
import {
  scheduleReceiverConfigPushModalServiceInjectionToken
} from "./modals/schedule-receiver-config-push-modal/schedule-receiver-config-push-modal-service-injection-token";
import {
  ScheduleReceiverConfigPushModalService
} from "./modals/schedule-receiver-config-push-modal/schedule-receiver-config-push-modal.service";
import {
  ScheduleReceiverConfigPushModalModule
} from "./modals/schedule-receiver-config-push-modal/schedule-receiver-config-push-modal.module";
import { SaveLocalstoragePageModule } from "./pages/save-localstorage-page/save-localstorage-page.module";
import { GlobalStatesModule } from "./global-states/global-states.module";
import { ServicesModule } from "./services/services.module";
import { RestClientsModule } from "./rest-clients/rest-clients.modules";
import { SplashLoaderComponent } from "./components/splash-loader/splash-loader.component";
import { SplashLoaderModule } from "./components/splash-loader/splash-loader.module";
import { ConnectReceiverModalModule } from "./modals/connect-receiver-modal/connect-receiver-modal.module";
import {
  ConfirmationModalModule,
  ConfirmationModalService,
  confirmationModalServiceInjectionToken,
  MediaGalleryModule,
  ToastComponent,
  ToastService,
  toastServiceInjectionToken,
  ToastStateService,
  toastStateServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreStateService } from "./global-states/store/store-state.service";
import {
  applicationsOverviewModalServiceInjectionToken
} from "./modals/applications-overview-modal/applications-overview-modal-service-injection-token";
import {
  ApplicationsOverviewModalService
} from "./modals/applications-overview-modal/applications-overview-modal.service";
import {
  ApplicationsOverviewModalModule
} from "./modals/applications-overview-modal/applications-overview-modal.module";
import { storeStateServiceInjectionToken } from "@zaeper/angular-application-store-lib";
import {
  availableAppsOverviewModalServiceInjectionToken
} from "./modals/available-apps-overview-modal/available-apps-overview-modal-service-injection-token";
import {
  AvailableAppsOverviewModalService
} from "./modals/available-apps-overview-modal/available-apps-overview-modal.service";
import {
  AvailableAppsOverviewModalModule
} from "./modals/available-apps-overview-modal/available-apps-overview-modal.module";
import { WsClientsModule } from "./ws-clients/ws-clients.module";

const dictionaryServiceFactory = (languageStateService: ILanguageStateService) => {
  return new DictionaryService(languageStateService);
};

const pageModules = [
  ReceiverPreviewerPageModule,
  CloudsOverviewPageModule,
  GroupsOverviewPageModule,
  ReceiversOverviewPageModule,
  FeaturesOverviewPageModule,
  UserSettingsPageModule,
  PaymentOverviewPageModule,
  SaveLocalstoragePageModule
];

const modalModules = [
  CloudCreateUpdateModalModule,
  GroupCreateUpdateModalModule,
  ConfirmationModalModule,
  ScheduleReceiverConfigPushModalModule,
  ApplicationsOverviewModalModule,
  AvailableAppsOverviewModalModule
];

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    MediaGalleryModule.forRoot(),
    ServicesModule.forRoot(),
    RestClientsModule.forRoot(),
    WsClientsModule.forRoot(),
    ...pageModules,
    ...modalModules,
    GlobalStatesModule.forRoot(),
    SplashLoaderModule.forRoot(),
    ConnectReceiverModalModule,
    DashboardLayoutModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    PaginatorModule,
    TableModule,
    ZDictionaryModule,
    ZTemplateModule,
    SplashLoaderComponent,
    ToastComponent
  ],
  providers: [
    provideLottieOptions({
      player: () => import("lottie-web")
    }), {
      provide: toastServiceInjectionToken,
      useClass: ToastService
    }, {
      provide: receiverRestClientInjectionToken,
      useClass: ReceiverRestClient
    }, {
      provide: toastServiceInjectionToken,
      useClass: ToastService
    }, {
      provide: toastStateServiceInjectionToken,
      useClass: ToastStateService
    }, {
      provide: languageStateServiceInjectionToken,
      useClass: LanguageStateService
    }, {
      provide: languageResolverServiceInjectionToken,
      useClass: LanguageResolverService
    }, {
      provide: dictionaryServiceInjectionToken,
      useFactory: dictionaryServiceFactory,
      deps: [ languageStateServiceInjectionToken ]
    }, {
      provide: dictionaryResolverServiceInjectionToken,
      useClass: DictionaryResolverService
    }, {
      provide: confirmationModalServiceInjectionToken,
      useClass: ConfirmationModalService
    }, {
      provide: cloudCreateUpdateModalServiceInjectionToken,
      useClass: CloudCreateUpdateModalService
    }, {
      provide: groupCreateUpdateModalServiceInjectionToken,
      useClass: GroupCreateUpdateModalService
    }, {
      provide: scheduleReceiverConfigPushModalServiceInjectionToken,
      useClass: ScheduleReceiverConfigPushModalService
    }, {
      provide: applicationsOverviewModalServiceInjectionToken,
      useClass: ApplicationsOverviewModalService
    }, {
      provide: storeStateServiceInjectionToken,
      useClass: StoreStateService
    }, {
      provide: availableAppsOverviewModalServiceInjectionToken,
      useClass: AvailableAppsOverviewModalService
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {
}

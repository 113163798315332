import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AsyncPipe } from "@angular/common";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { HostUtil } from "../../utils/host-util";
import { LocalstorageUtil } from "@zaeper/core-lib";
import {
  userAuthenticationRestClientInjectionToken
} from "../../rest-clients/user/authentication/user-authentication-rest-client-injection-token";
import {
  IUserAuthenticationRestClient
} from "../../rest-clients/user/authentication/i-user-authentication-rest.client";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { BehaviorSubject, Observable } from "rxjs";
import { ICurrentUser } from "../../models/i-current-user";
import { DropdownMenuComponent } from "@zaeper/angular-dashboard-view-lib";
import { ZTemplateModule } from "@zaeper/angular-core-lib";

@Component({
  selector: "app-user-menu",
  standalone: true,
  imports: [
    AsyncPipe, AvatarModule, ButtonModule, DropdownMenuComponent, ZTemplateModule
  ],
  templateUrl: "./user-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent {
  public readonly currentUser$: Observable<ICurrentUser | null>;
  public readonly userMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  constructor(
    @Inject(userAuthenticationRestClientInjectionToken) private readonly _userAuthenticationRestClient: IUserAuthenticationRestClient,
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService
  ) {
    this.currentUser$ = this._sessionStateService.currentUser$;
    this.setUserMenuItems();
  }

  private setUserMenuItems(): void {
    this.userMenuItems$.next([
      {
        label: "Benutzereinstellungen",
        routerLink: "/de/user-settings"
      }, {
        label: "Rechnungsübersicht",
        routerLink: "/de/payment-overview"
      }, {
        label: "Abmelden",
        icon: "pi pi-sign-out",
        styleClass: "font-semibold",
        command: () => {
          this._logout();
        }
      }
    ]);
  }

  private _logout() {
    this._userAuthenticationRestClient.logout().then(() => {
      const authenticationSiteUrl: string = HostUtil.getAuthenticationSiteUrl();
      LocalstorageUtil.removeRefreshToken();
      LocalstorageUtil.removeCSRFToken();
      location.href = authenticationSiteUrl;
    });
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy } from "@angular/core";
import {
  confirmationModalServiceInjectionToken,
  EPageLayout,
  ESeverity,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { MenuItem } from "primeng/api";
import {
  connectReceiverModalServiceInjectionToken
} from "../../modals/connect-receiver-modal/connect-receiver-modal-service-injection-token";
import { IConnectReceiverModalService } from "../../modals/connect-receiver-modal/i-connect-receiver-modal.service";
import {
  scheduleReceiverConfigPushModalServiceInjectionToken
} from "../../modals/schedule-receiver-config-push-modal/schedule-receiver-config-push-modal-service-injection-token";
import {
  IScheduleReceiverConfigPushModalService
} from "../../modals/schedule-receiver-config-push-modal/i-schedule-receiver-config-push-modal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { receiversOverviewStateServiceInjectionToken } from "./receivers-overview-state-service-injection-token";
import { IReceiversOverviewStateService } from "./i-receivers-overview-state.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { IReceiver } from "../../models/i-receiver";
import { ReceiversOverviewStateService } from "./receivers-overview-state.service";
import { TableLazyLoadEvent } from "primeng/table";
import { IPage } from "@zaeper/communication-lib";
import { receiverRestClientInjectionToken } from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import { IReceiverRestClient } from "../../rest-clients/receiver/i-receiver-rest.client";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { IGetReceiverPageForm } from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import { ICloud } from "../../models/i-cloud";
import { cloudRestClientInjectionToken } from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import { ICloudRestClient } from "../../rest-clients/cloud/i-cloud-rest.client";
import { groupRestClientInjectionToken } from "../../rest-clients/group/group-rest-client-injection-token";
import { IGroupRestClient } from "../../rest-clients/group/i-group-rest.client";
import { IGroup } from "../../models/i-group";
import { IConnectReceiverModalData } from "../../modals/connect-receiver-modal/i-connect-receiver-modal-data";
import { IDeleteReceiverForm } from "../../rest-clients/receiver/forms/i-delete-receiver-form";
import {
  applicationsOverviewModalServiceInjectionToken
} from "../../modals/applications-overview-modal/applications-overview-modal-service-injection-token";
import {
  IApplicationsOverviewModalService
} from "../../modals/applications-overview-modal/i-applications-overview-modal.service";
import { receiverWsClientInjectionToken } from "../../ws-clients/receiver/receiver-ws-client-injection-token";
import { IReceiverWsClient } from "../../ws-clients/receiver/i-receiver-ws.client";
import { IConnectionStateMessage } from "../../ws-clients/receiver/messages/i-connection-state-message";

@Component({
  selector: "app-receivers-overview-page",
  templateUrl: "./receivers-overview-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: receiversOverviewStateServiceInjectionToken,
      useClass: ReceiversOverviewStateService
    }
  ]
})
export class ReceiversOverviewPageComponent implements AfterViewInit, OnDestroy {
  public readonly activeReceiver$: Observable<IReceiver | null>;
  public readonly skeletonRows$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([ 1, 2, 3, 4 ]);
  public readonly rowsPerPageOptions: number[] = [ 10, 20, 50 ];
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly records$: Observable<IReceiver[]>;
  public readonly totalPages$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly cloudId: string;
  public readonly groupId: string;
  public readonly navigation: MenuItem[];
  private readonly _subscriptions: Subscription = new Subscription();
  private _pageSize: number = this.rowsPerPageOptions[0];
  private _pageNumber: number = 0;

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
    @Inject(connectReceiverModalServiceInjectionToken) private readonly _connectReceiverModalService: IConnectReceiverModalService,
    @Inject(scheduleReceiverConfigPushModalServiceInjectionToken) private readonly _scheduleReceiverConfigPushModalService: IScheduleReceiverConfigPushModalService,
    @Inject(receiversOverviewStateServiceInjectionToken) private readonly _receiversOverviewStateService: IReceiversOverviewStateService,
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
    @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
    @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
    @Inject(applicationsOverviewModalServiceInjectionToken) private readonly _applicationsOverviewModalService: IApplicationsOverviewModalService,
    @Inject(receiverWsClientInjectionToken) private readonly _receiverWsClient: IReceiverWsClient,
    private readonly _activatedRoute: ActivatedRoute, private readonly _router: Router
  ) {
    this.activeReceiver$ = this._sessionStateService.activeReceiver$;
    this.cloudId = this._activatedRoute.snapshot.params["cloudId"];
    this.groupId = this._activatedRoute.snapshot.params["groupId"];
    this.records$ = this._receiversOverviewStateService.records$;
    this.totalPages$ = this._receiversOverviewStateService.totalPages$;
    this.totalRecords$ = this._receiversOverviewStateService.totalRecords$;
    this.navigation = this._getNavigation();

    const amountSkeletonRows: number = Math.max(Math.min(this._sessionStateService.getQuickAccessClouds().length, this._pageSize), 1);
    this.skeletonRows$ = new BehaviorSubject<number[]>(new Array(amountSkeletonRows));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this._handleConnectionStateEvent();
  }

  public openConnectReceiverModal(): void {
    const connectReceiverModalData: IConnectReceiverModalData = {
      cloudId: this.cloudId,
      groupId: this.groupId
    };
    this._connectReceiverModalService.open(connectReceiverModalData);
  }

  public openApplicationsOverviewModal(receiver: IReceiver): void {
    this._applicationsOverviewModalService.openApplicationsOverviewModal({
      cloudId: this.cloudId,
      receiver
    });
  }

  public switchToReceiver(receiver: IReceiver): void {
    this._confirmationModalService.open({
      title: "Receiver wechseln",
      description: "Zu ausgewählten Receiver wechseln?",
      confirmationText: "Wechseln",
      onConfirm: async () => {
        const cloudRequest: Promise<ICloud> = this._cloudRestClient.getCloud({
          cloudId: this.cloudId
        });
        const groupRequest: Promise<IGroup> = this._groupRestClient.getGroup({
          cloudId: this.cloudId,
          groupId: this.groupId
        });

        Promise.all([ cloudRequest, groupRequest ]).then(([ cloud, group ]: [ ICloud, IGroup ]) => {
          this._sessionStateService.setActiveCloud(cloud);
          this._sessionStateService.setActiveGroup(group);
          this._sessionStateService.setActiveReceiver(receiver);
          this._router.navigate([ "/de/" ]);
        });
      }
    });
  }

  public openPushReceiverConfigModal(): void {
    this._confirmationModalService.open({
      title: "Konfigurationen laden",
      description: "Soll der Receiver die zurzeit konfigurierten Einstellungen laden?",
      confirmationText: "Laden",
      onConfirm: () => {
        this._toastService.setMessage({
          title: "Erfolgreich",
          detail: "Befehl zum Laden der Einstellungen wurde erfolgreich versendet.",
          severity: ESeverity.success
        });
        return Promise.resolve();
      }
    });
  }

  public delete(receiverId: string): void {
    this._confirmationModalService.open({
      title: `Receiver "Receiver 1" löschen`,
      description: "Receiver löschen?",
      confirmationText: "Löschen",
      onConfirm: () => this._sendDeleteUserRequest(receiverId)
    });
  }

  public syncReceiverSettings(): void {
    this._confirmationModalService.open({
      title: `Receiver synchronisieren`,
      description: "Alle Receiver Einstellungen in dieser Gruppe synchronisieren?",
      confirmationText: "Synchronisieren",
      onConfirm: () => Promise.resolve(console.log("send sync receiver settings request"))
    });
  }

  public lazyLoadTable(tableLazyLoadEvent: TableLazyLoadEvent): Promise<void> {
    const first: number = tableLazyLoadEvent.first ?? 0;
    this._pageSize = tableLazyLoadEvent.rows ?? this.rowsPerPageOptions[0];
    this._pageNumber = Math.floor(first / this._pageSize);

    return this._loadReceiverRecords();
  }

  public openScheduleReceiverConfigPushModal(): void {
    this._scheduleReceiverConfigPushModalService.open();
  }

  private _handleConnectionStateEvent(): void {
    this._subscriptions.add(this._receiverWsClient.getConnectionState$().subscribe((connectionState: IConnectionStateMessage) => {
      const receiverRecords: IReceiver[] = this._receiversOverviewStateService.getRecords();
      const updatedReceiverRecords: IReceiver[] = receiverRecords.map((receiver: IReceiver) => {
        if (receiver.id === connectionState.receiverId) {
          return {
            ...receiver,
            isOnline: connectionState.connected
          };
        } else {
          return receiver;
        }
      });
      this._receiversOverviewStateService.setRecords(updatedReceiverRecords);
    }));
  }

  private _loadReceiverRecords(): Promise<void> {
    this.isLoading$.next(true);
    const skeletonRows: number[] = Array(this._receiversOverviewStateService.getRecords().length);
    if (skeletonRows.length > 0) {
      this.skeletonRows$.next(skeletonRows);
    }
    this._receiversOverviewStateService.setRecords([]);
    const getReceiverPageForm: IGetReceiverPageForm = {
      cloudId: this.cloudId,
      groupId: this.groupId,
      pageNumber: this._pageNumber,
      pageSize: this._pageSize
    };

    return this._receiverRestClient.getReceiverPage(getReceiverPageForm).then((page: IPage<IReceiver>) => {
      this._receiversOverviewStateService.setRecords(page.records);
      this._receiversOverviewStateService.setTotalRecords(page.totalRecords);
      this._receiversOverviewStateService.setTotalPages(page.totalPages);
      this.isLoading$.next(false);
    });
  }

  private _getNavigation(): MenuItem[] {
    return [
      {
        icon: "pi pi-home",
        routerLink: "/de/"
      }, {
        label: "Clouds",
        routerLink: "/de/clouds"
      }, {
        label: "Groups",
        routerLink: `/de/clouds/${this.cloudId}/groups`
      }, {
        label: "Receivers",
        routerLink: `/de/cloud/${this.cloudId}/groups/${this.groupId}/receivers`
      }
    ];
  }

  private _sendDeleteUserRequest(receiverId: string): Promise<void> {
    const deleteGroupForm: IDeleteReceiverForm = {
      cloudId: this.cloudId,
      groupId: this.groupId,
      receiverId
    };

    return this._receiverRestClient.deleteReceiver(deleteGroupForm).then(() => {
      this._toastService.setMessage({
        title: "Erfolgreich",
        detail: "Receiver wurde erfolgreich gelöscht",
        severity: ESeverity.success
      });
    }).catch(() => {
      this._toastService.setMessage({
        title: "Fehler",
        detail: "Receiver konnte nicht gelöscht werden",
        severity: ESeverity.error
      });
    }).finally(() => {
      this._loadReceiverRecords();
    });
  }
}

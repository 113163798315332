<zaeper-dropdown-menu #groupMenu [menuItems]="(groupMenuItems$ | async) ?? []">
  <ng-template zTemplate="trigger">
    <p-button
      [disabled]="false"
      [label]="(activeGroup$ | async)?.name"
      [rounded]="true"
      class="flex flex-row gap-x-1"
      icon="pi pi-chevron-down"
      iconPos="right"
    >
      <zaeper-icon class="mr-2" fontSet="material-icons-outlined">location_on</zaeper-icon>
    </p-button>
  </ng-template>

  <ng-template zTemplate="top">
    <div class="bg-white flex flex-col gap-1">
      <div class="p-2">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input #searchInput [autofocus]="true" pInputText placeholder="Gruppe suchen" type="text"/>
                  </span>
      </div>
      <div class="relative">
        <p-scroller (onLazyLoad)="lazyLoadItems($event)" [appendOnly]="true" [autoSize]="true"
                    [itemSize]="itemSize"
                    [items]="(records$ | async) ?? []"
                    [lazy]="true" [loading]="(isLoading$ | async) ?? true"
                    [numToleratedItems]="0" [scrollHeight]="((scrollHeight$ | async) ?? 0) + 'px'" [showLoader]="true"
                    [step]="1"
                    styleClass="border-1 surface-border">
          <ng-template let-item let-options="options" pTemplate="item">
            <div (click)="switchToGroup(item)"
                 [ngClass]="{'bg-gray-300 font-semibold': (activeGroup$ | async)?.id === item.id, 'hover:bg-gray-100': (activeGroup$ | async)?.id !== item.id}"
                 class="flex flex-row gap-2 items-center px-4 py-2 cursor-pointer">
              <span>{{ item.name }}</span>
            </div>
          </ng-template>
        </p-scroller>
      </div>
    </div>
  </ng-template>
</zaeper-dropdown-menu>

import { Injectable } from "@angular/core";
import { AOverviewStateService } from "@zaeper/angular-dashboard-view-lib";
import { ICloud } from "../../models/i-cloud";
import { ICloudQuickAccessMenuStateService } from "./i-cloud-quick-access-menu-state.service";

@Injectable()
export class CloudQuickAccessMenuStateService extends AOverviewStateService<ICloud> implements ICloudQuickAccessMenuStateService {
  constructor() {
    super();
  }
}

import { Inject, Injectable } from "@angular/core";
import { ICurrentUserResolver } from "./i-current-user.resolver";
import {
    sessionStateServiceInjectionToken
} from "../../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../../global-states/session/i-session-state.service";
import { userAuthenticationRestClientInjectionToken } from "../../../rest-clients/user/authentication/user-authentication-rest-client-injection-token";
import { IUserAuthenticationRestClient } from "../../../rest-clients/user/authentication/i-user-authentication-rest.client";
import { ICurrentUser } from "../../../models/i-current-user";

@Injectable()
export class CurrentUserResolver implements ICurrentUserResolver {
    constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService, @Inject(userAuthenticationRestClientInjectionToken) private readonly _userRestClient: IUserAuthenticationRestClient) {
    }

    async getCurrentUser(): Promise<void> {
        const currentUser: ICurrentUser = await this._userRestClient.getCurrentUser();
        this._sessionStateService.setCurrentUser(currentUser);
    }
}

<div class="flex flex-col gap-6 pt-6">
  <zaeper-tab-navigation [tabs]="tabs">
    <ng-template zTemplate="tab">
      <app-apps-overview-tab />
    </ng-template>
    <ng-template zTemplate="tab">
      <app-launcher-settings-tab />
    </ng-template>
    <ng-template zTemplate="tab">
    </ng-template>
  </zaeper-tab-navigation>
</div>

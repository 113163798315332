import {AApplicationSettingsRestClient} from "../a-application-settings-rest.client";
import {ILauncherSettings} from "@zaeper/angular-application-store-lib/lib/models/launcher/i-launcher-settings";
import {ILauncherSettingsRestClient} from "@zaeper/angular-application-store-lib";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LauncherSettingsRestClient extends AApplicationSettingsRestClient<ILauncherSettings> implements ILauncherSettingsRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/launcher-settings"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, LauncherSettingsRestClient._RESOURCE_PATH);
  }
}

import { ISplashLoaderStateService } from "./i-splash-loader-state.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class SplashLoaderStateService implements ISplashLoaderStateService {
    private readonly _isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public readonly isVisible$: Observable<boolean> = this._isVisible$.asObservable();

    setIsVisible(isVisible: boolean): void {
        this._isVisible$.next(isVisible);
    }
}

import {IMapper} from "./i-mapper";
import {
  EApplicationSettingsFieldType,
  IApp,
  IApplicationSettingsAppSelectField,
  IApplicationSettingsTextAreaField,
  IApplicationSettingsTextField,
  TLauncherSettingsFieldType,
  ILauncherSettings
} from "@zaeper/angular-application-store-lib";
import {TFieldElement} from "@zaeper/angular-dashboard-view-lib";
import {ApplicationSettingsTextFieldMapper} from "./settings-input-field/application-settings-text-field.mapper";
import {
  ApplicationSettingsTextAreaFieldMapper
} from "./settings-input-field/application-settings-textarea-field.mapper";
import {FormGroup} from "@angular/forms";
import {
  ApplicationSettingsAppSelectFieldMapper
} from "./settings-input-field/application-settings-app-select-field.mapper";

export class LauncherSettingsInputFieldMapper implements Partial<IMapper<ILauncherSettings, TFieldElement[]>> {
  constructor(private readonly _apps: IApp[]) {
  }

  from(launcherSettings: ILauncherSettings): TFieldElement[] {
    const formGroup: FormGroup = new FormGroup({
    });

    return launcherSettings.fields.reduce((acc: TFieldElement[], curr: TLauncherSettingsFieldType): TFieldElement[] => {
      switch (curr.type) {
        case EApplicationSettingsFieldType.TEXT:
          const applicationSettingsTextFieldMapper: ApplicationSettingsTextFieldMapper = new ApplicationSettingsTextFieldMapper(formGroup);
          acc.push(applicationSettingsTextFieldMapper.from(<IApplicationSettingsTextField>curr));
          break;
        case EApplicationSettingsFieldType.TEXT_AREA:
          const applicationSettingsTextAreaFieldMapper: ApplicationSettingsTextAreaFieldMapper = new ApplicationSettingsTextAreaFieldMapper(formGroup);
          acc.push(applicationSettingsTextAreaFieldMapper.from(<IApplicationSettingsTextAreaField>curr));
          break;
        case "app-select":
          const applicationSettingsAppSelectFieldMapper: ApplicationSettingsAppSelectFieldMapper = new ApplicationSettingsAppSelectFieldMapper(this._apps, formGroup);
          acc.push(applicationSettingsAppSelectFieldMapper.from(<IApplicationSettingsAppSelectField>curr));
      }
      return acc;
    }, [])
  }
}

<zaeper-dashboard-layout
  [dashboardLayout]="dashboardLayout"
  [navItems]="navItems"
  branding="assets/images/zaeper_logo_z.svg"
>
  <ng-template zTemplate="topBarLeft">
    <div class="relative flex flex-row items-center h-full gap-x-2">
      <app-cloud-quick-access-menu />
      <app-group-quick-access-menu />
      <app-receiver-quick-access-menu/>
    </div>
  </ng-template>

  <ng-template zTemplate="topBarRight">
    <app-user-menu />
  </ng-template>

  <ng-template zTemplate="body">
    <ng-content></ng-content>
  </ng-template>
</zaeper-dashboard-layout>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
  ESeverity, IInputTextareaOptions, IInputTextOptions, IToastService, toastServiceInjectionToken
} from '@zaeper/angular-dashboard-view-lib';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IGroupCreateUpdateModalData } from "./i-group-create-update-modal-data";
import { EGroupCreateUpdateMode } from "./e-group-create-update-mode";
import {
  groupRestClientInjectionToken
} from "../../rest-clients/group/group-rest-client-injection-token";
import { IGroupRestClient } from "../../rest-clients/group/i-group-rest.client";
import { ICreateGroupForm } from "../../rest-clients/group/forms/i-create-group-form";
import { IUpdateGroupForm } from "../../rest-clients/group/forms/i-update-group-form";
import { IGroupCreateModalForm, IGroupUpdateModalForm } from "./i-group-create-update-modal.service";

@Component({
  selector: 'app-group-create-update-modal',
  templateUrl: './group-create-update-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCreateUpdateModalComponent {
  public nameInputTextOptions: IInputTextOptions;
  public descriptionInputTextareaOptions: IInputTextareaOptions;
  public submitText: string;
  public formGroup: FormGroup;
  private readonly _mode: EGroupCreateUpdateMode;
  private readonly _dialogData: IGroupCreateUpdateModalData | undefined;

  constructor(@Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
              private _dynamicDialogRef: DynamicDialogRef,
              private _dynamicDialogConfig: DynamicDialogConfig<IGroupCreateUpdateModalData>) {
    this._dialogData = this._dynamicDialogConfig.data;

    this._mode = this._dialogData?.mode ?? EGroupCreateUpdateMode.CREATE;

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this._mode === EGroupCreateUpdateMode.CREATE ? '' : (<IGroupUpdateModalForm>this._dialogData?.form)?.name ?? '', {
        validators: [Validators.required],
      }),
      description: new UntypedFormControl(this._mode === EGroupCreateUpdateMode.CREATE ? '' : (<IGroupUpdateModalForm>this._dialogData?.form)?.description ?? '', [])
    }, {
      updateOn: 'submit',
    });

    this.submitText = this._getSubmitText(this._dialogData?.mode ?? EGroupCreateUpdateMode.CREATE);

    this.nameInputTextOptions = {
      title: 'Name',
      inputId: 'group-name',
      placeholder: 'Name',
      required: true,
      formControlEntry: {
        formControl: this.formGroup.get('name')! as FormControl<string>,
        key: 'group-name'
      }
    }

    this.descriptionInputTextareaOptions = {
      title: 'Beschreibung',
      inputId: 'group-description',
      placeholder: 'Beschreibung',
      required: false,
      formControlEntry: {
        formControl: this.formGroup.get('description')! as FormControl<string>,
        key: 'group-description'
      },
      wysiwygEditor: false
    }
  }

  public close() {
    this._dynamicDialogRef.close();
  }

  public submit(event: Event) {
    event.preventDefault();

    if (this.formGroup.valid) {
      if (this._mode === EGroupCreateUpdateMode.CREATE) {
        const form: IGroupCreateModalForm = (<IGroupCreateModalForm>this._dialogData?.form);

        this._createGroup(form).then(() => {
          this._toastService.setMessage({
            title: "Erfolgreich",
            detail: "Gruppe wurde erfolgreich erstellt",
            severity: ESeverity.success
          })
        }).catch(() => {
          this._toastService.setMessage({
            title: "Fehler beim erstellen der Gruppe",
            detail: "Gruppe konnte nicht erstellt werden",
            severity: ESeverity.error
          })
        });
      } else if (this._mode === EGroupCreateUpdateMode.UPDATE) {
        const groupUpdateModalForm: IGroupUpdateModalForm = (<IGroupUpdateModalForm>this._dialogData?.form);
        this._updateGroup(groupUpdateModalForm).then(() => {
          this._toastService.setMessage({
            title: "Erfolgreich",
            detail: "Gruppe wurde erfolgreich aktualisiert",
            severity: ESeverity.success
          })
        }).catch(() => {
          this._toastService.setMessage({
            title: "Fehler beim aktualisieren der Gruppe",
            detail: "Gruppe konnte nicht aktualisiert werden",
            severity: ESeverity.error
          })
        });
      }
    }
  }

  private _updateGroup(groupUpdateModalForm: IGroupUpdateModalForm): Promise<void> {
    const updateGroupForm: IUpdateGroupForm = {
      cloudId: groupUpdateModalForm.cloudId,
      groupId: groupUpdateModalForm.groupId,
      name: this.formGroup.get("name")?.value,
      description: this.formGroup.get("description")?.value
    }

    return this._groupRestClient.updateGroup(updateGroupForm).then(() => {
      this._dialogData?.onSaveCallback?.();
    })
  }

  private _createGroup(groupCreateModalForm: IGroupCreateModalForm): Promise<void> {
    const createGroupForm: ICreateGroupForm = {
      cloudId: groupCreateModalForm.cloudId,
      name: this.formGroup.get("name")?.value,
      description: this.formGroup.get("description")?.value
    }

    return this._groupRestClient.createGroup(createGroupForm).then(() => {
      this._dialogData?.onSaveCallback?.();
    })
  }

  private _getSubmitText(groupCreateUpdateMode: EGroupCreateUpdateMode): string {
    if (groupCreateUpdateMode === EGroupCreateUpdateMode.CREATE) {
      return "Erstellen";
    } else {
      return "Aktualisieren";
    }
  }
}

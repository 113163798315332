import { Inject, Injectable } from "@angular/core";
import { IMessage } from "@stomp/rx-stomp";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { HostUtil } from "../../utils/host-util";
import { map, Observable } from "rxjs";
import { IReceiverRegisteredMessage } from "./messages/i-receiver-registered-message";
import { IReceiverAuthenticationWsClient } from "./i-receiver-authentication-ws.client";
import { AWebsocketClient } from "@zaeper/communication-lib";

@Injectable()
export class ReceiverAuthenticationWsClient extends AWebsocketClient implements IReceiverAuthenticationWsClient {
  private readonly _receiverRegistered$: Observable<IReceiverRegisteredMessage>;

  constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService) {
    super(HostUtil.getReceiverAuthenticationWebsocketEndpointUrl(), "stomp");

    this._receiverRegistered$ = this._connection
      .watch({ destination: `/${this._sessionStateService.getCurrentUser()?.id}/receiver-registered` }).pipe(map((message: IMessage) => JSON.parse(message.body)));
  }

  getReceiverRegistered$(): Observable<IReceiverRegisteredMessage> {
    return this._receiverRegistered$;
  }
}

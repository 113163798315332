import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {IApp, IAppRestClient} from "@zaeper/angular-application-store-lib";
import {AApplicationRestClient} from "../a-application-rest.client";

@Injectable()
export class AppRestClient extends AApplicationRestClient<IApp> implements IAppRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/app"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, AppRestClient._RESOURCE_PATH);
  }
}

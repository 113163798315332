import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, switchMap, take, tap} from 'rxjs';
import {IDictionaryResolverService} from './i-dictionary-resolver-service';
import {TJsonMap} from "@zaeper/core-lib";
import {IDictionaryService, ILanguageStateService} from "@zaeper/localization-lib";
import {languageStateServiceInjectionToken} from "../language/languageStateServiceInjectionToken";
import {dictionaryServiceInjectionToken} from "@zaeper/angular-localization-lib";

@Injectable()
export class DictionaryResolverService implements IDictionaryResolverService {
  constructor(
    @Inject(languageStateServiceInjectionToken)
    private _languageStateService: ILanguageStateService,
    @Inject(dictionaryServiceInjectionToken)
    private _dictionaryService: IDictionaryService,
    private _httpClient: HttpClient
  ) {
  }

  resolve(): Observable<[string, Object]> {
    return this._languageStateService.language$
      .pipe(
        switchMap((language: string) => {
          return this._httpClient
            .get(`/assets/dicts/main/${language}.json`)
            .pipe(
              map((httpResponse: Object): [string, Object] => {
                return [language, httpResponse];
              })
            );
        })
      )
      .pipe(
        tap(([language, dictionary]: [string, Object]) => {
          const dict: TJsonMap = <TJsonMap>dictionary;
          this._dictionaryService.setDictionary('main', language, dict);
        })
      )
      .pipe(take(1));
  }
}

<div #liveViewContainer class="live-view-container h-full w-full">
  <div class="live-view-container__no-stream-placeholder h-full w-full">
    <div
      class="live-view-container__no-stream-placeholder-text text-center grid place-content-center text-white h-full w-full bg-black bg-opacity-50">
      <div *ngIf="(liveViewConnected$ | async) === false; else noStreamAvailableMessage"
           class="flex gap-4 flex-col h-full w-full">
        <h2>Liveansicht wird gestartet...</h2>
      </div>
      <ng-template #noStreamAvailableMessage>
        <div class="flex gap-4 flex-col h-full w-full">
          <div class="flex gap-2 flex-row items-center justify-center">
            <zaeper-icon fontSet="material-icons-outlined">warning</zaeper-icon>
            <h2>Keine Video Übertragung möglich</h2></div>
          <div>Vergewissern Sie sich das der Receiver eingeschaltet ist und beim Receiver die Videoübertragung
            gestartet wurde.
          </div>
          <p-button [rounded]="true" label="Mehr erfahren"></p-button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

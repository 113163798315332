import {IMapper} from "../i-mapper";
import {IApp, IApplicationSettingsAppSelectField} from "@zaeper/angular-application-store-lib";
import {InputSelection, ISelectionOption} from "@zaeper/angular-dashboard-view-lib";
import {FormControl, FormGroup} from "@angular/forms";

export class ApplicationSettingsAppSelectFieldMapper implements Partial<IMapper<IApplicationSettingsAppSelectField, InputSelection>> {
  constructor(private readonly _apps: IApp[], private readonly _formGroup: FormGroup) {
  }

  from(applicationSettingsAppSelectField: IApplicationSettingsAppSelectField): InputSelection {
    const defaultValue: string | null = this._getDefaultValue(applicationSettingsAppSelectField.autoSelectFirst ?? false, this._apps);
    const selections: ISelectionOption[] = this._getSelections(this._apps);

    const formControl: FormControl<string | null> = new FormControl(defaultValue);

    this._formGroup.setControl(applicationSettingsAppSelectField.id, formControl)

    return new InputSelection({
      formControlEntry: {
        formControl: formControl, key: applicationSettingsAppSelectField.id
      },
      title: applicationSettingsAppSelectField.label.de,
      inputId: applicationSettingsAppSelectField.id,
      required: applicationSettingsAppSelectField.required ?? false,
      placeholder: applicationSettingsAppSelectField.placeholder?.de,
      selections: selections
    });
  }

  private _getDefaultValue(autoSelectFirst: boolean, apps: IApp[]): string | null {
    if (autoSelectFirst && apps.length > 0) {
      return this._apps[0].id
    }
    return null;
  }

  private _getSelections(apps: IApp[]): ISelectionOption[] {
    return apps.map((app: IApp): ISelectionOption => ({
      title: app.name, value: app.id
    }));
  }
}

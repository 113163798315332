import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConnectionCodeDisplayComponent } from './connection-code-display.component';
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { ZDictionaryModule } from '@zaeper/angular-localization-lib';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ZDictionaryModule,
    MessageModule,
    MessagesModule
  ],
  exports: [ConnectionCodeDisplayComponent],
  declarations: [ConnectionCodeDisplayComponent],
  providers: [],
})
export class ConnectionCodeDisplayModule {
}

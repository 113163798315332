import { ModuleWithProviders, NgModule } from '@angular/core';
import { infrastructureServiceInjectionToken } from "./infrastructure/infrastructure-service-injection-token";
import { InfrastructureService } from "./infrastructure/infrastructure.service";
import { livekitClientInjectionToken } from "./livekit/livekit-client-injection-token";
import { LivekitClient } from "./livekit/livekit.client";

@NgModule()
export class ServicesModule {
    public static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule,
            providers: [
                {
                    provide: infrastructureServiceInjectionToken,
                    useClass: InfrastructureService
                },
                {
                    provide: livekitClientInjectionToken,
                    useClass: LivekitClient
                }
            ]
        }
    }
}

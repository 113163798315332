import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { AsyncPipe, CommonModule, NgForOf, NgIf } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { SharedModule } from "primeng/api";
import { SkeletonModule } from "primeng/skeleton";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { BehaviorSubject, Observable } from "rxjs";
import {
  confirmationModalServiceInjectionToken,
  ESeverity,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import {
  appRestClientInjectionToken,
  IApp,
  IAppRestClient, IGetInstalledApplicationPageForm, IUninstallApplicationForm
} from "@zaeper/angular-application-store-lib";
import { appsOverviewStateServiceInjectionToken } from "../../apps-overview-state-service-injection-token";
import { IAppsOverviewStateService } from "../../i-apps-overview-state-service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IApplicationsOverviewModalForm } from "../../i-applications-overview-modal.service";
import { IPage } from "@zaeper/communication-lib";
import { Router, RouterLink } from "@angular/router";
import { InputTextModule } from "primeng/inputtext";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import {
  availableAppsOverviewModalServiceInjectionToken
} from "../../../available-apps-overview-modal/available-apps-overview-modal-service-injection-token";
import {
  IAvailableAppsOverviewModalForm,
  IAvailableAppsOverviewModalService
} from "../../../available-apps-overview-modal/i-available-apps-overview-modal.service";
import {IReceiver} from "../../../../models/i-receiver";

@Component({
  selector: "app-apps-overview-tab",
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    NgForOf,
    NgIf,
    SharedModule,
    SkeletonModule,
    TableModule,
    CommonModule,
    RouterLink,
    InputTextModule,
    ZDictionaryModule
  ],
  templateUrl: "./apps-overview-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppsOverviewTabComponent {
  public readonly skeletonRows$: BehaviorSubject<number[]>;
  public readonly rowsPerPageOptions: number[] = [
    10, 20, 50
  ];
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly records$: Observable<IApp[]>;
  public readonly totalPages$: Observable<number>;
  public readonly totalRecords$: Observable<number>;
  public readonly cloudId: string | null;
  public readonly receiver: IReceiver | null;
  private _pageSize: number = this.rowsPerPageOptions[0];
  private _pageNumber: number = 0;

  constructor(
    @Inject(appRestClientInjectionToken) private readonly _appRestClient: IAppRestClient,
    @Inject(appsOverviewStateServiceInjectionToken) private readonly _appsOverviewStateService: IAppsOverviewStateService,
    @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService, private _router: Router,
    @Inject(availableAppsOverviewModalServiceInjectionToken) private readonly _availableAppsOverviewModalService: IAvailableAppsOverviewModalService,
    private readonly _dynamicDialogRef: DynamicDialogRef,
    private readonly _dynamicDialogConfig: DynamicDialogConfig<IApplicationsOverviewModalForm>,
    private readonly _changeDetectionRef: ChangeDetectorRef
  ) {
    this.receiver = this._dynamicDialogConfig.data?.receiver ?? null;
    this.cloudId = this._dynamicDialogConfig.data?.cloudId ?? null;
    this.records$ = _appsOverviewStateService.records$;
    this.totalPages$ = _appsOverviewStateService.totalPages$;
    this.totalRecords$ = _appsOverviewStateService.totalRecords$;

    const amountSkeletonRows: number = 2;
    this.skeletonRows$ = new BehaviorSubject<number[]>(new Array(amountSkeletonRows));
  }

  public uninstall(app: IApp) {
    if (!!this.receiver) {
      this._confirmationModalService.open({
        title: `App "${app.name}" deinstallieren`,
        description: `Möchten Sie die App "${app.name} deinstallieren"? Alle zugehörigen Einstellungen werden dadurch auch gelöscht.`,
        confirmationText: "Deinstallieren",
        onConfirm: () => this._sendUninstallAppRequest(app.id, this.receiver!.id)
      });
    }
  }

  public openAvailableApps() {
    if (!!this.cloudId && !!this.receiver) {
      const availableAppsOverviewModalForm: IAvailableAppsOverviewModalForm = {
        cloudId: this.cloudId,
        receiverId: this.receiver.id,
        onSaveCallback: () => this._loadAppRecords()
      };

      this._availableAppsOverviewModalService.openAvailableAppsOverviewModal(availableAppsOverviewModalForm);
    }
  }

  public lazyLoadTable(tableLazyLoadEvent: TableLazyLoadEvent): Promise<void> {
    const first: number = tableLazyLoadEvent.first ?? 0;
    this._pageSize = 10;
    this._pageNumber = Math.floor(first / this._pageSize);

    return this._loadAppRecords();
  }

  public switchToStore() {
    this._dynamicDialogRef.close();
    this._router.navigate([ "de/store" ]);
  }

  private _loadAppRecords() {
    if (!!this.receiver) {
      this.isLoading$.next(true);
      const skeletonRows: number[] = Array(this._appsOverviewStateService.getRecords().length);
      if (skeletonRows.length > 0) {
        this.skeletonRows$.next(skeletonRows);
      }
      this._appsOverviewStateService.setRecords([]);
      this._changeDetectionRef.markForCheck();
      const getInstalledApplicationPageForm: IGetInstalledApplicationPageForm = {
        receiverId: this.receiver.id,
        pageNumber: this._pageNumber,
        pageSize: this._pageSize
      };

      return this._appRestClient.getInstalledApplicationPage(getInstalledApplicationPageForm).then((page: IPage<IApp>) => {
        this._appsOverviewStateService.setRecords(page.records);
        this._appsOverviewStateService.setTotalRecords(page.totalRecords);
        this._appsOverviewStateService.setTotalPages(page.totalPages);
        this.isLoading$.next(false);
      });
    }
    return Promise.resolve();
  }

  private _sendUninstallAppRequest(appId: string, receiverId: string): Promise<void> {
    const uninstallApplicationForm: IUninstallApplicationForm = {
      applicationId: appId,
      receiverId
    };

    return this._appRestClient.uninstallApplication(uninstallApplicationForm).then(() => {
      this._toastService.setMessage({
        title: "Erfolgreich",
        detail: "App wurde erfolgreich deinstalliert",
        severity: ESeverity.success
      });
    }).catch(() => {
      this._toastService.setMessage({
        title: "Fehler",
        detail: "App konnte nicht deinstalliert werden",
        severity: ESeverity.error
      });
    }).finally(() => {
      this._loadAppRecords();
    });
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { currentUserResolverInjectionToken } from "./session/current-user-resolver-injection-token";
import { CurrentUserResolver } from "./session/current-user.resolver";
import { quickAccessResourcesResolverInjectionToken } from "./session/quick-access-resources-resolver-injection-token";
import { QuickAccessResourcesResolver } from "./session/quick-access-resources-resolver.service";

@NgModule()
export class ResolversModule {
    public static forRoot(): ModuleWithProviders<ResolversModule> {
        return {
            ngModule: ResolversModule,
            providers: [
                {
                    provide: currentUserResolverInjectionToken,
                    useClass: CurrentUserResolver
                },
                {
                    provide: quickAccessResourcesResolverInjectionToken,
                    useClass: QuickAccessResourcesResolver
                }
            ]
        }
    }
}

import {IApplicationSettings, IApplicationSettingsRestClient} from "@zaeper/angular-application-store-lib";
import {ARestClient} from "@zaeper/communication-lib";
import {HttpClient} from "@angular/common/http";
import {
  IGetApplicationSettingsForm
} from "@zaeper/angular-application-store-lib/lib/rest-clients/forms/i-get-application-settings-form";
import {HostUtil} from "../../utils/host-util";
import {firstValueFrom, Observable} from "rxjs";

export abstract class AApplicationSettingsRestClient<T extends IApplicationSettings> extends ARestClient implements IApplicationSettingsRestClient<T> {
  protected constructor(protected readonly _httpClient: HttpClient, protected readonly _resourcePath: string) {
    super();
  }

  getApplicationSettings(getApplicationSettingsForm: IGetApplicationSettingsForm): Promise<T> {
    const applicationId: string = getApplicationSettingsForm.applicationId;
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/${applicationId}`);

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
    }) as Observable<T>);
  };
}

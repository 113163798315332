import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import {
  confirmationModalServiceInjectionToken, EFieldType,
  ESeverity,
  IConfirmationModalService,
  IInputGroupOptions,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { FormControl } from "@angular/forms";
import {
  userAuthenticationRestClientInjectionToken
} from "../../../../rest-clients/user/authentication/user-authentication-rest-client-injection-token";
import {
  IUserAuthenticationRestClient
} from "../../../../rest-clients/user/authentication/i-user-authentication-rest.client";

@Component({
  selector: "app-user-properties-tab",
  templateUrl: "./user-properties-tab.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class UserPropertiesTabComponent {
  public emailInputGroup: IInputGroupOptions = {
    groupId: "email-settings",
    title: "E-Mail verwalten",
    description: "Ändere hier deine aktuelles E-Mail Adresse",
    children: [
      {
        fieldType: EFieldType.TEXT,
        placeholder: "Neue E-Mail Adresse",
        required: true,
        formControlEntry: {
          formControl: new FormControl(""),
          key: "test"
        },
        title: "Neue E-Mail Adresse",
        inputId: "new-email"
      }, {
        fieldType: EFieldType.TEXT,
        placeholder: "Neue E-Mail Adresse bestätigen",
        required: true,
        formControlEntry: {
          formControl: new FormControl(""),
          key: "test"
        },
        title: "Neue E-Mail Adresse bestätigen",
        inputId: "email-confirmation"
      }
    ]
  };
  public passwordInputGroup: IInputGroupOptions = {
    groupId: "password-settings",
    title: "Passwort zurücksetzen",
    description: "Sende eine Anfrage zum zurücksetzen des Passworts per E-Mail zu",
    children: []
  };
  public deleteAccountInputGroup: IInputGroupOptions = {
    groupId: "account-deactivation-settings",
    title: "Konto löschen",
    description: "Sende eine Anfrage zum löschen deines Kontos per E-Mail zu",
    children: []
  };

  constructor(@Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService, @Inject(userAuthenticationRestClientInjectionToken) private readonly _userRestClient: IUserAuthenticationRestClient, @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService) {
  }

  public sendEmailPasswordRequest(): void {
    this._confirmationModalService.open({
      title: "Passwort Anfrage senden",
      description: "E-Mail zum zurücksenden des Passworts an dennis@zaeper.com senden?",
      confirmationText: "Senden",
      onConfirm: () => this.sendResetPasswordRequest()
    });
  }

  private sendResetPasswordRequest(): Promise<void> {
    return this._userRestClient.sendResetPasswordRequest({
      email: "dennis@zaeper.com"
    })
      .then(() => {
        this._toastService.setMessage({
          title: "Erfolgreich",
          detail: "Anfrage zum zurücksetzen des Passworts wurde erfolgreich versendet",
          severity: ESeverity.success
        });
      })
      .catch(() => {
        this._toastService.setMessage({
          title: "Fehler bei der Anfrage",
          detail: "Anfrage zum zurücksetzen des Passworts konnte nicht versendet werden",
          severity: ESeverity.error
        });
      });
  }
}

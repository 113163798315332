import { IStoreStateService } from "@zaeper/angular-application-store-lib";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class StoreStateService implements IStoreStateService {
  private readonly _activeReceiverId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly activeReceiverId$: Observable<string | null> = this._activeReceiverId$.asObservable();
  private readonly _activeCloudId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly activeCloudId$: Observable<string | null> = this._activeCloudId$.asObservable();

  public getActiveReceiverId(): string | null {
    return this._activeReceiverId$.getValue();
  }

  public setActiveReceiverId(receiverId: string | null) {
    this._activeReceiverId$.next(receiverId);
  }

  public getActiveCloudId(): string | null {
    return this._activeCloudId$.getValue();
  }

  public setActiveCloudId(cloudId: string | null) {
    this._activeCloudId$.next(cloudId);
  }
}

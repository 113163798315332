import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GroupsOverviewPageComponent } from "./groups-overview-page.component";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { CardModule } from "primeng/card";
import { IconComponent, PageLayoutModule } from "@zaeper/angular-dashboard-view-lib";
import { RouterLink } from "@angular/router";
import { SkeletonModule } from "primeng/skeleton";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [ GroupsOverviewPageComponent ],
  imports: [
    CommonModule,
    PageLayoutModule,
    ZTemplateModule,
    ZDictionaryModule,
    CardModule,
    TableModule,
    ButtonModule,
    PaginatorModule,
    RouterLink,
    SkeletonModule,
    InputTextModule,
    IconComponent
  ],
  exports: [
    GroupsOverviewPageComponent
  ]
})
export class GroupsOverviewPageModule {
}

<zaeper-dropdown-menu #receiverMenu [menuItems]="(userMenuItems$ | async) ?? []" orientation="right">
  <ng-template zTemplate="trigger">
    <p-button
      [label]="(currentUser$ | async)?.email"
      [text]="true"
      icon="pi pi-chevron-down"
      iconPos="right"
      styleClass="text-black whitespace-nowrap"
    >
      <p-avatar [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }" label="DB" shape="circle"
                styleClass="mr-2"></p-avatar>
    </p-button>
  </ng-template>
</zaeper-dropdown-menu>

import { Injectable } from "@angular/core";
import { IGroup } from "../../models/i-group";
import { IGroupsOverviewStateService } from "./i-groups-overview-state.service";
import { AOverviewStateService } from "@zaeper/angular-dashboard-view-lib";
import { BehaviorSubject, Observable } from "rxjs";
import { ICloud } from "../../models/i-cloud";

@Injectable()
export class GroupsOverviewStateService extends AOverviewStateService<IGroup> implements IGroupsOverviewStateService {
  private readonly _cloud$: BehaviorSubject<ICloud | null> = new BehaviorSubject<ICloud | null>(null);
  private readonly _cloudName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly cloudName$: Observable<string> = this._cloudName$.asObservable();
  public readonly cloud$: Observable<ICloud | null> = this._cloud$.asObservable();

  public setCloud(cloud: ICloud): void {
    return this._cloud$.next(cloud);
  }

  public getCloud(): ICloud | null {
    return this._cloud$.getValue();
  }

  public getCloudName(): string {
    return this._cloudName$.getValue();
  }

  public setCloudName(cloudName: string): void {
    this._cloudName$.next(cloudName);
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  ICloudCreateModalForm,
  ICloudCreateUpdateModalService,
  ICloudUpdateModalForm
} from "./i-cloud-create-update-modal.service";
import { IModalService, modalServiceInjectionToken } from '@zaeper/angular-dashboard-view-lib';
import { CloudCreateUpdateModalComponent } from "./cloud-create-update-modal.component";
import { ECloudCreateUpdateMode } from "./e-cloud-create-update-mode";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Injectable()
export class CloudCreateUpdateModalService implements ICloudCreateUpdateModalService {
  constructor(@Inject(modalServiceInjectionToken) private readonly _modalService: IModalService) {
  }

  openCreateModal(cloudCreateModalForm: ICloudCreateModalForm): void {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(CloudCreateUpdateModalComponent, {
      data: {
        mode: ECloudCreateUpdateMode.CREATE,
        onSaveCallback: () => {
          cloudCreateModalForm.onSaveCallback?.();
          dynamicDialogRef.close();
        }
      },
      styleClass: 'bg-white rounded-lg',
      header: "Cloud hinzufügen",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true
    });
  }

  openUpdateModal(cloudUpdateModalForm: ICloudUpdateModalForm): void {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(CloudCreateUpdateModalComponent, {
      data: {
        mode: ECloudCreateUpdateMode.UPDATE,
        form: cloudUpdateModalForm,
        onSaveCallback: () => {
          cloudUpdateModalForm.onSaveCallback?.(),
          dynamicDialogRef.close();
        }
      },
      styleClass: 'bg-white rounded-lg',
      header: "Cloud editieren",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true
    });
  }
}

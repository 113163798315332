import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardLayoutComponent } from "./dashboard-layout.component";
import {
  DashboardLayoutModule as ZDashboardLayoutModule,
  DropdownMenuComponent,
  IconComponent
} from "@zaeper/angular-dashboard-view-lib";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { ListboxModule } from "primeng/listbox";
import { BadgeModule } from "primeng/badge";
import { AvatarModule } from "primeng/avatar";
import { SpeedDialModule } from "primeng/speeddial";
import { InputTextModule } from "primeng/inputtext";
import {
  ReceiverQuickAccessMenuComponent
} from "../../menus/receiver-quick-access-menu/receiver-quick-access-menu.component";
import { GroupQuickAccessMenuComponent } from "../../menus/group-quick-access-menu/group-quick-access-menu.component";
import { CloudQuickAccessMenuComponent } from "../../menus/cloud-quick-access-menu/cloud-quick-access-menu.component";
import { UserMenuComponent } from "../../menus/user-menu/user-menu.component";

@NgModule({
  declarations: [ DashboardLayoutComponent ],
  exports: [
    DashboardLayoutComponent
  ],
  imports: [
    CommonModule,
    ZDashboardLayoutModule,
    ButtonModule,
    MenuModule,
    ListboxModule,
    BadgeModule,
    IconComponent,
    AvatarModule,
    SpeedDialModule,
    InputTextModule,
    DropdownMenuComponent,
    ReceiverQuickAccessMenuComponent,
    GroupQuickAccessMenuComponent,
    CloudQuickAccessMenuComponent,
    UserMenuComponent
  ]
})
export class DashboardLayoutModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupCreateUpdateModalComponent } from './group-create-update-modal.component';
import { ButtonModule } from "primeng/button";
import { InputTextModule, InputTextareaModule } from '@zaeper/angular-dashboard-view-lib';
import { ReactiveFormsModule } from "@angular/forms";



@NgModule({
  declarations: [
    GroupCreateUpdateModalComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        InputTextModule,
        InputTextareaModule,
        ReactiveFormsModule
    ]
})
export class GroupCreateUpdateModalModule { }

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { splashLoaderServiceInjectionToken } from "./components/splash-loader/splash-loader-service-injection-token";
import { ISplashLoaderService } from "./components/splash-loader/i-splash-loader.service";
import {
  splashLoaderStateServiceInjectionToken
} from "./components/splash-loader/splash-loader-state-service-injection-token";
import { ISplashLoaderStateService } from "./components/splash-loader/i-splash-loader-state.service";
import { filter, map, Observable, Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [ "./app.component.scss" ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly title: string = "Zaeper | User Dashboard";
  public isVisible$: Observable<boolean>;
  private readonly _subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(splashLoaderStateServiceInjectionToken) private readonly _splashLoaderStateService: ISplashLoaderStateService,
    @Inject(splashLoaderServiceInjectionToken) private readonly _splashLoaderService: ISplashLoaderService,
    private readonly _router: Router
  ) {
    this.isVisible$ = this._splashLoaderStateService.isVisible$;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._splashLoaderService.setIsVisible(true);

    this._subscriptions.add(this._router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(map((event) => <NavigationEnd>event)).subscribe(() => {
      this._splashLoaderService.setIsVisible(false);
    }));
  }
}

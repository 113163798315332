import { Inject, Injectable } from '@angular/core';
import { splashLoaderStateServiceInjectionToken } from "./splash-loader-state-service-injection-token";
import { ISplashLoaderStateService } from "./i-splash-loader-state.service";
import { ISplashLoaderService } from "./i-splash-loader.service";

@Injectable()
export class SplashLoaderService implements ISplashLoaderService {
    constructor(@Inject(splashLoaderStateServiceInjectionToken) private readonly _splashLoaderStateService: ISplashLoaderStateService) {
    }

    setIsVisible(isVisible: boolean): void {
        this._splashLoaderStateService.setIsVisible(isVisible);
    }
}

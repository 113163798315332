import {AApplicationSettingsRestClient} from "../a-application-settings-rest.client";
import {IAppSettings} from "@zaeper/angular-application-store-lib/lib/models/app/i-app-settings";
import {IAppSettingsRestClient} from "@zaeper/angular-application-store-lib";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AppSettingsRestClient extends AApplicationSettingsRestClient<IAppSettings> implements IAppSettingsRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/app-settings"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, AppSettingsRestClient._RESOURCE_PATH);
  }
}

<zaeper-page-layout [navigation]="navigation" [pageLayout]="ePageLayout.CARDS"
                    [title]="('manage-receivers' | zDictionary)">
  <ng-template zTemplate="header">
    <zaeper-icon fontSet="material-icons-outlined">tv_outline</zaeper-icon>
  </ng-template>

  <div class="flex flex-col gap-6">
    <div class="flex justify-between">
      <!-- TabNavigation -->
      <div class="flex flex-row gap-2">
        <p-button (onClick)="openConnectReceiverModal()" [label]="'add-receiver' | zDictionary" [rounded]="true"
                  icon="pi pi-plus"></p-button>
        <p-button (onClick)="syncReceiverSettings()" [label]="'Receivers synchronisieren'" [outlined]="true"
                  [rounded]="true" icon="pi pi-sync"></p-button>
      </div>

      <div class="p-input-icon-right self-center">
        <input #globalFilter [placeholder]="'search' | zDictionary: 'main'" pInputText type="text"/>
        <i class="pi pi-search"></i>
      </div>
    </div>

    <p-table (onLazyLoad)="lazyLoadTable($event)" [lazy]="true" [loading]="(isLoading$ | async) ?? true"
             [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions" [rows]="10"
             [showLoader]="false" [tableStyle]="{ 'min-width': '50rem' }" [totalRecords]="(totalRecords$ | async) || 0"
             [value]="(records$ | async) || []">
      <ng-template let-columns="columns" pTemplate="loadingbody">
        <tr *ngFor="let i of (skeletonRows$ | async)">
          @for (_ of [].constructor(9); track $index) {
            <td>
              <p-skeleton height="2rem"></p-skeleton>
            </td>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          <th class="whitespace-nowrap">Online</th>
          <th class="whitespace-nowrap w-full">Name</th>
          <th class="whitespace-nowrap">OS Typ</th>
          <th class="whitespace-nowrap">OS Version</th>
          <th class="whitespace-nowrap">App Version</th>
          <th class="whitespace-nowrap">Zuletzt aktualisiert</th>
          <th class="whitespace-nowrap">Status</th>
          <th class="whitespace-nowrap">Aktionen</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="8" class="text-center">
            Keine Einträge gefunden
          </td>
        </tr>
      </ng-template>
      <ng-template let-i="rowIndex" let-receiverRecord pTemplate="body">
        <tr [ngClass]="{
          'bg-gray-100': ((totalRecords$ | async) ?? 0) > 1 && (receiverRecord.id === (activeReceiver$ | async)?.id)
        }">
          <td>{{ i + 1 }}</td>
          <td class="whitespace-nowrap text-center">
            @if (receiverRecord.isOnline) {
              <div class="inline-block h-[15px] w-[15px] rounded-full bg-zaeper-success-300"></div>
            } @else {
              <div class="inline-block h-[15px] w-[15px] rounded-full bg-gray-400"></div>
            }
          </td>
          <td class="whitespace-nowrap"><strong>{{ receiverRecord.name }}</strong></td>
          <td class="whitespace-nowrap"><i>{{ receiverRecord.osType }}</i></td>
          <td class="whitespace-nowrap"><i>{{ receiverRecord.osVersion }}</i></td>
          <td class="whitespace-nowrap"><i>{{ receiverRecord.appVersion }}</i></td>
          <td class="whitespace-nowrap"><i>08.04.2024 03:41Uhr</i></td>
          <td class="whitespace-nowrap"><i>Aktuell</i></td>
          <td>
            <div class="flex gap-2 justify-end items-center h-full w-full">
              <p-button (onClick)="delete(receiverRecord.id)" [outlined]="true" [rounded]="true" icon="pi pi-trash"
                        label="" severity="danger"/>
              <p-button (onClick)="openPushReceiverConfigModal()" [outlined]="true" [rounded]="true"
                        icon="pi pi-cloud-download"/>
              <p-button (onClick)="openScheduleReceiverConfigPushModal()" [outlined]="true" [rounded]="true"
                        icon="pi pi-calendar-plus"/>
              <p-button (onClick)="switchToReceiver(receiverRecord)" [outlined]="true"
                        [rounded]="true" icon="pi pi-desktop"/>
              <p-button (onClick)="openApplicationsOverviewModal(receiverRecord)" [rounded]="true"
                        styleClass="whitespace-nowrap">Konfigurieren
              </p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</zaeper-page-layout>

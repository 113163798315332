import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "ngx-lottie/lib/symbols";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ContentSlideComponent,
  ContentSliderService,
  contentSliderServiceInjectionToken,
  ContentSliderStateService,
  contentSliderStateServiceInjectionToken,
  ESeverity,
  IContentSliderService,
  IContentSliderStateService,
  IToastService,
  toastServiceInjectionToken
} from "@zaeper/angular-dashboard-view-lib";
import { IDictionaryService } from "@zaeper/localization-lib";
import { dictionaryServiceInjectionToken } from "@zaeper/angular-localization-lib";
import {
  connectionCodeServiceInjectionToken
} from "../../components/connection-code-display/connection-code-service-injection-token";
import { IConnectionCodeService } from "../../components/connection-code-display/i-connection-code.service";
import { IConnectReceiverModalData } from "./i-connect-receiver-modal-data";
import { IConnectionCodeStateService } from "../../components/connection-code-display/i-connection-code-state.service";
import {
  connectionCodeStateServiceInjectionToken
} from "../../components/connection-code-display/connection-code-state-service-injection-token";
import {
  receiverAuthenticationWsClientInjectionToken
} from "../../ws-clients/receiver-authentication/receiver-authentication-ws-client-injection-token";
import {
  IReceiverAuthenticationWsClient
} from "../../ws-clients/receiver-authentication/i-receiver-authentication-ws.client";
import {
  IReceiverRegisteredMessage
} from "../../ws-clients/receiver-authentication/messages/i-receiver-registered-message";
import { Router } from "@angular/router";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { receiverRestClientInjectionToken } from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import { IReceiverRestClient } from "../../rest-clients/receiver/i-receiver-rest.client";
import { IReceiver } from "../../models/i-receiver";
import { ICloud } from "../../models/i-cloud";
import { cloudRestClientInjectionToken } from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import { groupRestClientInjectionToken } from "../../rest-clients/group/group-rest-client-injection-token";
import { ICloudRestClient } from "../../rest-clients/cloud/i-cloud-rest.client";
import { IGroupRestClient } from "../../rest-clients/group/i-group-rest.client";
import { IGroup } from "../../models/i-group";

@Component({
  templateUrl: "connect-receiver-modal.component.html",
  providers: [
    {
      provide: contentSliderStateServiceInjectionToken,
      useClass: ContentSliderStateService
    }, {
      provide: contentSliderServiceInjectionToken,
      useClass: ContentSliderService
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectReceiverModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly lottieConnectToCloud: AnimationOptions = {
    path: "/assets/lottie/connect_to_cloud.json"
  };
  public readonly lottieCheck: AnimationOptions = {
    path: "/assets/lottie/check.json",
    loop: false,
    autoplay: false
  };
  public readonly connectReceiverForm: UntypedFormGroup;
  public readonly cloudId: string | undefined;
  public readonly groupId: string | undefined;

  private readonly _lottieAnimations: Map<string, AnimationItem>;
  private readonly _subscriptions: Subscription;

  @ViewChildren(ContentSlideComponent, { read: ElementRef }) private readonly _contentSlides!: QueryList<ElementRef>;

  constructor(
    @Inject(contentSliderServiceInjectionToken) private readonly _contentSliderService: IContentSliderService,
    @Inject(dictionaryServiceInjectionToken) private readonly _dictionaryService: IDictionaryService,
    @Inject(contentSliderStateServiceInjectionToken) private readonly _contentSliderStateService: IContentSliderStateService,
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
    @Inject(connectionCodeServiceInjectionToken) private readonly _connectionCodeService: IConnectionCodeService,
    @Inject(connectionCodeStateServiceInjectionToken) private readonly _connectionCodeStateService: IConnectionCodeStateService,
    @Inject(receiverAuthenticationWsClientInjectionToken) private readonly _receiverAuthenticationWsClient: IReceiverAuthenticationWsClient,
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
    @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
    @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
    readonly dynamicDialogConfig: DynamicDialogConfig<IConnectReceiverModalData>,
    private readonly _dialogRef: DynamicDialogRef, private readonly _router: Router
  ) {
    this._subscriptions = new Subscription();
    this._lottieAnimations = new Map<string, AnimationItem>();

    this.cloudId = dynamicDialogConfig.data?.cloudId;
    this.groupId = dynamicDialogConfig.data?.groupId;

    this.connectReceiverForm = new UntypedFormGroup({
      connectionCode: new UntypedFormControl("", {
        validators: [
          Validators.required, Validators.minLength(8), Validators.pattern(/[a-zA-Z0-9]+/)
        ]
      })
    });
  }

  ngAfterViewInit(): void {
    this._handleAnimationStarts();
    this._handleReceiverRegisteredEvent();
  }

  ngOnInit() {
    this._subscriptions.add(this._contentSliderStateService.activeSlideIndex$.subscribe((activeSlideIndex: number) => {
      if (activeSlideIndex === 1 && this.cloudId !== undefined && this.groupId !== undefined) {
        this._connectionCodeService.requestConnectionCode(this.cloudId, this.groupId);
      }
    }));
  }

  public nextSlide(): void {
    this._contentSliderService.nextSlide();
  }

  public animationCreated(animationItem: AnimationItem, animationName: string): void {
    this._lottieAnimations.set(animationName, animationItem);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _handleReceiverRegisteredEvent(): void {
    this._subscriptions.add(this._receiverAuthenticationWsClient.getReceiverRegistered$().subscribe((receiverRegisteredMessage: IReceiverRegisteredMessage) => {
      const lastGeneratedConnectionCode: string | null = this._connectionCodeStateService.getConnectionCode();

      const formattedLastGeneratedConnectionCode: string | undefined = lastGeneratedConnectionCode?.replaceAll("-", "").toLowerCase();

      if (receiverRegisteredMessage.connectionCode === formattedLastGeneratedConnectionCode && this.cloudId && this.groupId) {
        const cloudRequest: Promise<ICloud> = this._cloudRestClient.getCloud({
          cloudId: this.cloudId
        });

        const groupRequest: Promise<IGroup> = this._groupRestClient.getGroup({
          cloudId: this.cloudId,
          groupId: this.groupId
        });

        const receiverRequest: Promise<IReceiver> = this._receiverRestClient.getReceiver({
          cloudId: this.cloudId,
          groupId: this.groupId,
          receiverId: receiverRegisteredMessage.receiverId
        });

        Promise.all([
          cloudRequest, groupRequest, receiverRequest
        ]).then(([ cloud, group, receiver ]: [ ICloud, IGroup, IReceiver ]) => {
          this._toastService.setMessage({
            title: "Erfolgreich",
            detail: `Receiver wurde erfolgreich in Cloud registriert`,
            severity: ESeverity.success
          });

          this._sessionStateService.setActiveCloud(cloud);
          this._sessionStateService.setActiveGroup(group);
          this._sessionStateService.setActiveReceiver(receiver);
          this._dialogRef.close();
          this._router.navigate([ "/de/" ]);
        });
      }
    }));
  }

  private _handleAnimationStarts(): void {
    this._subscriptions.add(this._contentSliderStateService.activeSlideIndex$.subscribe((activeSlideIndex: number) => {
      switch (activeSlideIndex) {
        case this._contentSlides.length - 1:
          this._lottieAnimations.get("lottieCheck")?.play();
          break;
      }
    }));
  }
}

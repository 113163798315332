<zaeper-page-layout [navigation]="navigation" [pageLayout]="ePageLayout.CARDS"
                    [title]="'manage-features' | zDictionary">
  <ng-template zTemplate="header">
    <zaeper-icon fontSet="material-icons-outlined">star_outline</zaeper-icon>
  </ng-template>

  <div class="flex flex-col gap-6">
    <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="features">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th class="w-1/2">Beschreibung</th>
          <th>Aktionen</th>
        </tr>
      </ng-template>
      <ng-template let-product pTemplate="body">
        <tr>
          <td class="text-nowrap"><strong>{{ product.name }}</strong></td>
          <td class="w-full"><i>{{ product.description }}</i></td>
          <td>
            <p-toggleButton [ngModel]="product.enabled" offIcon="pi pi-times" offLabel="Aktivieren"
                            onIcon="pi pi-check" onLabel="Deaktivieren" styleClass="w-[10em] rounded-full"></p-toggleButton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</zaeper-page-layout>

import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {SplashLoaderModule} from "../splash-loader/splash-loader.module";
import {BehaviorSubject, Observable} from 'rxjs';
import {PreviewContainerLoaderComponent} from "./preview-container-loader/preview-container-loader.component";
import {AsyncPipe, CommonModule} from "@angular/common";
import {validate, v4} from "uuid";
import {IReceiverMessage} from "../../receiver-communication/messages/i-receiver-message";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {IReceiver} from "../../models/i-receiver";
import {AppRestClient} from "../../rest-clients/application/app/app-rest.client";
import {HttpClient} from '@angular/common/http';
import {IApp, IAppRestClient, IGetInstalledApplicationPageForm} from '@zaeper/angular-application-store-lib';
import {IPage} from "@zaeper/communication-lib";
import {IPageMessage} from "../../receiver-communication/messages/i-page-message";
import {HostUtil} from "../../utils/host-util";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-container',
  standalone: true,
  templateUrl: './preview-container.component.html',
  providers: [SplashLoaderModule],
  imports: [PreviewContainerLoaderComponent, AsyncPipe]
})
export class PreviewContainerComponent implements AfterViewInit, OnDestroy {
  public readonly launcherUrl: SafeResourceUrl;

  private static _PREVIEWER_IDENTIFIER: string = v4();
  private static _MESSAGE_PAYLOAD_DELIMITER: string = ".";

  public readonly isLoaderVisible$: Observable<boolean>;
  private readonly _isLoaderVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private readonly _appRestClient: IAppRestClient;

  @ViewChild('previewerView', {read: ElementRef}) private readonly _previewerView!: ElementRef;

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    readonly httpClient: HttpClient,
    readonly domSanitizer: DomSanitizer
  ) {
    this.isLoaderVisible$ = this._isLoaderVisible$.asObservable();
    this._appRestClient = new AppRestClient(httpClient);
    this.launcherUrl = domSanitizer.bypassSecurityTrustResourceUrl(HostUtil.getApplicationsPreviewerEndpointUrl())
  }

  ngAfterViewInit(): void {
    const iframe: HTMLIFrameElement = this._previewerView.nativeElement;

    window.addEventListener('message', (messageEvent: MessageEvent) => this._messageHandler(messageEvent));
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', (messageEvent: MessageEvent) => this._messageHandler(messageEvent))
  }

  private async _messageHandler(messageEvent: MessageEvent): Promise<void> {
    const iframe: HTMLIFrameElement = this._previewerView.nativeElement;

    if (messageEvent.source === iframe.contentWindow) {
      const payloadChunks: string[] = (<string>messageEvent.data).split(PreviewContainerComponent._MESSAGE_PAYLOAD_DELIMITER);
      if (payloadChunks.length > 2 && validate(payloadChunks[0]) && validate(payloadChunks[1])) {
        const [senderId, messageId, messageBody]: string[] = payloadChunks;

        const receiverMessage: IReceiverMessage = JSON.parse(messageBody);

        if (receiverMessage.identifier === "application-ready") {
          this._isLoaderVisible$.next(false)
        }

        if (receiverMessage.identifier === "get-receiver-information") {
          const activeReceiver: IReceiver | null = this._sessionStateService.getActiveReceiver();

          if (activeReceiver !== null) {
            const serializedResponseBody: string = JSON.stringify(activeReceiver);
            const responsePayload: string = [PreviewContainerComponent._PREVIEWER_IDENTIFIER, messageId, serializedResponseBody].join(PreviewContainerComponent._MESSAGE_PAYLOAD_DELIMITER);
            iframe.contentWindow?.postMessage(responsePayload, "*");
          }
        }

        if (receiverMessage.identifier === "get-installed-apps") {
          const activeReceiver: IReceiver | null = this._sessionStateService.getActiveReceiver();

          if (activeReceiver !== null) {
            const getInstalledAppsForm: IGetInstalledApplicationPageForm = {
              receiverId: activeReceiver.id,
              pageNumber: 0,
              pageSize: 0
            }

            const installedAppsPage: IPage<IApp> = await this._appRestClient.getInstalledApplicationPage(getInstalledAppsForm);
            const installedAppsPageMessage: IPageMessage<IApp> = {
              records: installedAppsPage.records,
              totalRecords: installedAppsPage.totalRecords
            }

            const serializedResponseBody: string = JSON.stringify(installedAppsPageMessage);
            const responsePayload: string = [PreviewContainerComponent._PREVIEWER_IDENTIFIER, messageId, serializedResponseBody].join(PreviewContainerComponent._MESSAGE_PAYLOAD_DELIMITER);
            iframe.contentWindow?.postMessage(responsePayload, "*");
          }
        }
      }
    }
  }
}

import { ISessionStateService } from "./i-session-state.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ICurrentUser } from "../../models/i-current-user";
import { ICloud } from "../../models/i-cloud";
import { IGroup } from "../../models/i-group";
import { IReceiver } from "src/app/models/i-receiver";
import { group } from "@angular/animations";

@Injectable()
export class SessionStateService implements ISessionStateService {
  private readonly _currentUser$: BehaviorSubject<ICurrentUser | null> = new BehaviorSubject<ICurrentUser | null>(null);
  public readonly currentUser$: Observable<ICurrentUser | null> = this._currentUser$.asObservable();
  private readonly _activeCloud$: BehaviorSubject<ICloud | null> = new BehaviorSubject<ICloud | null>(null);
  public readonly activeCloud$: Observable<ICloud | null> = this._activeCloud$.asObservable();
  private readonly _activeGroup$: BehaviorSubject<IGroup | null> = new BehaviorSubject<IGroup | null>(null);
  public readonly activeGroup$: Observable<IGroup | null> = this._activeGroup$.asObservable();
  private readonly _quickAccessClouds$: BehaviorSubject<ICloud[]> = new BehaviorSubject<ICloud[]>([]);
  public readonly quickAccessClouds$: Observable<ICloud[]> = this._quickAccessClouds$.asObservable();
  private readonly _quickAccessGroups$: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>([]);
  public readonly quickAccessGroups$: Observable<IGroup[]> = this._quickAccessGroups$.asObservable();
  private readonly _quickAccessReceivers$: BehaviorSubject<IReceiver[]> = new BehaviorSubject<IReceiver[]>([]);
  public readonly quickAccessReceivers$: Observable<IReceiver[]> = this._quickAccessReceivers$.asObservable();
  private readonly _activeReceiver$: BehaviorSubject<IReceiver | null> = new BehaviorSubject<IReceiver | null>(null)
  public readonly activeReceiver$: Observable<IReceiver | null> = this._activeReceiver$.asObservable();

  public setCurrentUser(currentUser: ICurrentUser): void {
    this._currentUser$.next(currentUser);
  }

  public setActiveCloud(cloud: ICloud): void {
    this._activeCloud$.next(cloud);
  }

  public setActiveGroup(group: IGroup): void {
    this._activeGroup$.next(group);
  }

  public getCurrentUser(): ICurrentUser | null {
    return this._currentUser$.getValue();
  }

  public getActiveCloud(): ICloud | null {
    return this._activeCloud$.getValue();
  }

  public getActiveGroup(): IGroup | null {
    return this._activeGroup$.getValue();
  }

  public getQuickAccessClouds(): ICloud[] {
    return this._quickAccessClouds$.getValue();
  }

  public getQuickAccessGroups(): IGroup[] {
    return this._quickAccessGroups$.getValue();
  }

  public setQuickAccessClouds(clouds: ICloud[]): void {
    this._quickAccessClouds$.next(clouds);
  }

  public setQuickAccessGroups(groups: IGroup[]): void {
    this._quickAccessGroups$.next(groups);
  }

  public setActiveReceiver(receiver: IReceiver): void {
    this._activeReceiver$.next(receiver);
  }

  public getActiveReceiver(): IReceiver | null {
    return this._activeReceiver$.getValue();
  }

  public getQuickAccessReceivers(): IReceiver[] {
    return this._quickAccessReceivers$.getValue();
  }

  public setQuickAccessReceivers(receivers: IReceiver[]): void {
    this._quickAccessReceivers$.next(receivers);
  }
}

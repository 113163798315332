import { Inject, Injectable } from "@angular/core";
import { IQuickAccessResourcesResolver } from "./i-quick-access-resources.resolver";
import {
  sessionStateServiceInjectionToken
} from "../../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../../global-states/session/i-session-state.service";
import { cloudRestClientInjectionToken } from "../../../rest-clients/cloud/cloud-rest-client-injection-token";
import { ICloudRestClient } from "../../../rest-clients/cloud/i-cloud-rest.client";
import { IGetCloudPageForm } from "../../../rest-clients/cloud/forms/i-get-cloud-page-form";
import { ICloud } from "../../../models/i-cloud";
import { ICreateCloudForm } from "../../../rest-clients/cloud/forms/i-create-cloud-form";
import { IPage } from "@zaeper/communication-lib";
import { IGetGroupPageForm } from "../../../rest-clients/group/forms/i-get-group-page-form";
import { IGroup } from "../../../models/i-group";
import { ICreateGroupForm } from "../../../rest-clients/group/forms/i-create-group-form";
import { groupRestClientInjectionToken } from "../../../rest-clients/group/group-rest-client-injection-token";
import { IGroupRestClient } from "../../../rest-clients/group/i-group-rest.client";
import { IGetReceiverPageForm } from "../../../rest-clients/receiver/forms/i-get-receiver-page-form";
import { receiverRestClientInjectionToken } from "../../../rest-clients/receiver/receiver-rest-client-injection-token";
import { IReceiverRestClient } from "../../../rest-clients/receiver/i-receiver-rest.client";
import { IReceiver } from "../../../models/i-receiver";

@Injectable()
export class QuickAccessResourcesResolver implements IQuickAccessResourcesResolver {
  constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
              @Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
              @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient) {
  }

  public async getQuickAccessResources(): Promise<void> {
    await this._getQuickAccessClouds();
    await this._getQuickAccessGroups();
    return await this._getQuickAccessReceivers();
  };

  private async _getQuickAccessClouds(): Promise<void> {
    const getCloudPageForm: IGetCloudPageForm = {
      pageNumber: 0,
      pageSize: 10
    }

    let cloudPage: IPage<ICloud> = await this._cloudRestClient.getCloudPage(getCloudPageForm);
    this._sessionStateService.setQuickAccessClouds(cloudPage.records)
    if (cloudPage.records.length > 0) {
      this._sessionStateService.setActiveCloud(cloudPage.records[0])
      return Promise.resolve();
    } else {
      const createCloudForm: ICreateCloudForm = {
        name: "Cloud 1",
        description: "Iniziale Cloud"
      }
      await this._cloudRestClient.createCloud(createCloudForm);
      return this._getQuickAccessClouds();
    }
  }

  private async _getQuickAccessGroups(): Promise<void> {
    const activeCloud: ICloud | null = this._sessionStateService.getActiveCloud();

    if (activeCloud == null || activeCloud.id === undefined) {
      return Promise.reject();
    }

    const cloudId: string = activeCloud.id;

    const getGroupPageForm: IGetGroupPageForm = {
      cloudId,
      pageNumber: 0,
      pageSize: 10
    }

    const groupPage: IPage<IGroup> = await this._groupRestClient.getGroupPage(getGroupPageForm);
    if (groupPage.records.length > 0) {
      this._sessionStateService.setActiveGroup(groupPage.records[0]);
      this._sessionStateService.setQuickAccessGroups(groupPage.records);
      return Promise.resolve();
    } else {
      const createGroupForm: ICreateGroupForm = {
        cloudId,
        name: "Gruppe 1",
        description: "Iniziale Gruppe"
      };

      return this._groupRestClient.createGroup(createGroupForm).then((): Promise<void> => {
        return this._getQuickAccessGroups();
      });
    }
  }

  private async _getQuickAccessReceivers(): Promise<void> {
    const activeCloud: ICloud | null = this._sessionStateService.getActiveCloud();
    const activeGroup: IGroup | null = this._sessionStateService.getActiveGroup();

    if (activeCloud == null || activeCloud.id === undefined || activeGroup == null || activeGroup.id === undefined) {
      return Promise.reject();
    }

    const cloudId: string = activeCloud.id;
    const groupId: string = activeGroup.id;

    const getReceiverPageForm: IGetReceiverPageForm = {
      cloudId,
      groupId,
      pageNumber: 0,
      pageSize: 10
    }

    const receiverPage: IPage<IReceiver> = await this._receiverRestClient.getReceiverPage(getReceiverPageForm);
    if (receiverPage.records.length > 0) {
      this._sessionStateService.setActiveReceiver(receiverPage.records[0]);
      this._sessionStateService.setQuickAccessReceivers(receiverPage.records);
      return Promise.resolve();
    }
  }
}

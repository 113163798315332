import {Inject, Injectable} from "@angular/core";
import {IMessage} from "@stomp/rx-stomp";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {HostUtil} from "../../utils/host-util";
import {map, Observable} from "rxjs";
import {IConnectionStateMessage} from "./messages/i-connection-state-message";
import {IReceiverWsClient} from "./i-receiver-ws.client";
import {AWebsocketClient} from "@zaeper/communication-lib";

@Injectable()
export class ReceiverWsClient extends AWebsocketClient implements IReceiverWsClient {
  private readonly _connectionState$: Observable<IConnectionStateMessage>;

  constructor(@Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService) {
    super(HostUtil.getReceiverWebsocketEndpointUrl(), "stomp");

    this._connectionState$ = this._connection
      .watch({destination: `/${this._sessionStateService.getCurrentUser()?.id}/connection-state`}).pipe(map((message: IMessage) => JSON.parse(message.body)));
  }

  getConnectionState$(): Observable<IConnectionStateMessage> {
    return this._connectionState$;
  }
}

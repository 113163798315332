import {ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {AnimationOptions, LottieComponent} from "ngx-lottie";
import {NgClass, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-preview-container-loader',
  standalone: true,
  imports: [
    NgClass,
    LottieComponent,
    NgOptimizedImage,
    NgOptimizedImage
  ],
  templateUrl: './preview-container-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewContainerLoaderComponent {
  @Input("isVisible") public isVisible: boolean = false;

  public loadingDotsLgOptions: AnimationOptions = {
    path: `/assets/lottie/loading_dots_lg_white.json`, loop: true, autoplay: true,
  };
}

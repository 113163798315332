import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {ILanguageStateService} from "@zaeper/localization-lib";
import {ILanguageResolverService} from "./iLanguageResolverService";
import {languageStateServiceInjectionToken} from "./languageStateServiceInjectionToken";

@Injectable()
export class LanguageResolverService implements Resolve<string>, ILanguageResolverService {
  constructor(@Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    this._languageStateService.nextLanguage(route.params['language']);

    return this._languageStateService.language$.pipe(take(1));
  }
}

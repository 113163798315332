import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy} from "@angular/core";
import {EDashboardLayout} from "@zaeper/angular-dashboard-view-lib";
import {IMenuItem, MenuItemCommand, MenuItemRouterLink} from "@zaeper/core-lib";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {Observable, Subscription} from "rxjs";
import {ICurrentUser} from "../../models/i-current-user";
import {
  userAuthenticationRestClientInjectionToken
} from "../../rest-clients/user/authentication/user-authentication-rest-client-injection-token";
import {IUserAuthenticationRestClient} from "../../rest-clients/user/authentication/i-user-authentication-rest.client";
import {ICloud} from "../../models/i-cloud";
import {IGroup} from "../../models/i-group";
import {languageStateServiceInjectionToken} from "../../services/language/languageStateServiceInjectionToken";
import {ILanguageStateService} from "@zaeper/localization-lib";
import {IReceiver} from "../../models/i-receiver";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardLayoutComponent implements OnDestroy {
  @Input("routerPath") public routerPath: string = "";

  public readonly dashboardLayout: EDashboardLayout = EDashboardLayout.BLOCKS;
  public readonly currentUser$: Observable<ICurrentUser | null>;
  public readonly activeCloud$: Observable<ICloud | null>;
  public readonly activeGroup$: Observable<IGroup | null>;
  public readonly activeReceiver$: Observable<IReceiver | null>;
  public readonly language$: Observable<string>;

  public readonly navItems: IMenuItem[] = [new MenuItemRouterLink({
    icon: "tv_outline", title: "receiver", internalLink: `/de/`
  }), new MenuItemRouterLink({
    icon: "storefront", title: "store", internalLink: `/de/store`
  }), new MenuItemRouterLink({
    icon: "star_outlined", title: "features", internalLink: `/de/features`
  }), new MenuItemCommand({
    icon: "info_outline", title: "infos", command: () => {
      //this._openModal(InfoModalComponent, 'Infos', '500px'),
    }
  })];

  private readonly _subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(userAuthenticationRestClientInjectionToken) private readonly _userRestClient: IUserAuthenticationRestClient,
    @Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService
  ) {
    this.currentUser$ = this._sessionStateService.currentUser$;
    this.activeCloud$ = this._sessionStateService.activeCloud$;
    this.activeGroup$ = this._sessionStateService.activeGroup$;
    this.activeReceiver$ = this._sessionStateService.activeReceiver$;
    this.language$ = this._languageStateService.language$;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}

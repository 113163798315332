<div class="flex flex-col gap-6">
  <div class="flex justify-between">
    <!-- TabNavigation -->
    <div class="flex flex-row gap-2">
      <p-button (onClick)="openAvailableApps()" [label]="'App installieren'" [rounded]="true"
                icon="pi pi-plus"></p-button>
      <p-button (onClick)="switchToStore()" [label]="'Zum Store wechseln'" [outlined]="true"
                [rounded]="true"></p-button>
    </div>
  </div>

  <p-table (onLazyLoad)="lazyLoadTable($event)" [lazy]="true" [loading]="(isLoading$ | async) ?? true"
           [paginator]="false"
           [rowsPerPageOptions]="rowsPerPageOptions"
           [rows]="10" [scrollable]="true"
           [showLoader]="false" [tableStyle]="{ 'min-width': '50rem' }" [totalRecords]="(totalRecords$ | async) || 0"
           [value]="(records$ | async) || []">
    <ng-template let-columns="columns" pTemplate="loadingbody">
      <tr *ngFor="let i of (skeletonRows$ | async)">
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="8" class="text-center">
          Keine Einträge gefunden
        </td>
      </tr>
    </ng-template>
    <ng-template let-appRecord let-i="rowIndex" pTemplate="body">
      <tr>
        <td>
          <img [src]="appRecord.lightIconUrl" class="rounded" height="48" width="48"/>
        </td>
        <td><strong>{{ appRecord.name }}</strong></td>
        <td>1.0.0</td>
        <td [ngClass]="{'text-gray-400': !appRecord.description}">
          <i>{{ appRecord.description ?? "Keine Beschreibung vorhanden" }}</i></td>
        <td class="flex gap-2 justify-end">
          <p-button (onClick)="uninstall(appRecord)" *ngIf="((totalRecords$ | async) || 0) > 1" [outlined]="true"
                    [rounded]="true" icon="pi pi-trash"
                    severity="danger"/>
          <p-button [rounded]="true" [routerLink]="['/de/apps/' + appRecord.id + '/groups']">Konfigurieren
          </p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

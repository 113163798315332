import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  confirmationModalServiceInjectionToken,
  EPageLayout,
  ESeverity,
  IConfirmationModalService,
  IToastService,
  toastServiceInjectionToken,
  IToggleButton
} from "@zaeper/angular-dashboard-view-lib";
import {
  connectReceiverModalServiceInjectionToken
} from "../../modals/connect-receiver-modal/connect-receiver-modal-service-injection-token";
import {IConnectReceiverModalService} from "../../modals/connect-receiver-modal/i-connect-receiver-modal.service";
import {livekitClientInjectionToken} from "../../services/livekit/livekit-client-injection-token";
import {ILivekitClient} from "../../services/livekit/i-livekit.client";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {BehaviorSubject, filter, map, mergeWith, Observable, Subscription} from "rxjs";
import {IReceiver} from "../../models/i-receiver";
import {IConnectReceiverModalData} from "../../modals/connect-receiver-modal/i-connect-receiver-modal-data";
import {
  scheduleReceiverConfigPushModalServiceInjectionToken
} from "../../modals/schedule-receiver-config-push-modal/schedule-receiver-config-push-modal-service-injection-token";
import {
  IScheduleReceiverConfigPushModalService
} from "../../modals/schedule-receiver-config-push-modal/i-schedule-receiver-config-push-modal.service";
import {
  applicationsOverviewModalServiceInjectionToken
} from "../../modals/applications-overview-modal/applications-overview-modal-service-injection-token";
import {
  IApplicationsOverviewModalService
} from "../../modals/applications-overview-modal/i-applications-overview-modal.service";
import {receiverWsClientInjectionToken} from "../../ws-clients/receiver/receiver-ws-client-injection-token";
import {IReceiverWsClient} from "../../ws-clients/receiver/i-receiver-ws.client";
import {IConnectionStateMessage} from "../../ws-clients/receiver/messages/i-connection-state-message";
import {LiveViewContainerComponent} from "../../components/live-view-container/live-view-container.component";
import {PreviewContainerComponent} from "../../components/preview-container/preview-container.component";

enum EReceiverViewMode {
  PREVIEW = 0,
  LIVE_VIEW = 1
}

@Component({
  selector: "cms-receiver-previewer-page",
  templateUrl: "receiver-previewer-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./receiver-previewer-page.component.scss"]
})
export class ReceiverPreviewerPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly receiverViewMode$: Observable<EReceiverViewMode>;
  public readonly eReceiverViewMode: typeof EReceiverViewMode = EReceiverViewMode;
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;
  public readonly isReceiverOnline$: Observable<boolean>;
  public readonly activeReceiver$: Observable<IReceiver | null>;
  public readonly toggleButtons: IToggleButton[];
  private readonly _subscriptions: Subscription = new Subscription();
  private readonly _receiverViewMode$: BehaviorSubject<EReceiverViewMode>;


  @ViewChild("previewerFrame", {read: ElementRef}) private readonly _previewerFrame!: ElementRef;
  @ViewChild("previewerFrameWrapper", {read: ElementRef}) private readonly _previewerFrameWrapper!: ElementRef;
  @ViewChild("previewContainer", {read: ViewContainerRef}) private readonly _previewContainer!: ViewContainerRef
  @ViewChild("liveViewContainer", {read: ViewContainerRef}) private readonly _liveViewContainer!: ViewContainerRef

  constructor(
    @Inject(connectReceiverModalServiceInjectionToken) private readonly _connectReceiverModalService: IConnectReceiverModalService,
    @Inject(livekitClientInjectionToken) private readonly _livekitClient: ILivekitClient,
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(confirmationModalServiceInjectionToken) private readonly _confirmationModalService: IConfirmationModalService,
    @Inject(toastServiceInjectionToken) private readonly _toastService: IToastService,
    @Inject(scheduleReceiverConfigPushModalServiceInjectionToken) private readonly _scheduleReceiverConfigPushModalService: IScheduleReceiverConfigPushModalService,
    @Inject(applicationsOverviewModalServiceInjectionToken) private readonly _applicationsOverviewModalService: IApplicationsOverviewModalService,
    @Inject(receiverWsClientInjectionToken) private readonly _receiverWsClient: IReceiverWsClient,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    this.activeReceiver$ = this._sessionStateService.activeReceiver$;
    this.isReceiverOnline$ = this.activeReceiver$.pipe(map((receiver: IReceiver | null) => !!receiver?.isOnline)).pipe(mergeWith(this._receiverWsClient.getConnectionState$().pipe(map((connectionState: IConnectionStateMessage) => connectionState.connected))));
    this._receiverViewMode$ = new BehaviorSubject<EReceiverViewMode>(EReceiverViewMode.PREVIEW)
    this.receiverViewMode$ = this._receiverViewMode$.asObservable();
    this.toggleButtons = [
      {
        label: "Vorschau",
        iconName: "web_asset"
      },
      {
        label: "Live-Ansicht",
        iconName: "radio_button_checked"
      }
    ]
  }

  ngAfterViewInit(): void {
    this._handleReceiverViewModeChange();
    this._handleActiveReceiverChange();
  }

  ngOnInit() {
  }

  async ngOnDestroy(): Promise<void> {
    this._subscriptions.unsubscribe();
    await this._livekitClient.disconnect();
  }

  public openConnectReceiverModal() {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;
    const groupId: string | undefined = this._sessionStateService.getActiveGroup()?.id;

    if (cloudId && groupId) {
      const connectReceiverModalData: IConnectReceiverModalData = {
        cloudId, groupId
      };

      this._connectReceiverModalService.open(connectReceiverModalData);
    }
  }

  public openApplicationsOverviewModal(): void {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;
    const receiver: IReceiver | null = this._sessionStateService.getActiveReceiver();

    if (cloudId && receiver) {
      this._applicationsOverviewModalService.openApplicationsOverviewModal({
        cloudId, receiver
      });
    }
  }

  public openScheduleReceiverConfigPushModal(): void {
    this._scheduleReceiverConfigPushModalService.open();
  }

  public openPushReceiverConfigModal(): void {
    this._confirmationModalService.open({
      title: "Konfigurationen laden",
      description: "Soll der Receiver die zurzeit konfigurierten Einstellungen laden?",
      confirmationText: "Laden",
      onConfirm: () => {
        this._toastService.setMessage({
          title: "Erfolgreich",
          detail: "Befehl zum Laden der Einstellungen wurde erfolgreich versendet.",
          severity: ESeverity.success
        });
        return Promise.resolve();
      }
    });
  }

  public switchViewMode(activeIndex: number) {
    this._receiverViewMode$.next(activeIndex);
  }

  private _handleActiveReceiverChange() {
    this._subscriptions.add(this._sessionStateService.activeReceiver$.subscribe(() => {
      const receiver: IReceiver | null = this._sessionStateService.getActiveReceiver();
      const resolutionX: number | undefined = receiver?.resolutionX;
      const resolutionY: number | undefined = receiver?.resolutionY;

      if (resolutionX && resolutionY) {
        this._changeDetectorRef.detectChanges();
        this._previewerFrameWrapper.nativeElement.style.aspectRatio = `${resolutionX}/${resolutionY}`;
        this._previewerFrame.nativeElement.style.aspectRatio = `${resolutionX}/${resolutionY}`;
      }
    }));
  }

  private _handleReceiverViewModeChange() {
    this._subscriptions.add(
      this._receiverViewMode$.pipe(filter(() => this._sessionStateService.getActiveReceiver() !== null)).subscribe((receiverViewMode: EReceiverViewMode) => {
        this._changeDetectorRef.detectChanges();
        if (receiverViewMode === EReceiverViewMode.PREVIEW && this._previewContainer.length === 0) {
          this._previewContainer.createComponent(PreviewContainerComponent);
        }
        if (receiverViewMode === EReceiverViewMode.LIVE_VIEW && this._liveViewContainer.length === 0) {
          this._liveViewContainer.createComponent(LiveViewContainerComponent);
        }
        this._changeDetectorRef.detectChanges();
      })
    )
  }
}

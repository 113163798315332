import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleReceiverConfigPushModalComponent } from './schedule-receiver-config-push-modal.component';
import { InputGroupModule } from '@zaeper/angular-dashboard-view-lib';
import { ButtonModule } from "primeng/button";
import { MessagesModule } from 'primeng/messages';


@NgModule({
  declarations: [
    ScheduleReceiverConfigPushModalComponent
  ],
  imports: [
    CommonModule,
    InputGroupModule,
    ButtonModule,
    MessagesModule
  ]
})
export class ScheduleReceiverConfigPushModalModule {
}

import {
  IApplication,
  IApplicationRestClient,
  IGetApplicationPageForm,
  IGetInstalledApplicationPageForm,
  IGetPurchasedApplicationPageForm,
  IInstallApplicationForm,
  IPurchaseApplicationForm,
  IUninstallApplicationForm
} from "@zaeper/angular-application-store-lib";
import {ARestClient, ICreateRecordResponse, IOkResponse, IPage} from "@zaeper/communication-lib";
import {HostUtil} from "../../utils/host-util";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, Observable} from "rxjs";

export abstract class AApplicationRestClient<T extends IApplication> extends ARestClient implements IApplicationRestClient<T> {
  protected constructor(protected readonly _httpClient: HttpClient, protected readonly _resourcePath: string) {
    super();
  }

  getApplicationPage(getApplicationPageForm: IGetApplicationPageForm): Promise<IPage<T>> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/page`);

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
      params: {
        pageNumber: getApplicationPageForm.pageNumber,
        pageSize: getApplicationPageForm.pageSize
      }
    }) as Observable<IPage<T>>);
  }

  getInstalledApplicationPage(getInstalledApplicationPageForm: IGetInstalledApplicationPageForm): Promise<IPage<T>> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/installed/page`);

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
      params: {
        receiverId: getInstalledApplicationPageForm.receiverId,
        pageNumber: getInstalledApplicationPageForm.pageNumber,
        pageSize: getInstalledApplicationPageForm.pageSize
      }
    }) as Observable<IPage<T>>);
  }

  getPurchasedApplicationPage(getPurchasedApplicationPageForm: IGetPurchasedApplicationPageForm): Promise<IPage<T>> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/purchased/page`);

    return firstValueFrom(this._httpClient.get(endpointUrl, {
      withCredentials: true,
      params: {
        cloudId: getPurchasedApplicationPageForm.cloudId,
        pageNumber: getPurchasedApplicationPageForm.pageNumber,
        pageSize: getPurchasedApplicationPageForm.pageSize
      }
    }) as Observable<IPage<T>>);
  }

  installApplication(installApplicationForm: IInstallApplicationForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/install`);

    return firstValueFrom(this._httpClient.post(endpointUrl, installApplicationForm, {withCredentials: true}) as Observable<ICreateRecordResponse>);
  }

  purchaseApplication(purchaseApplicationForm: IPurchaseApplicationForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/purchase`);

    return firstValueFrom(this._httpClient.post(endpointUrl, purchaseApplicationForm, {withCredentials: true}) as Observable<ICreateRecordResponse>);
  }

  uninstallApplication(uninstallApplicationForm: IUninstallApplicationForm): Promise<IOkResponse> {
    const endpointUrl: string = super.getEndpointUrl(HostUtil.getApplicationApiEndpointUrl(), `${this._resourcePath}/uninstall`);

    return firstValueFrom(this._httpClient.post(endpointUrl, uninstallApplicationForm, {withCredentials: true}) as Observable<ICreateRecordResponse>);
  }
}

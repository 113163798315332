import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";
import { IReceiverRestClient } from "./i-receiver-rest.client";
import { IGenerateConnectionCodeResponse } from "./responses/i-generate-connection-code-response";
import { ARestClient, IOkResponse, IPage, ISort } from "@zaeper/communication-lib";
import { HostUtil } from "../../utils/host-util";
import { IGenerateConnectionCodeForm } from "./forms/i-generate-connection-code-form";
import { IGetReceiverForm } from "./forms/i-get-receiver-form";
import { IReceiver } from "../../models/i-receiver";
import { IGetReceiverPageForm } from "./forms/i-get-receiver-page-form";
import { IGetLiveViewAccessTokenForm } from "./forms/i-get-live-view-access-token-form";
import { IGetLiveViewAccessTokenResponse } from "./responses/i-get-live-view-access-token-response";
import { IDeleteReceiverForm } from "./forms/i-delete-receiver-form";

@Injectable()
export class ReceiverRestClient extends ARestClient implements IReceiverRestClient {
  constructor( private _httpClient: HttpClient ) {
    super();
  }

  deleteReceiver( deleteReceiverForm: IDeleteReceiverForm ): Promise<IOkResponse> {
    const {
      cloudId, groupId, receiverId
    } = deleteReceiverForm;
    const endpointUrl: string = super.getEndpointUrl( HostUtil.getReceiverApiEndpointUrl(), `v1/cloud/${ cloudId }/group/${ groupId }/receiver/${ receiverId }` );

    return firstValueFrom( this._httpClient.delete( endpointUrl, {
      withCredentials: true
    } ) as Observable<IOkResponse> );
  }

  generateConnectionCode( generateConnectionCodeForm: IGenerateConnectionCodeForm ): Promise<IGenerateConnectionCodeResponse> {
    const endpointUrl: string = super.getEndpointUrl( HostUtil.getReceiverAuthenticationApiEndpointUrl(), "v1/generate-connection-code" );

    return firstValueFrom( this._httpClient.post( endpointUrl, generateConnectionCodeForm ) as Observable<IGenerateConnectionCodeResponse> );
  }

  getLiveViewAccessToken( getLiveViewAccessTokenForm: IGetLiveViewAccessTokenForm ): Promise<IGetLiveViewAccessTokenResponse> {
    const {
      cloudId, groupId, receiverId
    } = getLiveViewAccessTokenForm;
    const endpointUrl: string = super.getEndpointUrl( HostUtil.getReceiverApiEndpointUrl(), `v1/cloud/${ cloudId }/group/${ groupId }/receiver/${ receiverId }/live-view-access-token` );
    return firstValueFrom( this._httpClient.get( endpointUrl, {
      withCredentials: true
    } ) as Observable<IGetLiveViewAccessTokenResponse> );
  }

  getReceiver( getReceiverForm: IGetReceiverForm ): Promise<IReceiver> {
    const {
      cloudId, groupId, receiverId
    } = getReceiverForm;
    const endpointUrl: string = super.getEndpointUrl( HostUtil.getReceiverApiEndpointUrl(), `v1/cloud/${ cloudId }/group/${ groupId }/receiver/${ receiverId }` );

    return firstValueFrom( this._httpClient.get( endpointUrl, {
      withCredentials: true
    } ) as Observable<IReceiver> );
  }

  getReceiverPage( getReceiverPageForm: IGetReceiverPageForm ): Promise<IPage<IReceiver>> {
    const {
      cloudId, groupId, pageNumber, pageSize
    } = getReceiverPageForm;
    const endpointUrl: string = super.getEndpointUrl( HostUtil.getReceiverApiEndpointUrl(), `v1/cloud/${ cloudId }/group/${ groupId }/receiver/page` );

    const defaultSort: ISort = {
      field: "name", ascending: true
    };

    return firstValueFrom( this._httpClient.get( endpointUrl, {
      withCredentials: true, params: {
        pageNumber: pageNumber, pageSize: pageSize, sort: JSON.stringify( defaultSort )
      }
    } ) as Observable<IPage<IReceiver>> );
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {IGetLiveViewAccessTokenForm} from "../../rest-clients/receiver/forms/i-get-live-view-access-token-form";
import {
  IGetLiveViewAccessTokenResponse
} from "../../rest-clients/receiver/responses/i-get-live-view-access-token-response";
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {livekitClientInjectionToken} from "../../services/livekit/livekit-client-injection-token";
import {ILivekitClient} from "../../services/livekit/i-livekit.client";
import {receiverRestClientInjectionToken} from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import {IReceiverRestClient} from "../../rest-clients/receiver/i-receiver-rest.client";
import {IconComponent} from "@zaeper/angular-dashboard-view-lib";
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';

@Component({
  selector: 'app-live-view-container',
  standalone: true,
  imports: [
    IconComponent,
    CommonModule,
    ButtonModule
  ],
  templateUrl: './live-view-container.component.html',
  styleUrls: ["./live-view-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveViewContainerComponent implements OnDestroy, AfterViewInit {
  public readonly liveViewConnected$: Observable<boolean>;
  private readonly _subscriptions: Subscription = new Subscription();
  private readonly _liveViewConnected$: BehaviorSubject<boolean>;

  @ViewChild("liveViewContainer", {read: ElementRef}) private readonly _liveViewContainer!: ElementRef;

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(livekitClientInjectionToken) private readonly _livekitClient: ILivekitClient,
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient
  ) {
    this._liveViewConnected$ = new BehaviorSubject<boolean>(false);
    this.liveViewConnected$ = this._liveViewConnected$.asObservable();
  }

  ngAfterViewInit(): void {
    this._handleActiveReceiverChange();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _handleActiveReceiverChange() {
    this._subscriptions.add(this._sessionStateService.activeReceiver$.subscribe(() => {
      const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;
      const groupId: string | undefined = this._sessionStateService.getActiveGroup()?.id;
      const receiverId: string | undefined = this._sessionStateService.getActiveReceiver()?.id;

      if (cloudId && groupId && receiverId) {
        this._getLiveViewAccessToken(cloudId, groupId, receiverId).then((accessToken: string) => {

          this._livekitClient.connect(accessToken, this._liveViewContainer.nativeElement).then(() => {
            this._liveViewConnected$.next(true);
          });
        });
      }
    }));
  }

  private _getLiveViewAccessToken(cloudId: string, groupId: string, receiverId: string): Promise<string> {

    const getLiveViewAccessTokenForm: IGetLiveViewAccessTokenForm = {
      cloudId, groupId, receiverId
    };

    return this._receiverRestClient.getLiveViewAccessToken(getLiveViewAccessTokenForm).then((response: IGetLiveViewAccessTokenResponse) => {
      return response.accessToken;
    });
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {EPageLayout} from '@zaeper/angular-dashboard-view-lib';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-extras-overview-page',
  templateUrl: './features-overview-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesOverviewPageComponent {
  public readonly ePageLayout: typeof EPageLayout = EPageLayout;

  public readonly navigation: MenuItem[] = [
    {
      icon: 'pi pi-home',
      routerLink: '/de/',
    }, {
      label: 'Features',
      routerLink: '/de/features',
    },
  ];

  public readonly features = [
    {
      name: "Cloud-Verwaltung",
      description: "Mit unserer Cloud-Verwaltung können Sie alle Ihre Filialen, Gruppen und Bildschirme zentral in einem Dashboard steuern und verwalten. Planen und passen Sie Inhalte effizient an, um Ihre Markenkommunikation zu vereinheitlichen und gezielte Werbung zu schalten. Sparen Sie Zeit und Ressourcen, während Sie Ihre Marketingstrategien optimal umsetzen.",
      enabled: true
    }, {
      name: "Gruppen Synchronisierung",
      description: "Mit der Gruppen-Synchronisation können Sie die Einstellungen aller Receiver innerhalb einer Gruppe synchronisieren. Halten Sie mehrere Receiver stets auf dem gleichen Stand, indem Sie Änderungen zentral vornehmen und automatisch auf alle Geräte in der Gruppe übertragen. Dieses Feature gewährleistet eine einheitliche Konfiguration und reibungslose Funktion Ihrer Bildschirme.",
      enabled: true
    }, {
      name: "Live-Ansicht",
      description: "Mit der Live-Übertragung können Sie in Echtzeit überprüfen, ob die aktuellen Daten erfolgreich auf den Receiver übertragen wurden, ohne vor Ort sein zu müssen. Dieses Feature ermöglicht es Ihnen, die Inhalte und Einstellungen des Receivers live zu kontrollieren und sicherzustellen, dass alles wie geplant funktioniert.",
      enabled: false
    }, {
      name: "Private Repository",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus non enim praesent elementum facilisis leo vel. Cursus turpis massa tincidunt dui ut ornare lectus sit. Lectus magna fringilla urna porttitor rhoncus dolor purus non enim. Consectetur libero id faucibus nisl tincidunt eget nullam.",
      enabled: true
    }
  ]
}

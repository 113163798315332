import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {AnimationOptions, LottieComponent} from "ngx-lottie";
import {Observable} from "rxjs";
import {splashLoaderStateServiceInjectionToken} from "./splash-loader-state-service-injection-token";
import {ISplashLoaderStateService} from "./i-splash-loader-state.service";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-splash-loader',
  templateUrl: './splash-loader.component.html',
  standalone: true,
  imports: [CommonModule, LottieComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashLoaderComponent {
  public isVisible$: Observable<boolean>;

  public loadingWavesOptions: AnimationOptions = {
    path: `/assets/lottie/waves.json`, loop: true, autoplay: true,
  };

  constructor(@Inject(splashLoaderStateServiceInjectionToken) private readonly _splashLoaderStateService: ISplashLoaderStateService) {
    this.isVisible$ = this._splashLoaderStateService.isVisible$
  }
}

import { Inject, Injectable } from '@angular/core';
import { IScheduleReceiverConfigPushModalService } from "./i-schedule-receiver-config-push-modal.service";
import { IModalService, modalServiceInjectionToken } from '@zaeper/angular-dashboard-view-lib';
import { ScheduleReceiverConfigPushModalComponent } from "./schedule-receiver-config-push-modal.component";

@Injectable()
export class ScheduleReceiverConfigPushModalService implements IScheduleReceiverConfigPushModalService {
  constructor(@Inject(modalServiceInjectionToken) private _modalService: IModalService) {
  }

  open() {
    this._modalService.open(ScheduleReceiverConfigPushModalComponent, {
      styleClass: 'bg-white rounded-lg',
      header: "Laden der Receiver Konfigurationen planen",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true
    });
  }
}

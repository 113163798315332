<div class="absolute bottom-0 left-0">
  @if (!!(activeReceiver$ | async)) {
    <div class="p-4 flex flex-col gap-2">
      <p-button styleClass="bg-white" pTooltip="Receiver konfigurieren" (onClick)="openApplicationsOverviewModal()"
                [text]="true" [outlined]="true"
                [raised]="true" [rounded]="true"
                icon="pi pi-cog"/>
      <p-button styleClass="bg-white" pTooltip="Laden der Receiver Konfigurationen planen"
                (onClick)="openScheduleReceiverConfigPushModal()"
                [text]="true" [outlined]="true" [raised]="true"
                [rounded]="true"
                icon="pi pi-calendar-plus"/>
      <p-button pTooltip="Konfigurationen laden" (onClick)="openPushReceiverConfigModal()" [raised]="true"
                [rounded]="true"
                icon="pi pi-cloud-download"/>
    </div>
  }
</div>

<zaeper-page-layout [pageLayout]="ePageLayout.CENTERED">
  <ng-container *ngIf="(activeReceiver$ | async) === null; else receiverFrameTemplate">
    <div
      class="flex flex-col gap-6 text-zaeper-gray-200 items-center w-full h-full"
    >
      <img alt="No connected receiver symbol" src="assets/images/no_screen_connected.svg" width="250"/>
      <span class="text-center whitespace-pre-line">
        {{ "no-receiver-in-group" | zDictionary: "main" }}
      </span>
      <p-button (click)="openConnectReceiverModal()" [rounded]="true">{{
          "connect-receiver" | zDictionary: "main"
        }}
      </p-button>
    </div>
  </ng-container>
  <ng-template #receiverFrameTemplate>
    <div class="receiver-previewer__container flex flex-col gap-5 justify-center items-center p-5">
      <div class="flex flex-row w-full justify-between">
        <div></div>
        <div>
          <zaeper-toggle-button [buttons]="toggleButtons" (onChange)="switchViewMode($event)"/>
        </div>
        <div>
          <div
            class="relative flex flex-row items-center gap-2 rounded-full overflow-hidden border-zaeper-primary-100 shadow-lg bg-white px-6 py-2">
            @if (isReceiverOnline$ | async) {
              <span>Online</span>
              <div class="inline-block h-[15px] w-[15px] rounded-full bg-zaeper-success-300"></div>
            } @else {
              <span>Offline</span>
              <div class="inline-block h-[15px] w-[15px] rounded-full bg-gray-400"></div>
            }
          </div>
        </div>
      </div>
      <div class="flex h-full w-full justify-center p-1.5">
        <div #previewerFrameWrapper class="flex flex-col max-w-full max-h-full justify-center">
          <div #previewerFrame
               class="receiver-previewer__frame relative max-w-full max-h-full">
            <div
              class="absolute -z-1 -top-1.5 -left-1.5 h-[calc(100%+theme(space.3))] w-[calc(100%+theme(space.3))] bg-gradient-to-br from-gray-950 to-gray-500 rounded-xl"></div>

            <div class="h-full w-full rounded-lg overflow-hidden">
              <div class="contents" [ngClass]="{
                'hidden': (receiverViewMode$ | async) !== eReceiverViewMode.PREVIEW
              }">
                <ng-container #previewContainer></ng-container>
              </div>

              <div class="contents" [ngClass]="{
                'hidden': (receiverViewMode$ | async) !== eReceiverViewMode.LIVE_VIEW
              }">
                <ng-container #liveViewContainer></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</zaeper-page-layout>

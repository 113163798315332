import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { AsyncPipe, NgClass } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { DropdownMenuComponent, IconComponent } from "@zaeper/angular-dashboard-view-lib";
import { InputTextModule } from "primeng/inputtext";
import { ScrollerLazyLoadEvent, ScrollerModule } from "primeng/scroller";
import { MenuItem, SharedModule } from "primeng/api";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from "rxjs";
import { IGroup } from "../../models/i-group";
import { sessionStateServiceInjectionToken } from "../../global-states/session/session-state-service-injection-token";
import { ISessionStateService } from "../../global-states/session/i-session-state.service";
import { ICloud } from "../../models/i-cloud";
import { languageStateServiceInjectionToken } from "../../services/language/languageStateServiceInjectionToken";
import { ILanguageStateService } from "@zaeper/localization-lib";
import { GroupQuickAccessMenuStateService } from "./group-quick-access-menu-state.service";
import {
  groupQuickAccessMenuStateServiceInjectionToken
} from "./group-quick-access-menu-state-service-injection-token";
import { groupRestClientInjectionToken } from "../../rest-clients/group/group-rest-client-injection-token";
import { IGroupRestClient } from "../../rest-clients/group/i-group-rest.client";
import { IGroupQuickAccessMenuStateService } from "./i-group-quick-access-menu-state.service";
import { IGetGroupPageForm } from "../../rest-clients/group/forms/i-get-group-page-form";
import { IPage } from "@zaeper/communication-lib";
import {
  IGroupCreateModalForm,
  IGroupCreateUpdateModalService
} from "../../modals/group-create-update-modal/i-group-create-update-modal.service";
import { Router } from "@angular/router";
import {
  groupCreateUpdateModalServiceInjectionToken
} from "../../modals/group-create-update-modal/group-create-update-modal-service-injection-token";
import { receiverRestClientInjectionToken } from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import { IReceiverRestClient } from "../../rest-clients/receiver/i-receiver-rest.client";
import { IGetReceiverPageForm } from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import { IReceiver } from "../../models/i-receiver";

@Component({
  selector: "app-group-quick-access-menu",
  standalone: true,
  providers: [
    {
      provide: groupQuickAccessMenuStateServiceInjectionToken,
      useClass: GroupQuickAccessMenuStateService
    }
  ],
  imports: [
    AsyncPipe,
    ButtonModule,
    DropdownMenuComponent,
    IconComponent,
    InputTextModule,
    ScrollerModule,
    SharedModule,
    ZTemplateModule,
    NgClass
  ],
  templateUrl: "./group-quick-access-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupQuickAccessMenuComponent implements OnDestroy {
  private static readonly _MAX_SCROLL_HEIGHT: number = 160;
  public readonly itemSize: number = 40;
  public readonly pageSize: number = 0;
  public readonly scrollHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(this.itemSize);
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly groupMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public readonly activeCloud$: Observable<ICloud | null>;
  public readonly activeGroup$: Observable<IGroup | null>;
  public readonly language$: Observable<string>;
  @ViewChild("searchInput", { read: ElementRef }) searchInputEl!: ElementRef;
  public readonly records$: Observable<IGroup[]> = new Observable<IGroup[]>();
  private _subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService,
    @Inject(groupQuickAccessMenuStateServiceInjectionToken) private readonly _groupQuickAccessMenuStateService: IGroupQuickAccessMenuStateService,
    @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient,
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
    @Inject(groupCreateUpdateModalServiceInjectionToken) private readonly _groupCreateUpdateModalService: IGroupCreateUpdateModalService,
    private readonly _router: Router
  ) {
    this.activeCloud$ = this._sessionStateService.activeCloud$;
    this.activeGroup$ = this._sessionStateService.activeGroup$;
    this.language$ = this._languageStateService.language$;
    this.records$ = this._groupQuickAccessMenuStateService.records$;

    this._setMenuItems();
    this._groupQuickAccessMenuStateService.setPageSize(this.pageSize);
    this._handleActiveGroupChange();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public switchToGroup(group: IGroup): void {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;

    if (cloudId) {
      const getReceiverPageForm: IGetReceiverPageForm = {
        cloudId,
        groupId: group.id,
        pageNumber: 0,
        pageSize: 1
      };

      const receiverPage: Promise<IPage<IReceiver>> = this._receiverRestClient.getReceiverPage(getReceiverPageForm);
      const language$: Promise<string> = firstValueFrom(this._languageStateService.language$);
      Promise.all([receiverPage, language$]).then(([receiverPage, language]: [IPage<IReceiver>, string]): void => {
        const firstReceiver: IReceiver = receiverPage.records[0] ?? null;

        this._sessionStateService.setActiveGroup(group);
        this._sessionStateService.setActiveReceiver(firstReceiver);

        this._router.navigate([ `/${language}/` ]);
      });
    }
  }

  public lazyLoadItems(scrollerLazyLoadEvent: ScrollerLazyLoadEvent): Promise<void> {
    const last: number = scrollerLazyLoadEvent.last;
    console.log("lazy", last, this._groupQuickAccessMenuStateService.getTotalRecords(), this._groupQuickAccessMenuStateService.getRecords().length);

    if (last === this._groupQuickAccessMenuStateService.getRecords().length && last < this._groupQuickAccessMenuStateService.getTotalRecords()) {
      const nextPage: number = Math.ceil(last / this.pageSize);
      if (this._groupQuickAccessMenuStateService.getPageNumber() < nextPage) {
        return this._loadGroupRecords(nextPage);
      }
    }
    return Promise.resolve();
  }

  private _handleActiveGroupChange(): void {
    this._subscriptions.add(this._sessionStateService.activeGroup$.subscribe((group: IGroup | null) => {
      this._groupQuickAccessMenuStateService.setRecords([]);
      this._loadGroupRecords(0);
    }));
  }

  private _loadGroupRecords(pageNumber: number): Promise<void> {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;

    if (cloudId) {
      this.isLoading$.next(true);
      const getGroupPageForm: IGetGroupPageForm = {
        cloudId: cloudId,
        pageNumber: pageNumber,
        pageSize: this._groupQuickAccessMenuStateService.getPageSize()
      };

      return this._groupRestClient.getGroupPage(getGroupPageForm).then((page: IPage<IGroup>) => {
        this._groupQuickAccessMenuStateService.setRecords([
          ...this._groupQuickAccessMenuStateService.getRecords(), ...page.records
        ]);
        this._groupQuickAccessMenuStateService.setTotalRecords(page.totalRecords);
        this._groupQuickAccessMenuStateService.setTotalPages(page.totalPages);
        this._groupQuickAccessMenuStateService.setPageNumber(pageNumber);
        this.scrollHeight$.next(Math.min(GroupQuickAccessMenuComponent._MAX_SCROLL_HEIGHT, page.totalRecords * this.itemSize));
        this.isLoading$.next(false);
      });
    }

    return Promise.resolve();
  }

  private _setMenuItems() {
    this._subscriptions.add(combineLatest([
      this.activeCloud$, this.activeGroup$, this.language$
    ]).subscribe(([ activeCloud, activeGroup, language ]: [ ICloud | null, IGroup | null, string ]) => {
      if (activeCloud && activeGroup) {
        this.groupMenuItems$.next([
          {
            label: "Gruppe hinzufügen",
            icon: "pi pi-plus",
            command: () => {
              const groupCreateModalForm: IGroupCreateModalForm = {
                cloudId: activeCloud.id,
                onSaveCallback: () => this._router.navigate([ `/${language}/clouds/${activeCloud.id}/groups` ])
              };
              this._groupCreateUpdateModalService.openCreateModal(groupCreateModalForm);
            }
          }, {
            label: "Gruppen verwalten",
            icon: "pi pi-list",
            routerLink: `/${language}/clouds/${activeCloud.id}/groups`
          }
        ]);
      }
    }));
  }
}

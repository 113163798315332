import { IInfrastructureService } from "./i-infrastructure.service";
import { Inject, Injectable } from "@angular/core";
import {
  cloudRestClientInjectionToken
} from "../../rest-clients/cloud/cloud-rest-client-injection-token";
import { ICloudRestClient } from "../../rest-clients/cloud/i-cloud-rest.client";
import {
  groupRestClientInjectionToken
} from "../../rest-clients/group/group-rest-client-injection-token";
import { IGroupRestClient } from "../../rest-clients/group/i-group-rest.client";
import { ICreateCloudForm } from "../../rest-clients/cloud/forms/i-create-cloud-form";
import { ICreateGroupForm } from "../../rest-clients/group/forms/i-create-group-form";
import { ICreateRecordResponse } from "@zaeper/communication-lib";

@Injectable()
export class InfrastructureService implements IInfrastructureService {
  private static readonly _INITIAL_GROUP_NAME: string = "Gruppe 1";
  private static readonly _INITIAL_GROUP_DESCRIPTION: string = "Iniziale Gruppe";

  constructor(@Inject(cloudRestClientInjectionToken) private readonly _cloudRestClient: ICloudRestClient,
              @Inject(groupRestClientInjectionToken) private readonly _groupRestClient: IGroupRestClient) {
  }

  public createCloud(createCloudForm: ICreateCloudForm): Promise<void> {
    return this._cloudRestClient.createCloud(createCloudForm).then((createRecordResponse: ICreateRecordResponse) => {
      const createGroupForm: ICreateGroupForm = {
        cloudId: createRecordResponse.recordId,
        name: InfrastructureService._INITIAL_GROUP_NAME,
        description: InfrastructureService._INITIAL_GROUP_DESCRIPTION
      }

      return this.createGroup(createGroupForm);
    })
  }

  public createGroup(createGroupForm: ICreateGroupForm): Promise<void> {
    return this._groupRestClient.createGroup(createGroupForm).then(() => {
      return Promise.resolve();
    })
  }
}

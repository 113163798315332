import {Injectable} from "@angular/core";
import {ILauncher, ILauncherRestClient} from "@zaeper/angular-application-store-lib";
import {HttpClient} from "@angular/common/http";
import {AApplicationRestClient} from "../a-application-rest.client";

@Injectable()
export class LauncherRestClient extends AApplicationRestClient<ILauncher> implements ILauncherRestClient {
  private static readonly _RESOURCE_PATH: string = "v1/launcher"

  constructor(readonly httpClient: HttpClient) {
    super(httpClient, LauncherRestClient._RESOURCE_PATH);
  }
}

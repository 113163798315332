import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewChild} from "@angular/core";
import {AsyncPipe, NgClass} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {DropdownMenuComponent, IconComponent} from "@zaeper/angular-dashboard-view-lib";
import {InputTextModule} from "primeng/inputtext";
import {ScrollerLazyLoadEvent, ScrollerModule} from "primeng/scroller";
import {MenuItem, SharedModule} from "primeng/api";
import {ZTemplateModule} from "@zaeper/angular-core-lib";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {IReceiver} from "../../models/i-receiver";
import {sessionStateServiceInjectionToken} from "../../global-states/session/session-state-service-injection-token";
import {ISessionStateService} from "../../global-states/session/i-session-state.service";
import {ICloud} from "../../models/i-cloud";
import {IGroup} from "../../models/i-group";
import {IConnectReceiverModalData} from "../../modals/connect-receiver-modal/i-connect-receiver-modal-data";
import {
  connectReceiverModalServiceInjectionToken
} from "../../modals/connect-receiver-modal/connect-receiver-modal-service-injection-token";
import {IConnectReceiverModalService} from "../../modals/connect-receiver-modal/i-connect-receiver-modal.service";
import {languageStateServiceInjectionToken} from "../../services/language/languageStateServiceInjectionToken";
import {ILanguageStateService} from "@zaeper/localization-lib";
import {ReceiverQuickAccessMenuStateService} from "./receiver-quick-access-menu-state.service";
import {
  receiverQuickAccessMenuStateServiceInjectionToken
} from "./receiver-quick-access-menu-state-service-injection-token";
import {receiverRestClientInjectionToken} from "../../rest-clients/receiver/receiver-rest-client-injection-token";
import {IReceiverRestClient} from "../../rest-clients/receiver/i-receiver-rest.client";
import {IReceiverQuickAccessMenuStateService} from "./i-receiver-quick-access-menu-state.service";
import {IGetReceiverPageForm} from "../../rest-clients/receiver/forms/i-get-receiver-page-form";
import {IPage} from "@zaeper/communication-lib";
import {Router} from "@angular/router";

@Component({
  selector: "app-receiver-quick-access-menu",
  standalone: true,
  providers: [
    {
      provide: receiverQuickAccessMenuStateServiceInjectionToken,
      useClass: ReceiverQuickAccessMenuStateService
    }
  ],
  imports: [
    AsyncPipe,
    ButtonModule,
    DropdownMenuComponent,
    IconComponent,
    InputTextModule,
    ScrollerModule,
    SharedModule,
    ZTemplateModule,
    NgClass
  ],
  templateUrl: "./receiver-quick-access-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiverQuickAccessMenuComponent implements OnDestroy {
  private static readonly _MAX_SCROLL_HEIGHT: number = 160;
  public readonly itemSize: number = 40;
  public readonly pageSize: number = 0;
  public readonly scrollHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(this.itemSize);
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly receiverMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public readonly activeCloud$: Observable<ICloud | null>;
  public readonly activeGroup$: Observable<IGroup | null>;
  public readonly activeReceiver$: Observable<IReceiver | null>;
  public readonly language$: Observable<string>;
  @ViewChild("searchInput", {read: ElementRef}) searchInputEl!: ElementRef;
  public readonly records$: Observable<IReceiver[]> = new Observable<IReceiver[]>();
  private _subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(sessionStateServiceInjectionToken) private readonly _sessionStateService: ISessionStateService,
    @Inject(connectReceiverModalServiceInjectionToken) private readonly _connectReceiverModalService: IConnectReceiverModalService,
    @Inject(languageStateServiceInjectionToken) private readonly _languageStateService: ILanguageStateService,
    @Inject(receiverQuickAccessMenuStateServiceInjectionToken) private readonly _receiverQuickAccessMenuStateService: IReceiverQuickAccessMenuStateService,
    @Inject(receiverRestClientInjectionToken) private readonly _receiverRestClient: IReceiverRestClient,
    private readonly _router: Router
  ) {
    this.activeCloud$ = this._sessionStateService.activeCloud$;
    this.activeGroup$ = this._sessionStateService.activeGroup$;
    this.activeReceiver$ = this._sessionStateService.activeReceiver$;
    this.language$ = this._languageStateService.language$;
    this.records$ = this._receiverQuickAccessMenuStateService.records$;

    this._setMenuItems();
    this._receiverQuickAccessMenuStateService.setPageSize(this.pageSize);
    this._handleActiveReceiverChange();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public lazyLoadItems(scrollerLazyLoadEvent: ScrollerLazyLoadEvent): Promise<void> {
    const last: number = scrollerLazyLoadEvent.last;

    if (last === this._receiverQuickAccessMenuStateService.getRecords().length && last < this._receiverQuickAccessMenuStateService.getTotalRecords()) {
      const nextPage: number = Math.ceil(last / this.pageSize);
      if (this._receiverQuickAccessMenuStateService.getPageNumber() < nextPage) {
        return this._loadReceiverRecords(nextPage);
      }
    }
    return Promise.resolve();
  }

  public switchToReceiver(receiver: IReceiver): void {
    this._sessionStateService.setActiveReceiver(receiver);
    this._router.navigate(["/de/"]);
  }

  private _handleActiveReceiverChange(): void {
    this._subscriptions.add(this._sessionStateService.activeReceiver$.subscribe((receiver: IReceiver | null) => {
      this._receiverQuickAccessMenuStateService.setRecords([]);
      this._loadReceiverRecords(0);
    }));
  }

  private _loadReceiverRecords(pageNumber: number): Promise<void> {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;
    const groupId: string | undefined = this._sessionStateService.getActiveGroup()?.id;

    if (cloudId && groupId) {
      this.isLoading$.next(true);
      const getReceiverPageForm: IGetReceiverPageForm = {
        cloudId: cloudId,
        groupId: groupId,
        pageNumber: pageNumber,
        pageSize: this._receiverQuickAccessMenuStateService.getPageSize()
      };

      return this._receiverRestClient.getReceiverPage(getReceiverPageForm).then((page: IPage<IReceiver>) => {
        this._receiverQuickAccessMenuStateService.setRecords([
          ...this._receiverQuickAccessMenuStateService.getRecords(), ...page.records
        ]);
        this._receiverQuickAccessMenuStateService.setTotalRecords(page.totalRecords);
        this._receiverQuickAccessMenuStateService.setTotalPages(page.totalPages);
        this._receiverQuickAccessMenuStateService.setPageNumber(pageNumber);
        this.scrollHeight$.next(Math.min(ReceiverQuickAccessMenuComponent._MAX_SCROLL_HEIGHT, page.totalRecords * this.itemSize));
        this.isLoading$.next(false);
      });
    }

    return Promise.resolve();
  }

  private _setMenuItems() {
    this._subscriptions.add(combineLatest([
      this.activeCloud$, this.activeGroup$, this.language$
    ]).subscribe(([activeCloud, activeGroup, language]: [ICloud | null, IGroup | null, string]) => {
      if (activeCloud && activeGroup) {
        this.receiverMenuItems$.next([
          {
            label: "Receiver hinzufügen",
            icon: "pi pi-plus",
            command: () => {
              this._openRegisterReceiverModal();
            }
          }, {
            label: "Receivers verwalten",
            icon: "pi pi-list",
            routerLink: `/${language}/clouds/${activeCloud.id}/groups/${activeGroup.id}/receivers`
          }
        ]);
      }
    }));
  }

  private _openRegisterReceiverModal() {
    const cloudId: string | undefined = this._sessionStateService.getActiveCloud()?.id;
    const groupId: string | undefined = this._sessionStateService.getActiveGroup()?.id;

    if (cloudId && groupId) {
      const connectReceiverModalData: IConnectReceiverModalData = {
        cloudId,
        groupId
      };

      this._connectReceiverModalService.open(connectReceiverModalData);
    }
  }
}

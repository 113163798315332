import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentOverviewPageComponent } from "./payment-overview-page.component";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { SharedModule } from "primeng/api";
import { TableModule } from "primeng/table";
import { ZDictionaryModule } from "@zaeper/angular-localization-lib";
import { ZTemplateModule } from "@zaeper/angular-core-lib";
import { IconComponent, PageLayoutModule } from "@zaeper/angular-dashboard-view-lib";


@NgModule({
  declarations: [
    PaymentOverviewPageComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    PaginatorModule,
    SharedModule,
    TableModule,
    ZDictionaryModule,
    ZTemplateModule,
    PageLayoutModule,
    IconComponent
  ]
})
export class PaymentOverviewPageModule {
}

import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {DropdownModule} from "primeng/dropdown";
import {
  EFieldType,
  FormBuilderModule,
  InputGroup,
  InputGroupModule,
  ISelectionOption
} from "@zaeper/angular-dashboard-view-lib";
import {ButtonModule} from "primeng/button";
import {ZTemplateModule} from "@zaeper/angular-core-lib";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, combineLatest, map, Observable, Subscription} from "rxjs";
import {
  IApp,
  IGetApplicationSettingsForm,
  IGetInstalledApplicationPageForm,
  ILauncher,
  ILauncherRestClient,
  ILauncherSettingsRestClient,
  launcherRestClientInjectionToken,
  launcherSettingsRestClientInjectionToken
} from "@zaeper/angular-application-store-lib";
import {AsyncPipe} from "@angular/common";
import {IPage} from "@zaeper/communication-lib";
import {IApplicationsOverviewModalForm} from "../../i-applications-overview-modal.service";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {IReceiver} from "../../../../models/i-receiver";
import {ILauncherSettings} from "@zaeper/angular-application-store-lib";
import {
  TFieldElement,
  TInputValue
} from "@zaeper/angular-dashboard-view-lib/lib/components/input-elements/iInputElement";
import {LauncherSettingsInputFieldMapper} from "../../../../mappers/launcher-settings-field.mapper";
import {appsOverviewStateServiceInjectionToken} from "../../apps-overview-state-service-injection-token";
import {IAppsOverviewStateService} from "../../i-apps-overview-state-service";

@Component({
  selector: "app-launcher-settings-tab",
  standalone: true,
  imports: [DropdownModule, ButtonModule, InputGroupModule, ZTemplateModule, AsyncPipe, FormBuilderModule],
  templateUrl: "./launcher-settings-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LauncherSettingsTabComponent implements OnInit, OnDestroy {
  private static _START_APPLICATION_SETTINGS_GROUP_ID: string = "start-application";

  public readonly _installedLaunchers$: BehaviorSubject<ILauncher[]>;
  public launcherInputGroup$: Observable<TFieldElement[]>;
  private _launcherSettings$: BehaviorSubject<ILauncherSettings | null>;
  private _receiver: IReceiver | null;
  private readonly _formGroup;
  private readonly _subscriptions: Subscription = new Subscription();

  constructor(@Inject(launcherRestClientInjectionToken) private readonly _launcherRestClient: ILauncherRestClient, @Inject(launcherSettingsRestClientInjectionToken) private readonly _launcherSettingsRestClient: ILauncherSettingsRestClient, @Inject(appsOverviewStateServiceInjectionToken) private readonly _appsOverviewStateService: IAppsOverviewStateService, private readonly _dynamicDialogConfig: DynamicDialogConfig<IApplicationsOverviewModalForm>) {
    this._installedLaunchers$ = new BehaviorSubject<ILauncher[]>([]);
    this._launcherSettings$ = new BehaviorSubject<ILauncherSettings | null>(null);
    this.launcherInputGroup$ = this._getLauncherInputFields$();
    this._receiver = this._dynamicDialogConfig.data?.receiver ?? null;

    this._formGroup = new FormGroup({
      launcherId: new FormControl(this._receiver?.launcherId, {
        validators: [Validators.required],
      })
    }, {
      updateOn: "change"
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public handleOnChange(values: Map<string, TInputValue>): void {
    const launcherSettings: Map<string, TInputValue> = <Map<string, TInputValue>>values.get(LauncherSettingsTabComponent._START_APPLICATION_SETTINGS_GROUP_ID);
    const launcherId: string | undefined = <string>launcherSettings.get("launcherId");
    if (!!launcherId) {
      this._loadLauncherSettings(launcherId);
    }
  }

  ngOnInit(): void {
    this._loadInstalledLaunchers();
    if (this._receiver?.launcherId) {
      this._loadLauncherSettings(this._receiver.launcherId)
    }
  }

  private _loadLauncherSettings(launcherId: string) {
    const getApplicationSettingsForm: IGetApplicationSettingsForm = {
      applicationId: launcherId
    }

    this._launcherSettingsRestClient.getApplicationSettings(getApplicationSettingsForm).then((launcherSettings: ILauncherSettings) => {
      this._launcherSettings$.next(launcherSettings);
    })
  }

  private _loadInstalledLaunchers(): void {
    if (!!this._receiver) {
      const getInstalledApplicationPageForm: IGetInstalledApplicationPageForm = {
        sort: [{
          field: "name", ascending: true
        }], pageNumber: 0, pageSize: 100, receiverId: this._receiver.id
      }

      this._launcherRestClient.getInstalledApplicationPage(getInstalledApplicationPageForm).then((installedApplicationPage: IPage<ILauncher>) => {
        this._installedLaunchers$.next(installedApplicationPage.records)
      })
    }
  }

  private _getLauncherInputFields$(): Observable<TFieldElement[]> {
    return combineLatest([this._installedLaunchers$, this._launcherSettings$]).pipe(map(([launchers, activeLauncherSettings]: [ILauncher[], ILauncherSettings | null]): TFieldElement[] => {
      let settingFields: TFieldElement[] = [];

      const selections: ISelectionOption[] = launchers.map((launcher: ILauncher): ISelectionOption => {
        return {
          title: launcher.name, value: launcher.id
        };
      });

      settingFields = [{
        fieldType: EFieldType.SELECTION,
        title: "Launcher",
        description: "Setze den Launcher fest, welcher für die Ausführung der Applikationen zuständig sein wird.",
        required: true,
        formControlEntry: {
          formControl: this._formGroup.get("launcherId") as FormControl<string>,
          key: "launcherId"
        },
        inputId: "launcherId",
        selections: selections
      }];

      if (!!activeLauncherSettings) {
        const installedApps: IApp[] = this._appsOverviewStateService.getRecords();
        const launcherSettingsInputFieldMapper: LauncherSettingsInputFieldMapper = new LauncherSettingsInputFieldMapper(installedApps);
        const settingsFieldElements: TFieldElement[] = launcherSettingsInputFieldMapper.from(activeLauncherSettings);

        settingFields = [...settingFields, ...settingsFieldElements]
      }

      return [new InputGroup({
        groupId: LauncherSettingsTabComponent._START_APPLICATION_SETTINGS_GROUP_ID, children: settingFields
      })];
    }));
  }
}

import { Inject, Injectable } from "@angular/core";
import {
  IAvailableAppsOverviewModalForm,
  IAvailableAppsOverviewModalService
} from "./i-available-apps-overview-modal.service";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IModalService, modalServiceInjectionToken } from "@zaeper/angular-dashboard-view-lib";
import { AvailableAppsOverviewModalComponent } from "./available-apps-overview-modal.component";

@Injectable()
export class AvailableAppsOverviewModalService implements IAvailableAppsOverviewModalService {
  constructor(@Inject(modalServiceInjectionToken) private readonly _modalService: IModalService) {
  }

  openAvailableAppsOverviewModal(availableAppsOverviewModalForm: IAvailableAppsOverviewModalForm): DynamicDialogRef {
    const dynamicDialogRef: DynamicDialogRef = this._modalService.open(AvailableAppsOverviewModalComponent, {
      data: {
        cloudId: availableAppsOverviewModalForm.cloudId,
        receiverId: availableAppsOverviewModalForm.receiverId,
        onSaveCallback: () => {
          availableAppsOverviewModalForm.onSaveCallback?.();
          dynamicDialogRef.close();
        }
      },
      styleClass: "bg-white rounded-lg",
      header: "App installieren",
      showHeader: true,
      closable: true,
      width: "600px",
      closeOnEscape: true,
      dismissableMask: true
    });

    return dynamicDialogRef;
  }
}


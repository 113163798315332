import { Inject, Injectable } from '@angular/core';
import { IConnectReceiverModalService } from "./i-connect-receiver-modal.service";
import { ConnectReceiverModalComponent } from "./connect-receiver-modal.component";
import { IModalService, modalServiceInjectionToken } from '@zaeper/angular-dashboard-view-lib';
import { IConnectReceiverModalData } from "./i-connect-receiver-modal-data";

@Injectable()
export class ConnectReceiverModalService implements IConnectReceiverModalService {
  constructor( @Inject( modalServiceInjectionToken ) private readonly _modalService: IModalService ) {
  }

  open( modalData: IConnectReceiverModalData ): void {
    this._modalService.open( ConnectReceiverModalComponent, {
      styleClass: 'bg-white rounded-lg',
      header: "Receiver verbinden",
      showHeader: true,
      closable: true,
      width: '900px',
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        cloudId: modalData.cloudId,
        groupId: modalData.groupId
      }
    } );
  }
}
